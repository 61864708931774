import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import CenterModal from "../CenterModal";
import Button from "../../theme/Button";
import { NoteDTO } from "../../models/api/alum";
import NoteCard from "./NoteCard";
import leftChevronSvg from "/chevron-left.svg";
import closeSvg from "/x-close.svg";
import trashSvg from "/trash-01.svg";
import theme from "../../theme";
import { displayDate } from "../../lib/time";
import { SvgButton, SvgButtonSize } from "../../theme/SvgButton.tsx";
import Box from "@mui/material/Box";

interface ExpandedNoteModalProps {
  note: NoteDTO | null;
  onClose: () => void;
  onBack: () => void;
  onDiscard: () => void;
  displayBackButton?: boolean;
}

export const ExpandedNoteModal = ({
  note,
  onClose,
  onBack,
  onDiscard,
  displayBackButton = true,
}: ExpandedNoteModalProps) => {
  const { t } = useTranslation(Translation.alumProfile);

  if (!note) return null;

  return (
    <CenterModal
      open={true}
      onClose={onBack}
      sx={{
        width: "40rem",
        height: "31.25rem",
        py: "1.5rem",
        outline: "none",
        overflow: "auto",
      }}
    >
      <Stack id="expanded-note-modal" gap="1.5rem" height="100%">
        <Stack direction="row" alignItems="center" gap={2} px="1.5rem">
          {displayBackButton && (
            <SvgButton src={leftChevronSvg} onClick={onBack} />
          )}
          <Typography
            fontWeight={400}
            fontSize="0.75rem"
            flexGrow={1}
            color={theme.palette.grey[500]}
            textAlign="center"
          >
            {t("notes.expandedView.title", {
              firstName: note.created_by.first_name,
              lastName: note.created_by.last_name,
              date: displayDate(note.created_date),
            })}
          </Typography>
          <SvgButton
            src={closeSvg}
            onClick={onClose}
            size={SvgButtonSize.MEDIUM}
          />
        </Stack>
        <Divider />
        <Box flex={1} px="1.5rem">
          <NoteCard note={note} expanded />
        </Box>
        <Divider />
        <Stack
          direction="row"
          justifyContent="flex-end"
          px="1.5rem"
          alignContent="center"
        >
          <Button
            buttonProps={{
              onClick: onDiscard,
              sx: {
                padding: "0.625rem 1rem",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0.5rem",
                backgroundColor: theme.palette.background.default,
                "&:hover": {
                  backgroundColor: theme.palette.errorColor[50],
                },
              },
            }}
          >
            <Stack direction="row" gap="0.5rem">
              <SvgButton src={trashSvg} />
              <Typography
                fontSize="0.875rem"
                fontWeight={600}
                color={theme.palette.errorColor[700]}
              >
                {t("notes.expandedView.discard")}
              </Typography>
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </CenterModal>
  );
};
