import { useTranslation } from "react-i18next";
import { useState, useContext, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import textLogo from "/text-logo.svg";
import loginMockup from "/login-mockup.png";
import { UserContext } from "../../lib/context";
import "../../CustomToastAnimations.css";
import { Translation } from "../../lib/constants";
import theme from "../../theme";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer.tsx";
import { toast } from "react-toastify";
import EmailForm from "./EmailForm.tsx";
import PasswordForm from "./PasswordForm.tsx";
import MFAForm from "./MFAForm.tsx";
import { SignInRequest } from "../../api/auth.ts";

enum LOGIN_VIEW {
  EMAIL,
  PASSWORD,
  MFA,
}

export default function Login() {
  const { t } = useTranslation(Translation.login);
  const [view, setView] = useState<LOGIN_VIEW>(LOGIN_VIEW.EMAIL);
  const [loginData, setLoginData] = useState<SignInRequest>({
    email: "",
    password: "",
    mfaCode: null,
  });
  const [user, _] = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.message) {
      toast.success(location.state.message, { containerId: "login-success" });
    }
  }, [location]);

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <Stack flexDirection="row" alignItems="center" flexShrink={0}>
      <Stack
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        width={{ xs: "100%", md: "50%" }}
        padding="2rem"
      >
        <Stack width="22.5rem" height="33.15rem">
          <Box
            component="img"
            sx={{ width: "9rem", marginBottom: "5rem" }}
            alt={t("boomerangLogoAlt", { ns: Translation.common })}
            src={textLogo}
          />
          <Stack
            alignItems="flex-start"
            spacing="0.75rem"
            alignSelf="stretch"
            marginBottom="2rem"
          >
            <Typography fontSize="1.875rem" fontWeight={600}>
              {t("title")}
            </Typography>
            <Typography fontSize="1rem" fontWeight={400} color="text.secondary">
              {t("subtitle")}
            </Typography>
          </Stack>
          {view === LOGIN_VIEW.EMAIL && (
            <EmailForm
              showPasswordInput={() => {
                setView(LOGIN_VIEW.PASSWORD);
              }}
              loginData={loginData}
              setLoginData={setLoginData}
            />
          )}
          {view === LOGIN_VIEW.PASSWORD && (
            <PasswordForm
              loginData={loginData}
              setLoginData={setLoginData}
              showPasswordInput={() => {
                setView(LOGIN_VIEW.EMAIL);
              }}
              showMfaInput={() => {
                setView(LOGIN_VIEW.MFA);
              }}
            />
          )}
          {view === LOGIN_VIEW.MFA && (
            <MFAForm loginData={loginData} setLoginData={setLoginData} />
          )}
        </Stack>
        <SuccessToastContainer containerId="login-success" position="top-left" style={{ width: "48%" }} />
      </Stack>
      <Stack
        minHeight="100vh"
        width="50%"
        position="fixed"
        left="50%"
        overflow="hidden"
        display={{ xs: "none", md: "flex" }}
        sx={{ backgroundColor: theme.palette.grey[50] }}
      >
        <Box
          component="img"
          alt={t("mockupAlt")}
          src={loginMockup}
          width="125%"
          position="absolute"
          top="50%"
          left={0}
          border={`3px solid ${theme.palette.grey[800]}`}
          borderRadius="0.75rem"
          sx={{ translate: "6rem -50%" }}
        />
      </Stack>
    </Stack>
  );
}
