import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Pagination from "../Pagination";
import { SxProps, Theme } from "@mui/material";
import EmptyResult from "../Error/EmptyResult";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";

interface TableProps<T> {
  columns: TableColumn<T>[];
  rows: T[];
  page: number;
  pageSize: number;
  totalRows: number;
  onPageChange: (page: number) => void;
  minWidth: string;
  categoryPlural: string;
  categorySingular: string;
  emptyResultText?: string;
  maxPagesToShow?: number;
}

export default function Table<T>(props: TableProps<T>) {
  const { t } = useTranslation(Translation.common);

  const {
    columns,
    rows,
    page,
    pageSize,
    totalRows,
    onPageChange,
    minWidth,
    categoryPlural,
    categorySingular,
    emptyResultText = t("emptyResult"),
    maxPagesToShow,
  } = props;
  return (
    <Box>
      <Box overflow="auto">
        <Stack
          className="table-header"
          minWidth={minWidth}
          direction="row"
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[50],
          })}
        >
          {columns.map((column) => (
            <Stack
              key={column.id}
              className={column.id}
              padding="0.75rem 1.5rem"
              justifyContent="center"
              sx={column.styles}
            >
              {column.label()}
            </Stack>
          ))}
        </Stack>
        {rows.length > 0 ? (
          <Stack className="table-rows">
            {rows.map((row, idx) => {
              return (
                <Stack
                  key={idx}
                  minWidth={minWidth}
                  direction="row"
                  sx={(theme) => ({
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    background: theme.palette.common.white,
                    "&:hover": {
                      backgroundColor: theme.palette.custom.greyBackground,
                    },
                  })}
                >
                  {columns.map((column) => (
                    <Stack
                      key={column.id}
                      className={column.id}
                      padding="1rem 1.5rem"
                      justifyContent="center"
                      sx={column.styles}
                    >
                      {column.content(row)}
                    </Stack>
                  ))}
                </Stack>
              );
            })}
          </Stack>
        ) : (
          <EmptyResult text={emptyResultText} />
        )}
      </Box>
      {rows.length > 0 && (
        <Pagination
          page={page}
          pageSize={pageSize}
          totalRows={totalRows}
          onPageChange={onPageChange}
          categoryPlural={categoryPlural}
          categorySingular={categorySingular}
          maxPagesToShow={maxPagesToShow}
        />
      )}
    </Box>
  );
}

export type TableColumn<T> = {
  id: string;
  label: () => JSX.Element;
  content: (row: T) => JSX.Element;
  styles: SxProps<Theme>;
};
