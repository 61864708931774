import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Loading from "../../components/Loading";
import CriteriaListItem from "./CriteriaListItem";
import { MatchingCriteriaI } from "../../models/api/criteria";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import CriteriaHeading from "./CriteriaHeading";
import coinsHandIcon from "/public/coins-hand.svg";
import Button from "../../theme/Button";
import EmptyResult from "../Error/EmptyResult";
import theme from "../../theme";

type CriteriaListProps<T> = {
  isLoading: boolean;
  isError: boolean;
  criteriaList: T[];
  setCriteriaToEdit: (arg: T) => void;
  listHeader: string;
  listSubHeader: string;
  toggleDisabledTooltip?: string;
  createNew?: {
    onCreateNewClick: () => void;
    duplicateCriteria?: (arg: T) => void;
    setCriteriaToDelete?: (arg: T) => void;
    updateIsEnabled?: (arg: T, isChecked: boolean) => void;
    noMatchHeading?: string;
    noMatchSubHeading?: string;
    buttonText: string;
  };
};

export default function CriteriaList<T extends MatchingCriteriaI>({
  isLoading,
  isError,
  criteriaList,
  setCriteriaToEdit,
  listHeader,
  listSubHeader,
  toggleDisabledTooltip,
  createNew,
}: CriteriaListProps<T>) {
  const { t } = useTranslation(Translation.settings);

  const newCriteriaButton = (
    <Button
      buttonProps={{
        sx: {
          p: "0.6rem 1.2rem",
          fontSize: "0.875rem",
          bgcolor: theme.palette.primary.main,
          "&:hover": {
            bgcolor: theme.palette.custom.purpleHover,
            boxShadow: "none",
          },
        },
        className: "new-criteria-button",
        onClick: () => createNew?.onCreateNewClick(),
      }}
    >
      {createNew?.buttonText}
    </Button>
  );

  if (isLoading) {
    return <Loading />;
  } else if (isError) {
    return (
      <>
        {createNew && (
          <CriteriaHeading
            listHeader={listHeader}
            listSubHeader={listSubHeader}
          >
            {createNew ? newCriteriaButton : null}
          </CriteriaHeading>
        )}
        <EmptyResult text={t("loadingError")} />
      </>
    );
  } else if (createNew && criteriaList.length == 0) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          pt: "4.5rem",
        }}
      >
        <Stack
          sx={{
            height: "100%",
            width: "34rem",
            overflow: "auto",
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.grey[800],
            textAlign: "center",
          }}
        >
          <Box component="img" src={coinsHandIcon} />
          <Typography
            pt="1rem"
            fontSize="1rem"
            fontWeight={600}
            className="no-match-heading"
          >
            {createNew.noMatchHeading}
          </Typography>
          <Typography
            pt="0.4rem"
            pb="1.5rem"
            fontSize="0.875rem"
            className="no-match-subheading"
          >
            {createNew.noMatchSubHeading}
          </Typography>
          {newCriteriaButton}
        </Stack>
      </Box>
    );
  }

  return (
    <Box className="criteria-list">
      <CriteriaHeading listHeader={listHeader} listSubHeader={listSubHeader}>
        {createNew ? newCriteriaButton : null}
      </CriteriaHeading>
      <Stack gap="16px">
        {criteriaList.map((criteria) => {
          const menuOptions = [
            {
              className: "menu-options-edit",
              label: "Edit",
              hasDividerAbove: false,
              handler: () => {
                setCriteriaToEdit(criteria);
              },
            },
            {
              className: "menu-options-duplicate",
              label: "Duplicate",
              hasDividerAbove: false,
              handler: () => {
                createNew?.duplicateCriteria && createNew.duplicateCriteria(criteria);
              },
            },
            {
              className: "menu-options-delete",
              label: "Delete",
              handler: () => {
                createNew?.setCriteriaToDelete && createNew?.setCriteriaToDelete(criteria);
              },
              hasDividerAbove: true,
              color: theme.palette.errorColor[700],
            },
          ];
          return (
            <CriteriaListItem
              key={criteria.id}
              criteria={criteria}
              onToggle={(event) =>
                createNew?.updateIsEnabled && createNew?.updateIsEnabled(criteria, event.target.checked)
              }
              enableToggle={toggleDisabledTooltip === undefined}
              menuOptions={createNew ? menuOptions : [menuOptions[0]]}
              onEdit={() => {
                setCriteriaToEdit(criteria);
              }}
              toggleDisabledTooltip={toggleDisabledTooltip}
            />
          );
        })}
      </Stack>
    </Box>
  );
}
