import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";

interface InitialsIconProps {
  firstName: string;
  lastName: string;
  size: string;
  fontSize: string;
  BoxProps?: BoxProps;
  typographyProps?: TypographyProps;
}

export default function InitialsIcon(props: InitialsIconProps) {
  const { firstName, lastName, size, fontSize } = props;
  const initial =
    (firstName.length > 0 ? firstName[0] : "") +
    (lastName.length > 0 ? lastName[0] : "");
  return (
    <Box
      width={size}
      height={size}
      borderRadius="50%"
      display="grid"
      sx={{
        placeItems: "center",
        backgroundColor: (theme) => theme.palette.custom.navGrey,
      }}
      {...props.BoxProps}
    >
      <Typography
        fontSize={fontSize}
        fontWeight={400}
        color={(theme) => theme.palette.grey[500]}
        {...props.typographyProps}
      >
        {initial}
      </Typography>
    </Box>
  );
}
