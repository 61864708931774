import Stack from "@mui/material/Stack";
import { JobAlumniMatchDTO, PayPeriodEnum } from "../../models/api/job";
import Typography from "@mui/material/Typography";
import Checkbox, { CheckedState } from "../../components/Checkbox";
import Avatar, { AvatarSize } from "../../theme/Avatar";
import MatchAttributePill from "../../theme/MatchAttributePill";
import {
  JobMatchStatus,
  MatchAttributeType,
  getJobMatchStatusString,
} from "../../models/job";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Table, { TableColumn } from "../../components/Table";
import Tooltip from "../../theme/Tooltip";
import { Translation } from "../../lib/constants";
import NewTag from "../../theme/NewTag";
import { useContext } from "react";
import { FlagsContext, UserConfigContext } from "../../lib/context";
import {
  FormattedText,
  FormattedTextType,
} from "../../theme/FormattedText";
import { Format, formatAnnuPay, formatPayRate } from "../../lib/utils";

interface RolesViewTableProps {
  jobMatches: JobAlumniMatchDTO[];
  page: number;
  pageSize: number;
  totalRows: number;
  onPageChange: (page: number) => void;
  onCheckboxClick: (count: number) => void;
  isRoleClosed: boolean;
  selectedIds: Set<string>;
  setSelectedIds: (newSelectedIds: Set<string>) => void;
  onClick: (alumId: string) => void;
}

export default function RolesViewTable({
  jobMatches,
  page,
  pageSize,
  totalRows,
  onPageChange,
  onCheckboxClick,
  isRoleClosed,
  selectedIds,
  setSelectedIds,
  onClick,
}: RolesViewTableProps) {
  const { t, i18n } = useTranslation(Translation.rolesView);
  const common = useTranslation(Translation.common)[0];
  const [userConfig, _setUserConfig] = useContext(UserConfigContext);

  const orgName = jobMatches.length > 0 ? jobMatches[0].org_name : "";

  // Pagination on FE, may need to move to BE if performance is an issue
  const startIndex = page * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedJobMatches = jobMatches.slice(startIndex, endIndex);

  const allCheckedOnPage =
    jobMatches.length > 0 &&
    jobMatches.every((jobMatch) => selectedIds.has(jobMatch.applicant_id));

  const someCheckedOnPage =
    !allCheckedOnPage &&
    jobMatches.some((jobMatch) => selectedIds.has(jobMatch.applicant_id));

  const checkOrUncheckRow = (id: string) => {
    const newSelectedIds = new Set(selectedIds);
    if (newSelectedIds.has(id)) {
      newSelectedIds.delete(id);
    } else {
      newSelectedIds.add(id);
    }
    setSelectedIds(newSelectedIds);
    onCheckboxClick(newSelectedIds.size);
  };

  const checkOrUncheckOnPage = () => {
    const newSelectedIds = new Set(selectedIds);
    const selectableJobMatches = jobMatches.filter(
      (jobMatch) => jobMatch.status !== JobMatchStatus.HIRED,
    );
    // Uncheck all if all are checked, or some are checked
    if (allCheckedOnPage || someCheckedOnPage) {
      selectableJobMatches.forEach((jobMatch) => {
        newSelectedIds.delete(jobMatch.applicant_id);
      });
    }
    // Otherwise check all
    else {
      selectableJobMatches.forEach((jobMatch) => {
        if (jobMatch.is_subscribed) {
          newSelectedIds.add(jobMatch.applicant_id);
        }
      });
    }
    setSelectedIds(newSelectedIds);
    onCheckboxClick(newSelectedIds.size);
  };

  // Check to see if we want to show last Known Role and other post-departure related fields.
  const [flags] = useContext(FlagsContext);
  const showPostDepartureDataRelatedFields = () => {
    return flags?.includes("SHOW_POST_DEPARTURE_DATA") ?? false;
  };

  // If PDD is enabled but the current role is the same as the last role at the org,
  // and the current company is the same as the current org, we should render no text for
  // both the last role at org and the current company.
  const renderCorrectCurrentJobTitleText = (jobMatch: JobAlumniMatchDTO) => {
    if (
      jobMatch.last_role_at_org === jobMatch.current_job_title &&
      jobMatch.current_company === orgName
    ) {
      return "";
    }
    return jobMatch.current_job_title;
  };

  const renderCorrectCurrentCompanyText = (jobMatch: JobAlumniMatchDTO) => {
    if (
      jobMatch.last_role_at_org === jobMatch.current_job_title &&
      jobMatch.current_company === orgName
    ) {
      return "";
    }
    return jobMatch.current_company;
  };

  const minTableWidth =
    showPostDepartureDataRelatedFields() && userConfig?.hasSalaryData()
      ? "85.755rem"
      : userConfig?.hasSalaryData()
      ? "93.5rem"
      : showPostDepartureDataRelatedFields()
      ? "97.9425rem"
      : "70.9375rem";

  const columns: TableColumn<JobAlumniMatchDTO>[] = [
    {
      id: "selection",
      label: () => (
        <Stack height="1.5rem" justifyContent="center">
          <Tooltip
            title={
              selectedIds.size == 0 ? t("table.selectAllAlumni.label") : ""
            }
          >
            <div>
              <Checkbox
                checked={
                  // prettier-ignore
                  allCheckedOnPage ? CheckedState.CHECKED :
                  someCheckedOnPage ? CheckedState.MIXED :
                  CheckedState.UNCHECKED
                }
                onCheck={checkOrUncheckOnPage}
                disabled={isRoleClosed}
              />
            </div>
          </Tooltip>
        </Stack>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => (
        <Stack height="3rem" justifyContent="center">
          <Checkbox
            id={jobMatch.applicant_id + "-checkbox"}
            checked={
              selectedIds.has(jobMatch.applicant_id)
                ? CheckedState.CHECKED
                : CheckedState.UNCHECKED
            }
            onCheck={() => checkOrUncheckRow(jobMatch.applicant_id)}
            disabled={
              isRoleClosed ||
              !jobMatch.is_subscribed ||
              jobMatch.status === JobMatchStatus.HIRED
            }
          />
        </Stack>
      ),
      styles: {
        width: "3.92%",
        minWidth: "4.25rem",
        position: "sticky",
        left: 0,
        background: "inherit",
        zIndex: 1,
      },
    },
    {
      id: "name",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.name.label")}
        </Typography>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => {
        const isHired = jobMatch.status === JobMatchStatus.HIRED;
        return (
          <Tooltip title={isHired ? t("table.hiredTooltip") : ""}>
            <Box
              sx={{
                cursor: isHired ? "auto" : "pointer",
              }}
              onClick={() => {
                if (!isHired) {
                  onClick(jobMatch.applicant_id);
                }
              }}
            >
              <Avatar
                firstName={jobMatch.first_name}
                lastName={jobMatch.last_name}
                email={jobMatch.email}
                size={AvatarSize.MEDIUM}
                photoUrl={jobMatch.profile_photo_url}
                isSubscribed={jobMatch.is_subscribed}
                tag={jobMatch.is_new ? <NewTag /> : null}
                isDisabled={isHired}
              />
            </Box>
          </Tooltip>
        );
      },
      styles: {
        width: "19.50%",
        minWidth: "21.125rem",
        position: "sticky",
        left: "4.25rem",
        background: "inherit",
        zIndex: 1,
      },
    },
    {
      id: "topAttribute",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.topAttribute.label")}
        </Typography>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => (
        <MatchAttributePill
          attribute={jobMatch.notable_feature}
          attributeType={jobMatch.notable_feature_tag as MatchAttributeType}
          fontSize="0.75rem"
          lineHeight="1.125rem"
          isDisabled={jobMatch.status === JobMatchStatus.HIRED}
        />
      ),
      styles: {
        width: "13.85%",
        minWidth: "15rem",
      },
    },
    {
      id: "status",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.status.label")}
        </Typography>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => (
        <Stack>
          <FormattedText
            props={{
              value: getJobMatchStatusString(jobMatch.status, t),
              ellipsis: false,
            }}
            style={{
              sx: (theme) => {
                if (jobMatch.status === JobMatchStatus.HIRED) {
                  return {
                    width: "max-content",
                    fontSize: "0.75rem",
                    marginBottom: "0.25rem",
                    padding: "2px 6px",
                    borderRadius: "6px",
                    color: theme.palette.primary.main,
                    background: theme.palette.primary.light,
                    border: `1px solid ${theme.palette.custom.purpleBorder}`,
                  };
                }
                return {};
              },
            }}
          />
          <FormattedText
            props={{
              value: jobMatch.status_modified_date,
              format: Format.dateDayMonthYear,
              ellipsis: false,
              type: FormattedTextType.SECONDARY,
            }}
          />
        </Stack>
      ),
      styles: {
        width: "8.31%",
        minWidth: "9rem",
      },
    },
    {
      id: "emailsSent",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.emailsSent.label")}
        </Typography>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => (
        <Box
          width="max-content"
          marginLeft="auto"
          color={(theme) => {
            if (
              jobMatch.status === JobMatchStatus.HIRED ||
              jobMatch.num_emails_sent === 0
            ) {
              return theme.palette.grey[400];
            } else {
              return theme.palette.grey[600];
            }
          }}
        >
          {jobMatch.num_emails_sent}
        </Box>
      ),
      styles: {
        width: "6.92%",
        minWidth: "7.5rem",
      },
    },
    {
      id: "formerRole",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.formerRole.label", { org: orgName })}
        </Typography>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => (
        <Stack>
          <FormattedText
            props={{
              value: jobMatch.last_role_at_org,
              format: Format.uppercaseFirstLetter,
            }}
            style={{
              color: (theme) => {
                if (jobMatch.status === JobMatchStatus.HIRED) {
                  return theme.palette.grey[400];
                }
                return theme.palette.grey[800];
              },
            }}
          />
          <FormattedText
            props={{
              value: jobMatch.last_role_at_org_department,
              format: Format.uppercaseFirstLetter,
              type: FormattedTextType.SECONDARY,
            }}
            style={{
              color: (theme) => {
                if (jobMatch.status === JobMatchStatus.HIRED) {
                  return theme.palette.grey[400];
                }
                return theme.palette.grey[500];
              },
            }}
          />
        </Stack>
      ),
      styles: {
        width: "13.56%",
        minWidth: "14.6875rem",
      },
    },
    ...(userConfig?.hasSalaryData()
      ? [
          {
            id: "formerSalary",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.formerSalary.label")}
              </Typography>
            ),
            content: (jobMatch: JobAlumniMatchDTO) => {
              var payRate = jobMatch.former_salary;
              var payPeriod = jobMatch.former_salary_pay_period;

              if (payRate === 0 || payPeriod == null) return <></>;

              return (
                <Stack>
                  <FormattedText
                    props={{
                      value: formatPayRate(
                        payRate,
                        payPeriod,
                        common,
                        i18n.language,
                      ),
                    }}
                    style={{
                      align: "right",
                    }}
                  />
                  {payPeriod !== PayPeriodEnum.YEAR && (
                    <FormattedText
                      props={{
                        value: formatAnnuPay(
                          payRate,
                          payPeriod,
                          common,
                          i18n.language,
                        ),
                        type: FormattedTextType.SECONDARY,
                      }}
                      style={{
                        align: "right",
                      }}
                    />
                  )}
                </Stack>
              );
            },
            styles: {
              width: "9.58%",
              minWidth: "10.375rem",
            },
          },
        ]
      : []),
    ...(showPostDepartureDataRelatedFields()
      ? [
          {
            id: "lastKnownRole",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.lastKnownRole.label")}
              </Typography>
            ),
            content: (jobMatch: JobAlumniMatchDTO) => (
              <Stack>
                <FormattedText
                  props={{
                    value: renderCorrectCurrentJobTitleText(jobMatch),
                    format: Format.uppercaseFirstLetter,
                  }}
                  style={{
                    color: (theme) => {
                      if (jobMatch.status === JobMatchStatus.HIRED) {
                        return theme.palette.grey[400];
                      }
                      return theme.palette.grey[800];
                    },
                  }}
                />
                <FormattedText
                  props={{
                    value: renderCorrectCurrentCompanyText(jobMatch),
                    format: Format.uppercaseFirstLetter,
                    type: FormattedTextType.SECONDARY,
                  }}
                  style={{
                    color: (theme) => {
                      if (jobMatch.status === JobMatchStatus.HIRED) {
                        return theme.palette.grey[400];
                      }
                      return theme.palette.grey[500];
                    },
                  }}
                />
              </Stack>
            ),
            styles: {
              width: "13.56%",
              minWidth: "14.6875rem",
            },
          },
          {
            id: "lastKnownLocation",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.lastKnownLocation.label")}
              </Typography>
            ),
            content: (jobMatch: JobAlumniMatchDTO) => (
              <FormattedText
                props={{
                  value: jobMatch.last_known_location,
                  format: Format.location,
                }}
              />
            ),
            styles: {
              width: "11.25%",
              minWidth: "12.1875rem",
            },
          },
        ]
      : []),
  ];

  return (
    <Table
      columns={columns}
      rows={paginatedJobMatches}
      page={page}
      pageSize={pageSize}
      totalRows={totalRows}
      onPageChange={onPageChange}
      minWidth={minTableWidth}
      categoryPlural={t("paginationFooter.alumni")}
      categorySingular={t("paginationFooter.alum")}
    />
  );
}
