import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MuiButton from "@mui/material/Button";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import Drawer from "@mui/material/Drawer";
import { Translation } from "../../lib/constants";
import { ReactNode } from "react";

type Props = {
  description: string;
  isOpen: boolean;
  closePanel: () => void;
  children: ReactNode;
  submitEnabled: boolean;
  onSubmit: () => void;
  onClear: () => void;
};

export default function FilterPanel({
  description,
  isOpen,
  closePanel,
  children,
  submitEnabled,
  onSubmit,
  onClear,
}: Props) {
  const { t } = useTranslation(Translation.common);

  return (
    <Drawer anchor="right" open={isOpen} onClose={closePanel}>
      <Stack
        width={500}
        height="100vh"
        role="presentation"
        display="flex"
        flexDirection="column"
        id="filter-panel"
      >
        <Stack padding="24px" gap="24px" flexGrow="1" overflow="scroll">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="column" alignItems="start">
              <Typography
                fontSize="20px"
                fontWeight={600}
                lineHeight="28px"
                color={(theme) => theme.palette.grey[800]}
              >
                {t("filter.title")}
              </Typography>

              <Typography
                fontSize="14px"
                fontWeight={400}
                lineHeight="20px"
                color={(theme) => theme.palette.grey[500]}
              >
                {description}
              </Typography>
            </Stack>

            <IconButton
              id="close-filters-button"
              disableRipple
              aria-haspopup="true"
              onClick={closePanel}
              style={{ padding: 0 }}
            >
              <ClearIcon />
            </IconButton>
          </Stack>

          {children}
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          gap="12px"
          padding="16px 24px"
          borderTop="1px solid var(--Gray-200, #EAECF0)"
        >
          <MuiButton
            id="clear-filters-button"
            variant="outlined"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "1.2rem", 
              width: "92px",
              height: "40px",
              borderRadius: "8px",
              borderColor: theme.palette.grey[300],
              color: theme.palette.grey[600],
              "&:hover": {
                borderColor: theme.palette.grey[300],
              },
            }}
            disableRipple
            onClick={onClear}
          >
            {t("filter.resetAll")}
          </MuiButton>
          <MuiButton
            id="apply-filters-button"
            sx={{
              bgcolor: theme.palette.primaryColor[600],
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "1.2rem",
              width: "80px",
              height: "40px",
              borderRadius: "8px",
              color: "white",
              "&:hover": {
                bgcolor: theme.palette.primaryColor[700],
              },
              '&.Mui-disabled': {
                backgroundColor: theme.palette.primaryColor[200],
                color: "white"
              },
              transition: "none",
            }}
            disableRipple
            disabled={!submitEnabled}
            onClick={onSubmit}
          >
            {t("filter.apply")}
          </MuiButton>
        </Stack>
      </Stack>
    </Drawer>
  );
}
