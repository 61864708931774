export const SERVER_URL =
  import.meta.env?.VITE_SERVER_URL ?? "http://localhost:8080";

export const GA_MEASUREMENT_ID =
  import.meta.env?.VITE_GA_MEASUREMENT_ID ?? "";

// const COG_AUTHORITY = "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_UtAmdOd4B";
// const COG_CLIENT_ID = "29o12395fhgvcr2f6hg2f22g3s";

// const OKTA_AUTHORITY = "https://dev-48852829.okta.com/oauth2/default";
// const OKTA_CLIENT_ID = "0oake20enkkSB0Cdd5d7";

const ENTRA_AUTHORITY = "https://login.microsoftonline.com/ef842243-f9fc-4c40-9117-dc362a8e6fb5/v2.0";
const ENTRA_CLIENT_ID = "e578de17-3843-4ed7-8c0c-d527a7182896";

export const AUTHORITY = ENTRA_AUTHORITY;
export const CLIENT_ID = ENTRA_CLIENT_ID;
