import { useTranslation } from "react-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Header from "../../theme/Header";
import SearchBar from "../../components/Searchbar";
import Sort from "../../components/Sort";
import RolesDatabaseTable from "./RolesDatabaseTable";
import { PAGE_SIZE, trackGoogleAnalyticsEvent } from "../../lib/utils";
import { getJobDashboard } from "../../api/job";
import { RoleViewSortOptionEnum } from "../../models/api/job";
import { loadRoleSortFromStorage, setRoleSortInStorage } from "../../lib/utils";
import { Translation } from "../../lib/constants";
import { SearchbarContextType } from "../../models/api/common";
import Loading from "../../components/Loading";
import { UserContext } from "../../lib/context";
import { RolesDBEvent } from "../../lib/eventEnums";
import FilterButton from "../../components/Filter/FilterButton";
import FilterPanel from "../../components/Filter/FilterPanel";
import { useTableContext } from "../../components/TableView";
import { FilterMulitSelectCheckbox } from "../../components/UtilComponents";
import { BasicProfileDTO } from "../../models/api/filter";
import { DEFAULT_JOB_FILTERS, JobFilterOptions } from "../../models/filter";
import { Recruiters, JobLocations } from "../../components/Filter/FilterFields";
import { listRecruiters, listLocations } from "../../api/filter";

const validateFilters = (filters: JobFilterOptions) => {
  // at least one status filter must be true
  return Object.entries(filters.status).reduce(
    (acc, [_, value]) => acc || value,
    false,
  );
};

export default function RolesDatabase() {
  const { t } = useTranslation(Translation.rolesDatabase);

  const [isLoading, setIsLoading] = useState(false);
  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  const {
    totalRows,
    setTotalRows,
    page,
    setPage,
    searchTerm,
    setSearchTerm,
    jobs,
    setJobs,
    firstLoadTriggered,
    setFirstLoadTriggered,
    jobFilters,
    setJobFilters,
    editJobFilters,
    setEditJobFilters,
  } = useTableContext();

  const [filterOpen, setFilterOpen] = useState(false);
  const [recruiters, setRecruiters] = useState<BasicProfileDTO[]>([]);
  const [locations, setLocations] = useState<string[]>([]);

  const setStatusFilters = (status: string, value: boolean) => {
    setEditJobFilters({
      ...editJobFilters,
      status: { ...editJobFilters.status, [status]: value },
    });
  };

  const setRecruitersFilters = (recruitersIds: Set<string>) => {
    setEditJobFilters({
      ...editJobFilters,
      recruiterIds: recruitersIds,
    });
  };

  const setLocationsFilters = (locations: Set<string>) => {
    setEditJobFilters({
      ...editJobFilters,
      locations: locations,
    });
  };

  const updateFilterSelectionsCount = (filters: JobFilterOptions) => {
    let count = 0;

    Object.keys(filters).forEach((key) => {
      switch (key as keyof JobFilterOptions) {
        case "status":
          count += Object.values(filters.status).filter(
            (value) => value === true,
          ).length;
          break;
        case "recruiterIds":
          count += filters.recruiterIds.size;
          break;
        case "locations":
          count += filters.locations.size;
          break;
      }
    });

    return count;
  };

  const closeFilters = () => {
    setFilterOpen(false);
    setEditJobFilters(jobFilters);
  };

  const applyFilters = () => {
    setPage(0);
    setJobFilters(editJobFilters);
    setFilterOpen(false);
  };

  const sortOptions = [
    t("sortOption.newest"),
    t("sortOption.oldest"),
    t("sortOption.roleNameA-Z"),
    t("sortOption.roleNameZ-A"),
    t("sortOption.mostMatches"),
    t("sortOption.leastMatches"),
    t("sortOption.mostApplicants"),
    t("sortOption.leastApplicants"),
  ];
  const [selectedOption, setSelectedOption] = useState<string>(
    t(loadRoleSortFromStorage()),
  );

  const prevValues = useRef({
    page,
    searchTerm,
    selectedOption,
    filters: jobFilters,
  }).current;

  const getSortOptionsDesc = (selectedOption: string) => {
    setRoleSortInStorage(selectedOption);
    switch (selectedOption) {
      case t("sortOption.newest"):
        return {
          sortOption: RoleViewSortOptionEnum.POSTED_DATE,
          sortDesc: true,
        };
      case t("sortOption.oldest"):
        return {
          sortOption: RoleViewSortOptionEnum.POSTED_DATE,
          sortDesc: false,
        };
      case t("sortOption.roleNameA-Z"):
        return {
          sortOption: RoleViewSortOptionEnum.JOB_NAME,
          sortDesc: false,
        };
      case t("sortOption.roleNameZ-A"):
        return {
          sortOption: RoleViewSortOptionEnum.JOB_NAME,
          sortDesc: true,
        };
      case t("sortOption.mostMatches"):
        return {
          sortOption: RoleViewSortOptionEnum.NUM_MATCHED,
          sortDesc: true,
        };
      case t("sortOption.leastMatches"):
        return {
          sortOption: RoleViewSortOptionEnum.NUM_MATCHED,
          sortDesc: false,
        };
      case t("sortOption.mostApplicants"):
        return {
          sortOption: RoleViewSortOptionEnum.NUM_APPLICATIONS,
          sortDesc: true,
        };
      case t("sortOption.leastApplicants"):
        return {
          sortOption: RoleViewSortOptionEnum.NUM_APPLICATIONS,
          sortDesc: false,
        };
      default:
        return {
          sortOption: RoleViewSortOptionEnum.POSTED_DATE,
          sortDesc: true,
        };
    }
  };

  useEffect(() => {
    if (
      prevValues.page !== page ||
      prevValues.searchTerm !== searchTerm ||
      prevValues.selectedOption !== selectedOption ||
      prevValues.filters !== jobFilters ||
      !firstLoadTriggered
    ) {
      fetchJobDashboard();
      setFirstLoadTriggered(true);
      prevValues.page = page;
      prevValues.searchTerm = searchTerm;
      prevValues.selectedOption = selectedOption;
      prevValues.filters = jobFilters;
    }
  }, [page, selectedOption, searchTerm, jobFilters]);

  const fetchJobDashboard = async () => {
    setIsLoading(true);
    try {
      const { sortDesc, sortOption } = getSortOptionsDesc(selectedOption);
      const response = await getJobDashboard(
        orgId,
        page,
        PAGE_SIZE,
        sortOption,
        sortDesc,
        jobFilters,
        searchTerm,
      );
      setJobs(response.jobs);
      setIsLoading(false);
      setTotalRows(response.total);
    } catch {
      // TODO: BOO-1036
    }
  };

  useEffect(() => {
    fetchRecruiters();
  }, []);

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchRecruiters = async () => {
    try {
      const response = await listRecruiters(orgId);
      setRecruiters(response.recruiters);
    } catch (error) {
      console.error("Error fetching recruiters for recruiter filter: ", error);
      setRecruiters([]);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await listLocations(orgId);
      setLocations(response.locations);
    } catch (error) {
      console.error("Error fetching locations for location filter: ", error);
      setLocations([]);
    }
  };

  const handleSortChange = (option: string) => {
    setPage(0);
    setSelectedOption(option);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSearch = (searchValue: string) => {
    trackGoogleAnalyticsEvent({
      event: RolesDBEvent.ROLES_DB_SEARCH_QUERY,
      org: user?.orgName,
      type: searchValue,
    });
    setPage(0);
    setSearchTerm(searchValue);
  };

  const trackSortAnalytics = (option: string) => {
    trackGoogleAnalyticsEvent({
      event: RolesDBEvent.ROLES_DB_SORT,
      org: user?.orgName,
      type: option,
    });
  };

  return (
    <Stack padding="2rem">
      <Header title={t("title")} />
      <Stack
        direction="row"
        margin="2rem 0 1rem"
        padding="1rem 1rem 1rem 0rem"
        gap="0.75rem"
      >
        <SearchBar
          placeholder={t("searchPlaceholder")}
          onSearch={handleSearch}
          context={SearchbarContextType.ROLES_DATABASE}
          filters={jobFilters}
          searchTerm={searchTerm}
        />
        <FilterButton
          isDisabled={isLoading}
          onClick={() => setFilterOpen(true)}
          count={updateFilterSelectionsCount(jobFilters)}
        />
        <Sort
          options={Object.values(sortOptions)}
          selectedOption={selectedOption}
          onSortChange={handleSortChange}
          trackAnalyticsEvent={trackSortAnalytics}
        />
      </Stack>
      {isLoading ? (
        <Loading />
      ) : (
        <RolesDatabaseTable
          jobs={jobs}
          page={page}
          pageSize={PAGE_SIZE}
          totalRows={totalRows}
          onPageChange={handlePageChange}
        />
      )}

      <FilterPanel
        description={t("filter.subtitle")}
        isOpen={filterOpen}
        closePanel={closeFilters}
        submitEnabled={validateFilters(editJobFilters)}
        onSubmit={applyFilters}
        onClear={() => setEditJobFilters(DEFAULT_JOB_FILTERS)}
      >
        <Stack direction="column" gap="1.5rem">
          <FilterMulitSelectCheckbox
            label={t("filter.status.heading")}
            optionStatus={new Map(Object.entries(editJobFilters.status))}
            optionLabels={
              new Map(
                Object.entries(editJobFilters.status).map(
                  ([status, _value]) => [status, t(`filter.status.${status}`)],
                ),
              )
            }
            setFilters={setStatusFilters}
          />
          <Recruiters
            allRecruiters={recruiters}
            selectedIds={editJobFilters.recruiterIds}
            setSelectedIds={setRecruitersFilters}
          />
          <JobLocations
            allLocations={locations}
            selectedLocations={editJobFilters.locations}
            setSelectedLocations={setLocationsFilters}
          />
        </Stack>
      </FilterPanel>
    </Stack>
  );
}
