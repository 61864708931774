import { Box, Divider, Stack } from "@mui/material";
import { NoteDTO } from "../../models/api/alum";
import NoteCard from "./NoteCard";
import theme from "../../theme";

interface NoteListProps {
  notes: NoteDTO[];
  onNoteClick: (note: NoteDTO) => void;
  showFinalDivider?: boolean;
}

export const NoteList = ({
  notes,
  onNoteClick,
  showFinalDivider = false,
}: NoteListProps) => {
  return (
    <Stack className="note-list">
      {notes.map((note, idx) => (
        <Stack key={note.id}>
          <Box
            onClick={() => onNoteClick(note)}
            sx={{
              cursor: "pointer",
              borderRadius: "0.5rem",
              px: "0.5rem",
              pt: idx === 0 ? "0.5rem" : "1.25rem",
              pb: (idx === notes.length - 1 && !showFinalDivider) ? "0.5rem" : "1.25rem",
              "&:hover": {
                backgroundColor: theme.palette.grey[50],
              },
            }}
          >
            <NoteCard note={note} />
          </Box>
          {(idx !== notes.length - 1 || showFinalDivider) && <Divider />}
        </Stack>
      ))}
    </Stack>
  );
};
