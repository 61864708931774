export type AlumniDbFilterOptions = {
  last_known_locations: Set<string>;
  former_departments: Set<string>;
  former_employment_types: Map<string, boolean>;
  former_salary: {
    min: string;
    max: string;
  };
  tenure: {
    min: string;
    max: string;
  };
  time_since_departure: {
    years: number;
    months: number;
  };
};

export const getAlumniDbDefaultFilters = (
  employmentTypes?: string[],
): AlumniDbFilterOptions => {
  return {
    last_known_locations: new Set(),
    former_departments: new Set(),
    former_employment_types: employmentTypes
      ? new Map(employmentTypes.map((type) => [type, false]))
      : new Map(),
    former_salary: {
      min: "",
      max: "",
    },
    tenure: {
      min: "",
      max: "",
    },
    time_since_departure: {
      years: 0,
      months: 0,
    },
  };
};

type AllowedStatusFilters = JobStatusEnum.OPEN | JobStatusEnum.CLOSED;

export type JobFilterOptions = {
  status: Pick<{ [key in JobStatusEnum]: boolean }, AllowedStatusFilters>;
  recruiterIds: Set<string>;
  locations: Set<string>;
};

export const DEFAULT_JOB_FILTERS: JobFilterOptions = {
  status: {
    OPEN: true,
    CLOSED: false,
  },
  recruiterIds: new Set(),
  locations: new Set(),
};

export enum JobStatusEnum {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  DRAFT = "DRAFT",
  ARCHIVED = "ARCHIVED",
  PENDING = "PENDING",
}
