import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import NavItem from "../../theme/NavItem";
import { Translation } from "../../lib/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FlagsContext } from "../../lib/context.ts";

interface SettingsSidebarProps {
  onClose: () => void;
}

const SettingsSidebar = ({ onClose }: SettingsSidebarProps) => {
  const { t } = useTranslation(Translation.common);
  const [flags] = useContext(FlagsContext);
  const [selected, setSelected] = useState<number | null>(null);
  const navigate = useNavigate();

  const style = {
    width: "260px",
    height: "100%",
    bgcolor: "background.paper",
    overflowX: "hidden",
    overflowY: "hidden",
    transition: "0.3s",
    zIndex: 1000,
    position: "absolute",
    left: "15.6rem",
    top: 0,
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/settings/role-matching":
        setSelected(0);
        break;
      case "/settings/referral-matching":
        setSelected(1);
        break;
      case "/settings/sales-and-partnership-matching":
        setSelected(2);
        break;
      default:
        setSelected(null);
    }
  }, [location.pathname]);

  const handleNavigation = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <Box
      sx={style}
      borderRight={(theme) => `1px solid ${theme.palette.divider}`}
    >
      <Stack
        id="settingsSidebar"
        height="100%"
        minWidth="15.6rem"
        marginTop="2rem"
      >
        <Stack
          height="100%"
          gap="0.5rem"
          padding="0 1rem"
          alignItems="flex-start"
        >
          <NavItem
            title={t("settingsSidebar.roleMatching")}
            selected={selected === 0}
            onClick={() => handleNavigation("/settings/role-matching")}
          />
          {flags?.includes("REFERRAL_MATCHING_CRITERIA_ENABLED") && (
            <NavItem
              title={t("settingsSidebar.referralMatching")}
              selected={selected === 1}
              onClick={() => handleNavigation("/settings/referral-matching")}
            />
          )}
          {flags?.includes("SALES_AND_PARTNERSHIPS") && (
            <NavItem
              title={t("settingsSidebar.salesAndPartnershipMatching")}
              selected={selected === 2}
              onClick={() =>
                handleNavigation("/settings/sales-and-partnership-matching")
              }
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default SettingsSidebar;
