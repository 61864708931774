import { useContext } from "react";
import theme from "../../theme";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArrowIcon from "@mui/icons-material/ArrowForwardRounded";
import { useTranslation } from "react-i18next";
import { AlumDTO, JobHistoryDTO } from "../../models/api/alum";
import { getTimeSinceDeparture, formatTenure } from "../../lib/time";
import RoleHistoryRow from "./RoleHistoryRow";
import { Link } from "react-router-dom";
import { Translation } from "../../lib/constants";
import calendarIcon from "/calendar.svg";
import { AlumProfileEvent } from "../../lib/eventEnums";
import { UserContext } from "../../lib/context";
import { trackGoogleAnalyticsEvent } from "../../lib/utils";
import Divider from "@mui/material/Divider";
import EmptyList from "../EmptyList";

interface RoleHistoryTimelineProps {
  alum: AlumDTO;
  limitTo?: number;
}

export default function RoleHistoryTimeline({
  alum,
  limitTo,
}: RoleHistoryTimelineProps) {
  const { t } = useTranslation(Translation.alumProfile);
  const [user, _setUser] = useContext(UserContext);

  const jobHistory = alum.job_history;
  const orgName = alum.org_name;
  const endDateAtOrg = alum.end_date_at_org
    ? new Date(alum.end_date_at_org)
    : null;

  const totalTenure = alum.tenure_at_org
    ? formatTenure(alum.tenure_at_org)
    : null;
  const timeSinceDeparture = getTimeSinceDeparture(endDateAtOrg);

  let jobHistoryToDisplay = jobHistory;
  if (limitTo != null) {
    jobHistoryToDisplay = jobHistoryToDisplay.slice(0, limitTo);
  }

  const [jobHistoryWithDates, jobHistoryWithNoDates] =
    jobHistoryToDisplay.reduce(
      (acc, job) => {
        if (job.start_date || job.end_date) {
          acc[0].push(job);
        } else {
          acc[1].push(job);
        }
        return acc;
      },
      [[] as JobHistoryDTO[], [] as JobHistoryDTO[]],
    );

  const handleSeeAllRolesClick = () => {
    trackGoogleAnalyticsEvent({
      event: AlumProfileEvent.ALUM_PROFILE_HISTORY_SEE_ALL,
      type: "alum_profile_see_all_history",
      org: user?.orgName,
    });
  };

  return (
    <>
      {alum.job_history.length === 0 ? (
        <EmptyList
          icon={calendarIcon}
          iconAlt={t("roleMatches.iconAlt")}
          title={t("roleHistory.empty.title")}
          message={t("roleHistory.empty.subtitle")}
        />
      ) : (
        <Stack gap="1.5rem" width="100%">
          <Stack
            width="100%"
            direction="row"
            padding="0.25rem"
            gap="1.5rem"
            borderRadius="0.5rem"
            sx={{
              backgroundColor: theme.palette.grey[50],
            }}
          >
            {totalTenure && (
              <Typography
                padding="0.5rem 0.75rem"
                fontSize="0.875rem"
                fontWeight={500}
                lineHeight="1.25rem"
                color={theme.palette.grey[600]}
              >
                {t("roleHistory.totalTenure", {
                  orgName: orgName,
                  time: `${totalTenure.year}y ${totalTenure.month}m`,
                })}
              </Typography>
            )}
            <Typography
              padding="0.5rem 0.75rem"
              fontSize="0.875rem"
              fontWeight={500}
              lineHeight="1.25rem"
              color={theme.palette.grey[600]}
            >
              {t("roleHistory.timeSinceDeparture", {
                time: `${timeSinceDeparture.year}y ${timeSinceDeparture.month}m`,
              })}
            </Typography>
          </Stack>
          <Box>
            {jobHistoryWithDates.map((job, index) => {
              const prevJobFromSameOrg =
                index > 0 &&
                jobHistoryWithDates[index - 1].org_name === job.org_name;
              return (
                <RoleHistoryRow
                  key={index}
                  job={job}
                  showOrgLogo={!prevJobFromSameOrg}
                  showTimeline={true}
                  isLastKnownRole={index === 0}
                />
              );
            })}
            {jobHistoryWithNoDates.length > 0 && (
              <>
                <Divider
                  sx={{
                    marginY: "1.5rem",
                    color: theme.palette.grey[500],
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    fontWeight: 500,
                  }}
                >
                  {t("roleHistory.rolesWithUnspecifiedDates")}
                </Divider>
                {jobHistoryWithNoDates.map((job, index) => {
                  return (
                    <RoleHistoryRow
                      key={index}
                      job={job}
                      showOrgLogo={true}
                      showTimeline={false}
                      isLastKnownRole={false}
                    />
                  );
                })}
              </>
            )}
            {jobHistory.length > jobHistoryToDisplay.length && (
              <Link
                className="see-all-roles"
                to="role-history"
                style={{ textDecoration: "none" }}
                onClick={handleSeeAllRolesClick}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="0.5rem"
                  padding="0.625rem 0.5rem"
                  width="max-content"
                  borderRadius="0.5rem"
                  sx={{
                    cursor: "pointer",
                    color: theme.palette.grey[600],
                    "&:hover": {
                      color: theme.palette.grey[700],
                      background: theme.palette.grey[50],
                    },
                  }}
                >
                  <Typography
                    color="inherit"
                    fontSize="0.875rem"
                    fontWeight={600}
                    lineHeight="1.25rem"
                    sx={{ userSelect: "none" }}
                  >
                    {t("roleHistory.seeAllRoles")}
                  </Typography>
                  <ArrowIcon sx={{ color: "inherit" }} />
                </Stack>
              </Link>
            )}
          </Box>
        </Stack>
      )}
    </>
  );
}
