import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

interface HeaderProps {
  title: string;
  showBorder?: boolean;
}

export default function Header(props: HeaderProps) {
  const { title, showBorder = true } = props;
  return (
    <Box
      borderBottom={(theme) =>
        showBorder ? `1px solid ${theme.palette.divider}` : "none"
      }
      paddingBottom="1.25rem"
      className="header"
    >
      <Typography
        fontSize="1.5rem"
        fontWeight={600}
        lineHeight="2rem"
        color={(theme) => theme.palette.grey[800]}
      >
        {title}
      </Typography>
    </Box>
  );
}
