import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ReactNode } from "react";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

interface CenterModalProps {
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

export default function CenterModal(props: CenterModalProps) {
  const { open, onClose, children, sx } = props;
  return (
    <Modal
      className="modal"
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "transparent" },
        },
      }}
      sx={(theme) => ({
        background: `hsl(from ${theme.palette.grey[700]} h s l / 70%)`,
      })}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: (theme) => theme.spacing(2),
          ...sx,
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}
