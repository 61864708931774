import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import arrowLeft from "/public/chevron-left.svg";
import doubleArrowLeft from "/public/arrow-left-double.svg";
import arrowRight from "/public/arrow-right.svg";
import doubleArrowRight from "/public/arrow-right-double.svg";
import disabledArrowLeft from "/public/disabled-arrow-left.svg";
import disabledDoubleArrowLeft from "/public/disabled-arrow-left-double.svg";
import disabledArrowRight from "/public/disabled-arrow-right.svg";
import disabledDoubleArrowRight from "/public/disabled-arrow-right-double.svg";
import { Translation } from "../../lib/constants";
import theme from "../../theme";
import Button from "../../theme/Button";

const MAX_PAGINATION_WITHOUT_ELLIPSES = 6;

interface PaginationProps {
  page: number;
  pageSize: number;
  totalRows: number;
  onPageChange: (page: number) => void;
  categoryPlural: string;
  categorySingular: string;
  maxPagesToShow?: number;
}

export default function Pagination(props: PaginationProps) {
  const { t } = useTranslation(Translation.common);
  const {
    page,
    pageSize,
    totalRows,
    onPageChange,
    categoryPlural,
    categorySingular,
    maxPagesToShow = MAX_PAGINATION_WITHOUT_ELLIPSES,
  } = props;

  const nextPage = page + 1;
  const firstRowNumber = Math.min(page * pageSize + 1, totalRows);
  const lastRowNumber = Math.min(nextPage * pageSize, totalRows);

  const pagesToShow: number[][] = [];
  const currPage = page + 1;
  const lastPage = Math.ceil(totalRows / pageSize);
  const disableLeftArrow = currPage === 1;
  const disableRightArrow = currPage === lastPage;

  const range = (start: number, end: number) =>
    Array.from({ length: end - start + 1 }, (_, i) => start + i);

  if (lastPage <= Math.max(maxPagesToShow, MAX_PAGINATION_WITHOUT_ELLIPSES)) {
    pagesToShow.push(range(1, lastPage));
  } else if (currPage < 3 || currPage > lastPage - 2) {
    pagesToShow.push(range(1, 3), range(lastPage - 2, lastPage));
  } else if (currPage == 3) {
    pagesToShow.push(range(1, 4), [lastPage]);
  } else if (currPage == lastPage - 2) {
    pagesToShow.push([1], range(lastPage - 3, lastPage));
  } else {
    pagesToShow.push([1], range(currPage - 1, currPage + 1), [lastPage]);
  }

  return (
    <Stack
      className="pagination-footer"
      direction="row"
      alignItems="center"
      padding="0.75rem 1.5rem"
      justifyContent="space-between"
    >
      <Typography
        fontSize="0.75rem"
        lineHeight="1.125rem"
        color={(theme) => theme.palette.grey[500]}
      >
        {t("pagination", {
          count: totalRows,
          start: firstRowNumber,
          end: lastRowNumber,
          category_plural: categoryPlural,
          category_singular: categorySingular,
        })}
      </Typography>
      <Stack direction="row" alignItems="center">
        <Button
          buttonProps={{
            className: "to-first-page",
            onClick: () => onPageChange(0),
            disabled: false,
            disableRipple: true,
            sx: {
              height: "2.5rem",
              minWidth: "2.5rem",
              padding: "0rem",
              fontSize: "0.875rem",
              boxShadow: "none",
              borderRadius: "50%",
              cursor: "pointer",
              backgroundColor: theme.palette.background.default,
              "&:hover": {
                backgroundColor: disableLeftArrow
                  ? theme.palette.background.default
                  : theme.palette.grey[50],
                boxShadow: "none",
              },
            },
          }}
        >
          <Box
            component="img"
            src={disableLeftArrow ? disabledDoubleArrowLeft : doubleArrowLeft}
          />
        </Button>
        <Button
          buttonProps={{
            className: "to-prev-page",
            onClick: () => onPageChange(Math.max(page - 1, 0)),
            disabled: false,
            disableRipple: true,
            sx: {
              height: "2.5rem",
              minWidth: "2.5rem",
              padding: "0rem",
              fontSize: "0.875rem",
              boxShadow: "none",
              borderRadius: "50%",
              cursor: "pointer",
              backgroundColor: theme.palette.background.default,
              "&:hover": {
                backgroundColor: disableLeftArrow
                  ? theme.palette.background.default
                  : theme.palette.grey[50],
                boxShadow: "none",
              },
            },
          }}
        >
          <Box
            component="img"
            src={disableLeftArrow ? disabledArrowLeft : arrowLeft}
          />
        </Button>
        {pagesToShow.map((pageNumbers, index) => (
          <Stack
            key={index}
            direction="row"
            className="pagination-page-numbers"
          >
            {pageNumbers.map((pageNumber) => (
              <Box
                key={pageNumber}
                className={`page-${pageNumber}`}
                width="2.5rem"
                height="2.5rem"
                borderRadius="50%"
                sx={(theme) => ({
                  display: "grid",
                  placeItems: "center",
                  cursor: "pointer",
                  backgroundColor:
                    currPage === pageNumber ? theme.palette.grey[50] : "none",
                  "&:hover": {
                    backgroundColor: theme.palette.grey[50],
                  },
                })}
                onClick={() => onPageChange(pageNumber - 1)}
              >
                <Typography
                  fontSize="12px"
                  fontWeight={500}
                  lineHeight="18px"
                  color={
                    currPage === pageNumber
                      ? theme.palette.grey[800]
                      : theme.palette.grey[500]
                  }
                >
                  {pageNumber}
                </Typography>
              </Box>
            ))}
            {index < pagesToShow.length - 1 && (
              <Typography
                fontSize="12px"
                fontWeight={500}
                lineHeight="18px"
                className="pagination-ellipsis"
                color={(theme) => theme.palette.grey[500]}
                sx={{
                  marginTop: "0.65rem",
                }}
              >
                ...
              </Typography>
            )}
          </Stack>
        ))}
        <Button
          buttonProps={{
            className: "to-next-page",
            onClick: () => onPageChange(Math.min(page + 1, lastPage - 1)),
            disabled: false,
            disableRipple: true,
            sx: {
              height: "2.5rem",
              minWidth: "2.5rem",
              padding: "0rem",
              fontSize: "0.875rem",
              boxShadow: "none",
              borderRadius: "50%",
              cursor: "pointer",
              backgroundColor: theme.palette.background.default,
              "&:hover": {
                backgroundColor: disableRightArrow
                  ? theme.palette.background.default
                  : theme.palette.grey[50],
                boxShadow: "none",
              },
            },
          }}
        >
          <Box
            component="img"
            src={disableRightArrow ? disabledArrowRight : arrowRight}
          />
        </Button>
        <Button
          buttonProps={{
            className: "to-last-page",
            onClick: () => onPageChange(lastPage - 1),
            disabled: false,
            disableRipple: true,
            sx: {
              height: "2.5rem",
              minWidth: "2.5rem",
              padding: "0rem",
              fontSize: "0.875rem",
              boxShadow: "none",
              borderRadius: "50%",
              cursor: "pointer",
              backgroundColor: theme.palette.background.default,
              "&:hover": {
                backgroundColor: disableRightArrow
                  ? theme.palette.background.default
                  : theme.palette.grey[50],
                boxShadow: "none",
              },
            },
          }}
        >
          <Box
            component="img"
            src={
              disableRightArrow ? disabledDoubleArrowRight : doubleArrowRight
            }
          />
        </Button>
      </Stack>
    </Stack>
  );
}
