import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../../lib/context";
import Box from "@mui/material/Box";
import Sidebar from "../Sidebar";
import ErrorElement from ".";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";

export default function ErrorPage() {
  const { t } = useTranslation(Translation.common);
  const navigate = useNavigate();
  const [user, _setUser] = useContext(UserContext);

  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <Sidebar />
      <ErrorElement
        title={t("error.unexpected")}
        message={t("error.detailed")}
        buttons={[{ text: "Home", onClick: () => navigate("/roles-database") }]}
      />
    </Box>
  );
}
