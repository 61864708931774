import { useTranslation } from "react-i18next";
import theme from "../../theme";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import briefcaseIcon from "/briefcase-light.svg";
import verifiedCheckIcon from "/verified-check.svg";
import { getEmploymentTypeDisplay, JobHistoryDTO } from "../../models/api/alum";
import { Translation } from "../../lib/constants";
import { formatUTCMonthAndYear } from "../../lib/time";
import {
  FormattedText,
  FormattedTextType,
} from "../../theme/FormattedText";
import { Format } from "../../lib/utils";

interface RoleHistoryRowProps {
  job: JobHistoryDTO;
  showOrgLogo: boolean;
  showTimeline: boolean;
  isLastKnownRole: boolean;
}

export default function RoleHistoryRow({
  job,
  showOrgLogo,
  showTimeline,
  isLastKnownRole,
}: RoleHistoryRowProps) {
  const { t, i18n } = useTranslation(Translation.alumProfile);
  const common = useTranslation(Translation.common)[0];

  const startDate =
    job.start_date && formatUTCMonthAndYear(job.start_date, i18n);
  const endDate = job.end_date && formatUTCMonthAndYear(job.end_date, i18n);

  let dateString = "";
  if (startDate && endDate) {
    dateString = `${startDate} - ${endDate}`;
  } else if (startDate && !endDate) {
    dateString = `${startDate} - ${t("roleHistory.present")}`;
  } else if (!startDate && endDate) {
    dateString = `${t("roleHistory.unspecified")} - ${endDate}`;
  } else {
    dateString = t("roleHistory.unspecified");
  }

  const managerName = job.manager
    ? `${job.manager.first_name} ${job.manager.last_name}`
    : null;

  const companyAndEmploymentType = [
    job.org_name,
    getEmploymentTypeDisplay(job.employment_type, common),
  ].filter((name) => name != null && name.trim() !== "");

  const showVerifiedIcon = job.is_from_hris && job.is_from_requesting_org;

  return (
    <Stack direction="row" gap="1rem">
      <Stack width="1.5rem">
        {showTimeline && (
          <>
            <Box padding="0.5rem">
              <Box
                width="0.5rem"
                height="0.5rem"
                borderRadius="50%"
                sx={{
                  background: job.is_from_requesting_org
                    ? theme.palette.primary.main
                    : theme.palette.grey[300],
                }}
              />
            </Box>
            <Box
              margin="0.25rem auto"
              width="0.125rem"
              height="100%"
              borderRadius="0.125rem"
              sx={{
                background: job.is_from_requesting_org
                  ? theme.palette.primary.main
                  : theme.palette.grey[200],
              }}
            />
          </>
        )}
      </Stack>
      <Box width="2rem" height="2rem">
        {showOrgLogo && (
          <Box
            width="100%"
            height="100%"
            borderRadius="50%"
            overflow="hidden"
            display="grid"
            sx={{
              placeItems: "center",
              backgroundColor: theme.palette.grey[100],
            }}
          >
            <Box
              component="img"
              alt={t("roleHistory.orgLogoAlt")}
              src={job.org_logo_url ?? briefcaseIcon}
              width="1.25rem"
              height="1.25rem"
            />
          </Box>
        )}
      </Box>
      <Stack gap="0.25rem" marginBottom="2rem" flexGrow={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormattedText
            props={
              job.title === "" ?
              { value: t("roleHistory.untitledRole") } :
              { value: job.title, format: Format.uppercaseFirstLetter }
            }
            style={{
              fontWeight: 600,
            }}
          />
          {showVerifiedIcon && (
            <Tooltip
              title={t("roleHistory.verifiedTooltip")}
              componentsProps={{
                tooltip: {
                  sx: {
                    padding: "0.75rem",
                    background: theme.palette.common.white,
                    color: theme.palette.grey[500],
                    borderRadius: "0.5rem",
                    maxWidth: "20rem",
                    fontSize: "0.75rem",
                    lineHeight: "1.125rem",
                    boxShadow: 3,
                  },
                },
              }}
            >
              <Box
                component="img"
                src={verifiedCheckIcon}
                alt={t("roleHistory.verifiedIconAlt")}
                margin="0 auto 0 0.5rem"
                width="1rem"
                height="1rem"
              />
            </Tooltip>
          )}
          <FormattedText
            props={{
              value: dateString,
              type: FormattedTextType.SECONDARY,
            }}
            style={{
              textAlign: "right",
            }}
          />
        </Stack>
        {companyAndEmploymentType && (
          <FormattedText
            props={{
              value: companyAndEmploymentType.join(" • "),
              format: Format.uppercaseFirstLetter,
              type: FormattedTextType.PRIMARY,
            }}
          />
        )}
        {job.location && (
          <FormattedText
            props={{
              value: job.location,
              format: Format.location,
              type: FormattedTextType.SECONDARY,
            }}
          />
        )}
        {job.department && (
          <FormattedText
            props={{
              value: job.department,
              format: Format.uppercaseFirstLetter,
              type: FormattedTextType.SECONDARY,
            }}
          />
        )}
        {managerName && (
          <FormattedText
            props={{
              value: managerName,
              format: Format.uppercaseFirstLetter,
              type: FormattedTextType.SECONDARY,
            }}
          />
        )}
        {isLastKnownRole && (
          <Typography
            padding="0.125rem 0.375rem"
            border={`1px solid ${theme.palette.grey[300]}`}
            borderRadius="0.375rem"
            fontSize="0.75rem"
            lineHeight="1.125rem"
            width="max-content"
            marginTop="0.25rem"
          >
            {t("roleHistory.lastKnownRole")}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}