import { identity } from "lodash";
import EllipsisText from "./EllipsisText";
import { Typography, TypographyProps } from "@mui/material";
import theme from ".";
import { useTranslation } from "react-i18next";
import { Translation } from "../lib/constants";

type FormattedTextProps = {
  value: string | number | boolean | null;
  format?: (value: string, language?: string) => string;
  ellipsis?: boolean;
  type?: FormattedTextType;
};

export function FormattedText({
  props,
  style,
}: {
  props: FormattedTextProps;
  style?: TypographyProps;
}) {
  const {
    value,
    format = identity,
    ellipsis = true,
    type = FormattedTextType.PRIMARY,
  } = props;
  if (value == null || value == undefined) return "";

  const { i18n } = useTranslation(Translation.common);

  let defaultStyle = {};

  switch (type) {
    case FormattedTextType.PRIMARY:
      defaultStyle = {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "1.25rem",
        color: theme.palette.grey[800],
      };
      break;
    case FormattedTextType.SECONDARY:
      defaultStyle = {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "1.25rem",
        color: theme.palette.grey[500],
      };
      break;
    case FormattedTextType.TERTIARY:
      defaultStyle = {
        color: theme.palette.grey[500],
        fontSize: "0.75rem",
        fontWeight: "400",
        lineHeight: "1.125rem",
      };
      break;
  }

  return ellipsis ? (
    <EllipsisText {...defaultStyle} {...style}>
      {format(value.toString(), i18n.language)}
    </EllipsisText>
  ) : (
    <Typography {...defaultStyle} {...style}>
      {format(value.toString(), i18n.language)}
    </Typography>
  );
}

export enum FormattedTextType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
}
