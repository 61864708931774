import theme from ".";
import Typography from "@mui/material/Typography";
import { MatchAttributeType } from "../models/job";

interface MatchAttributePillProps {
  attribute: string;
  attributeType: MatchAttributeType;
  fontSize?: string;
  lineHeight?: string;
  isDisabled?: boolean;
}

export default function MatchAttributePill(props: MatchAttributePillProps) {
  const { attribute, attributeType, fontSize, lineHeight, isDisabled } = props;

  let textColor = "";
  let backgroundColor = "";
  switch (attributeType) {
    case MatchAttributeType.PROMOTION:
      textColor = theme.palette.attribute.promotion.text;
      backgroundColor = theme.palette.attribute.promotion.background;
      break;
    case MatchAttributeType.SALARY:
      textColor = theme.palette.attribute.salary.text;
      backgroundColor = theme.palette.attribute.salary.background;
      break;
    case MatchAttributeType.TENURE:
      textColor = theme.palette.attribute.tenure.text;
      backgroundColor = theme.palette.attribute.tenure.background;
      break;
    case MatchAttributeType.LOCATION:
      textColor = theme.palette.attribute.location.text;
      backgroundColor = theme.palette.attribute.location.background;
      break;
  }

  return (
    <Typography
      width="max-content"
      padding="0.125rem 0.5rem"
      borderRadius="1rem"
      fontSize={fontSize}
      fontWeight={400}
      lineHeight={lineHeight}
      color={textColor}
      sx={{
        backgroundColor: backgroundColor,
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      {attribute}
    </Typography>
  );
}
