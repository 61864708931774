import { Stack, Typography } from "@mui/material";
import React from "react";

type CriteriaHeadingProps = {
  listHeader: string;
  listSubHeader: string;
  children: React.ReactElement | null;
};

export default function CriteriaHeading({
  listHeader,
  listSubHeader,
  children,
}: CriteriaHeadingProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="column" alignItems="start" spacing={1} padding="2rem 0">
        <Typography
          fontSize="18px"
          fontWeight={600}
          lineHeight="28px"
          color={(theme) => theme.palette.grey[800]}
          className="criteria-list-header"
        >
          {listHeader}
        </Typography>
        <Typography
          fontSize="14px"
          lineHeight="20px"
          color={(theme) => theme.palette.grey[500]}
          className="criteria-list-subheader"
        >
          {listSubHeader}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
}
