import { Link, LinkProps } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

const LinkWithoutUnderline = styled(Link)(() => ({
  textDecoration: "none",
}));

interface TextLinkProps {
  text: string;
  icon?: React.ReactNode;
  linkProps: LinkProps;
}

export default function TextLink(props: TextLinkProps) {
  const { text, linkProps, icon } = props;
  return (
    <LinkWithoutUnderline {...linkProps} style={ { ...linkProps.style, display: "flex", gap: "8px", alignItems: "center" } }>
      { icon !== undefined && icon}
      <Typography
        fontSize="0.875rem"
        fontWeight={600}
        color="inherit"
      >
        {text}
      </Typography>
    </LinkWithoutUnderline>
  );
}
