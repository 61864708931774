import { useNavigate, useSearchParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { setUserInStorage, trackGoogleAnalyticsEvent } from "../../lib/utils";
import { LoginEvent } from "../../lib/eventEnums";
import { UserContext } from "../../lib/context";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import { AuthUser } from "../../models/auth";
import Typography from "@mui/material/Typography";

export default function SamlInit() {
  const { t } = useTranslation(Translation.login);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [_, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // this is a redirect request from the server after the user has been authenticated via SAML
    // get the user from the 302 req url search params and set it in the react state
    let user = createUser();
    
    if (user !== null) {
      initUser(user);
    } else {
      setLoading(false);
    }
  }, []);

  const createUser = (): AuthUser | null => {
    let email = searchParams.get("email");
    let firstName = searchParams.get("firstName");
    let lastName = searchParams.get("lastName");
    let orgId = searchParams.get("orgId");
    let orgName = searchParams.get("orgName");

    if (email === null || orgId === null || orgName === null) {
      return null;
    } else {
      return {
        email: email,
        firstName: firstName,
        lastName: lastName,
        orgId: orgId,
        orgName: orgName,
      }
    }
  };

  const initUser = async (user: AuthUser) => {
    setUser(user);
    setUserInStorage(user);
    trackGoogleAnalyticsEvent({
      event: LoginEvent.LOGIN,
      org: user.orgName,
      name: `${user.firstName}` + `${user.lastName}`,
    });
    navigate("/");
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
      padding="10rem"
    >
      {!loading ? (
        <Stack
        width="98vw"
        padding="1rem"
        gap="32px"
        alignItems="center"
        justifyContent="end"
      >
        <Typography fontSize="1.875rem" fontWeight={600}>
          {t("initializingError")}
        </Typography>
      </Stack>
        
      ) : (
        <Stack
          width="98vw"
          padding="1rem"
          gap="32px"
          alignItems="center"
          justifyContent="end"
        >
          <Loading text={t("initializingReactUser")} />
        </Stack>
      )}
    </Stack>
  );
}
