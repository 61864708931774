import theme from ".";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function SubtitleWithIcon(props: {
  id?: string | undefined;
  subtitle: string;
  icon: string;
  iconAlt: string;
}) {
  return (
    <Typography
      id={props.id}
      fontSize="0.875rem"
      lineHeight="1.25rem"
      color={theme.palette.grey[500]}
      display="flex"
      alignItems="center"
      gap="0.5rem"
    >
      <Box
        component="img"
        sx={{ width: "1rem" }}
        alt={props.iconAlt}
        src={props.icon}
      />
      {props.subtitle}
    </Typography>
  );
}
