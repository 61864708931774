import { Box, Stack, Typography } from "@mui/material";
import theme from "../../theme";
import { NoteDTO } from "../../models/api/alum.ts";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants.ts";
import rightArrowSvg from "/arrow-right.svg";
import {
  FormattedText,
  FormattedTextType,
} from "../../theme/FormattedText.tsx";
import { Format } from "../../lib/utils.ts";
import EllipsisText from "../../theme/EllipsisText.tsx";
import { SvgButton, SvgButtonSize } from "../../theme/SvgButton.tsx";

interface NoteCardProps {
  note: NoteDTO;
  expanded?: boolean;
}

export default function NoteCard({ note, expanded }: NoteCardProps) {
  const { t } = useTranslation(Translation.alumProfile);

  if (expanded) {
    return (
      <Stack gap="1rem">
        <Typography
          fontSize="0.875rem"
          fontWeight={400}
          lineHeight="1.25rem"
          color={(theme) => theme.palette.grey[800]}
        >
          {note.content}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap="0.5rem">
      <Stack direction="row">
        <EllipsisText
          fontSize="0.875rem"
          fontWeight={600}
          color={(theme) => theme.palette.grey[800]}
          flexGrow={1}
        >
          {note.content}
        </EllipsisText>
        <SvgButton src={rightArrowSvg} size={SvgButtonSize.MEDIUM} />
      </Stack>
      <Box>
        <Typography
          fontSize="0.875rem"
          fontWeight={400}
          color={theme.palette.grey[500]}
        >
          {t("notes.createdBy", {
            firstName: note.created_by.first_name,
            lastName: note.created_by.last_name,
          })}
        </Typography>
        <FormattedText
          props={{
            value: note.created_date,
            format: Format.dateAsRelativeTime,
            ellipsis: true,
            type: FormattedTextType.SECONDARY,
          }}
          style={{ fontSize: "0.875rem" }}
        />
      </Box>
    </Stack>
  );
}
