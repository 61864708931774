import theme from ".";
import styled from "@emotion/styled";
import Switch, { SwitchProps } from "@mui/material/Switch";

const switchSizes = {
  "small": {
    width: 32,
    height: 20,
    thumb: 16,
    translate: 16
  },
  "large": {
    width: 36,
    height: 20,
    thumb: 16,
    translate: 16
  }
}

type CustomSwitchProps = SwitchProps & { switchsize: keyof typeof switchSizes }

export default styled((props: CustomSwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({switchsize}) => ({
    width: switchSizes[switchsize].width,
    height: switchSizes[switchsize].height,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: `translateX(${switchSizes[switchsize].translate}px)`,
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primaryColor[600],
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: theme.palette.primaryColor[200],
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: "white"
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: switchSizes[switchsize].thumb,
      height: switchSizes[switchsize].thumb,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));