import theme from ".";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function NewTag() {
  const { t } = useTranslation("common");
  return (
    <Stack
      direction="row"
      gap="0.25rem"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        width="0.375rem"
        height="0.375rem"
        borderRadius="50%"
        sx={{ backgroundColor: theme.palette.success.main }}
      />
      <Typography
        fontSize="0.75rem"
        fontWeight={500}
        lineHeight="1.125rem"
        color={theme.palette.success.main}
      >
        {t("new")}
      </Typography>
    </Stack>
  );
}
