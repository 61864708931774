export namespace Translation {
  export const rolesDatabase = "rolesDatabase";
  export const alumniDatabase = "alumniDatabase";
  export const rolesView = "rolesView";
  export const common = "common";
  export const login = "login";
  export const settings = "settings";
  export const alumProfile = "alumProfile";
  export const authenticator = "authenticator";
  export const email = "email";
  export const password = "password";
  export const sendEmailModal = "sendEmailModal";
}

export namespace RouteId {
  export const rolesDatabase = "roles-database";
  export const alumDatabase = "alumni-database";
  export const rolesView = "rolesView";
  export const alumProfile = "alumProfile";

  export const rolesDatabaseRolesView = rolesDatabase + "-" + rolesView;
  export const rolesDatabaseAlumProfile = rolesDatabase + "-" + alumProfile;
  export const alumDatabaseRolesView = alumDatabase + "-" + rolesView;
  export const alumDatabaseAlumProfile = alumDatabase + "-" + alumProfile;
}

export const INITIAL_FETCH_SIZE = 200;

export const REDIRECT_URL = `${window.location.origin}/oauth2/callback`;
export const SIGNOUT_REDIRECT_URL = `${window.location.origin}/login`;