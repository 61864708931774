import { Alert, AlertTitle, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import theme from ".";
import { ReactNode } from "react";

interface InfoAlertProps {
  children?: ReactNode;
  heading: string;
}

export default function InfoAlert({ children, heading }: InfoAlertProps) {
  return (
    <Alert
      severity="info"
      variant="outlined"
      className="info-alert"
      icon={
        <InfoOutlinedIcon
          htmlColor={theme.palette.notification.info.color}
          sx={{ mr: "0.3rem" }}
        />
      }
      sx={{
        bgcolor: theme.palette.notification.info.backgroundColor,
        color: theme.palette.notification.info.color,
        p: "0.6rem 1rem",
        borderColor: theme.palette.notification.info.borderColor,
        fontSize: "0.875rem",
        mt: "1rem",
        mb: "1.4rem",
      }}
    >
      <AlertTitle
        className="info-alert-title"
        sx={{ fontSize: "inherit", fontWeight: 600 }}
      >
        {heading}
      </AlertTitle>
      <Typography fontSize="inherit" className="info-alert-body">
        {children}
      </Typography>
    </Alert>
  );
}
