import Stack from "@mui/material/Stack";
import theme from ".";
import { ElementType } from "react";

interface IconButtonProps {
  id?: string;
  Icon: ElementType;
  onClick: () => void;
  isDisabled?: boolean;
}

export default function IconButton(props: IconButtonProps) {
  const { id, Icon, onClick, isDisabled } = props;
  return (
    <Stack
      className="icon-button"
      id={id}
      borderRadius="50%"
      width="1.5rem"
      height="1.5rem"
      border={`1px solid ${theme.palette.grey[400]}`}
      alignItems="center"
      justifyContent="center"
      sx={{
        cursor: !isDisabled ? "pointer" : "default",
        "&:hover": {
          backgroundColor: !isDisabled ? theme.palette.grey[50] : "initial",
        },
      }}
      onClick={!isDisabled ? onClick : undefined}
    >
      <Icon
        sx={{
          width: "0.875rem",
          color: !isDisabled
            ? theme.palette.grey[600]
            : theme.palette.grey[400],
        }}
      />
    </Stack>
  );
}
