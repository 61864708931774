import MuiTooltip, { TooltipProps } from "@mui/material/Tooltip";

export default function Tooltip(props: TooltipProps) {
  return (
    <MuiTooltip
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            padding: "0.5rem 0.75rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            borderRadius: "0.5rem",
            bgcolor: "grey.800",
            "& .MuiTooltip-arrow": {
              color: "grey.800",
            },
          },
        },
      }}
      {...props}
    />
  );
}

export function TooltipWhite(props: TooltipProps) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            padding: "0.75rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            borderRadius: "0.5rem",
            bgcolor: "white",
            color: "grey.500",
            fontSize: "0.75rem",
            fontWeight: "500",
            lineHeight: "1.125rem",
            boxShadow: "0px 0.5rem 0.5rem #00000034",
          },
        },
      }}
      {...props}
    />
  );
}
