import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import theme from "../../theme";
import { Typography } from "@mui/material";
import { Translation } from "../../lib/constants";

export default function Loading({text: propsText}: { text?: string }) {
  const { t } = useTranslation(Translation.common);

  return (
    <Stack
      sx=
      {{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }}
    >
      <CircularProgress
        size={35}
        thickness={6}
        sx={{
          color: theme.palette.primary.dark,
          borderRadius: '50%',
          boxShadow: `inset 0 0 0 5.5px ${theme.palette.grey[100]}`,
          '& .MuiCircularProgress-circle': {
            strokeLinecap: 'round'
          }
        }} />
      <Typography fontWeight="500" fontSize="0.875rem" color={(theme) => theme.palette.grey[600]} style={{ marginTop: "1.5rem" }}>
        { propsText ? propsText : t("loading") }
      </Typography>
    </Stack>
  );
}
