import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import MultiSelectComboBox, { MultiSelectOption } from ".";
import { DEBOUNCE_DELAY } from "../../lib/utils";

interface ApiSupportedMultiSelectComboBoxProps {
  id?: string;
  suggestions: MultiSelectOption[];
  setSuggestions?: React.Dispatch<React.SetStateAction<MultiSelectOption[]>>;
  selectedValues: Set<string>;
  setSelectedValues: (values: Set<string>) => void;
  inputErrorMessage?: string;
  getOptions: (searchTerm: string) => Promise<MultiSelectOption[]>;
  placeholder?: string;
  maxSelectedValues?: number;
  format?: (value: string) => string;
  onFocus?: () => void;
  disabled?: boolean;
  selectAllOption?: MultiSelectOption;
  isOptionSelected?: (option: MultiSelectOption) => boolean;
}

export default function ApiSupportedMultiSelectComboBox({
  id,
  suggestions,
  setSuggestions,
  selectedValues,
  setSelectedValues,
  inputErrorMessage,
  getOptions,
  placeholder,
  maxSelectedValues,
  format,
  disabled,
}: ApiSupportedMultiSelectComboBoxProps) {
  const [searchTerm, setSearchTerm] = useState("");
  let [localSuggestions, setLocalSuggestions] = setSuggestions
    ? [suggestions, setSuggestions]
    : useState(suggestions);

  const debouncedGetSuggestions = useCallback(
    debounce(async (searchTerm) => {
      // filters out the unselected options from previous search and leaves the selected options
      // since its information is still needed to display the selected tags
      let updatedSuggestions = localSuggestions.filter((option) =>
        selectedValues.has(option.value),
      );

      let newSuggestions: MultiSelectOption[] = await getOptions(searchTerm);

      newSuggestions = newSuggestions.filter(
        (suggestion) => !selectedValues.has(suggestion.value),
      );

      if (newSuggestions.length !== 0)
        updatedSuggestions = updatedSuggestions.concat(newSuggestions);

      setLocalSuggestions(updatedSuggestions);
    }, DEBOUNCE_DELAY),
    [selectedValues, localSuggestions],
  );

  useEffect(() => {
    debouncedGetSuggestions(searchTerm);
    return () => debouncedGetSuggestions.cancel();
  }, [searchTerm]);

  return (
    <MultiSelectComboBox
      id={id}
      options={localSuggestions}
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
      inputErrorMessage={inputErrorMessage}
      getInputValue={setSearchTerm}
      placeholder={placeholder}
      maxSelectedValues={maxSelectedValues}
      format={format}
      disabled={disabled}
    />
  );
}
