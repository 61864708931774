import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import textLogo from "/text-logo.svg";
import settingsIcon from "/settings.svg";
import chevronRight from "/chevron-right.svg";
import rolesDatabaseIcon from "/briefcase.svg";
import alumniDatabaseIcon from "/users.svg";
import logOutIcon from "/log-out.svg";
import helpIcon from "/help-circle-primary.svg";
import linkIcon from "/link-external-secondary.svg";
import NavItem from "../../theme/NavItem";
import SettingsSidebar from "../SettingsSidebar";
import { useContext } from "react";
import { OAuthUserManager, UserContext } from "../../lib/context";
import Avatar, { AvatarSize } from "../../theme/Avatar";
import { signOut } from "../../api/auth";
import { removeUserFromStorage, trackGoogleAnalyticsEvent, USER_GUIDE_URL } from "../../lib/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { SIGNOUT_REDIRECT_URL, Translation } from "../../lib/constants";
import { LoginEvent } from "../../lib/eventEnums";

export default function Sidebar() {
  const { t } = useTranslation(Translation.common);
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useContext(UserContext);
  const [isSettingsSidebarVisible, setIsSettingsSidebarVisible] =
    useState(false);
  if (!user) return <></>;

  useEffect(() => {
    if (location.pathname !== "/settings") {
      setIsSettingsSidebarVisible(false);
    }
  }, [location]);

  const determineActiveItem = () => {
    switch (true) {
      case location.pathname.startsWith("/roles-database"):
        return "rolesDatabase";
      case location.pathname.startsWith("/alumni-database"):
        return "alumniDatabase";
      case location.pathname.startsWith("/settings"):
        return "settings";
      default:
        return "";
    }
  };

  const [activeItem, setActiveItem] = useState(determineActiveItem());

  const handleSignOut = async () => {
    trackGoogleAnalyticsEvent({
      event: LoginEvent.LOGOUT,
      org: user.orgName,
      name: `${user.firstName}` + `${user.lastName}`,
    });

    const userInfo = await OAuthUserManager.getUser()
    const isSSOUser = userInfo ? true : false
    console.log("is SSO user:", isSSOUser);

    await signOut();
    removeUserFromStorage();
    const redirectState = { message: t("loggedOut", { ns: Translation.login }) }

    if (isSSOUser) {
      await OAuthUserManager.signoutRedirect({
        post_logout_redirect_uri: SIGNOUT_REDIRECT_URL,
        state: redirectState,
      });
    } else {
      setUser(null);
      navigate("/login", { state: redirectState });
    }
  };

  const toggleSettingsSidebar = () => {
    setIsSettingsSidebarVisible(!isSettingsSidebarVisible);
    setActiveItem("settings");
  };

  const handleHelp = () => {
    window.open(USER_GUIDE_URL, "_blank");
  };

  useEffect(() => {
    setActiveItem(determineActiveItem());
  }, [location]);

  return (
    <Box sx={{ display: "flex" }}>
      <Stack
        id="sidebar"
        height="100%"
        minWidth="15.6rem"
        maxWidth="15.6rem"
        borderRight={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Box
          component="img"
          sx={{ width: "8.8rem", margin: "1.5rem", marginTop: "2rem" }}
          alt={t("boomerangLogoAlt")}
          src={textLogo}
        />
        <Stack
          height="100%"
          gap="0.5rem"
          padding="0 1rem"
          alignItems="flex-start"
        >
          <NavItem
            title={t("sidebar.rolesDatabase")}
            icon={rolesDatabaseIcon}
            selected={activeItem === "rolesDatabase"}
            onClick={() => {
              const prevPath = location.pathname;
              navigate("/roles-database");
              if (prevPath.startsWith("/roles-database")) {
                navigate(0);
              }
              setActiveItem("rolesDatabase");
              setIsSettingsSidebarVisible(false);
            }}
          />
          <NavItem
            title={t("sidebar.alumniDatabase")}
            icon={alumniDatabaseIcon}
            selected={activeItem === "alumniDatabase"}
            onClick={() => {
              const prevPath = location.pathname;
              navigate("/alumni-database");
              if (prevPath.startsWith("/alumni-database")) {
                navigate(0);
              }
              setActiveItem("alumniDatabase");
              setIsSettingsSidebarVisible(false);
            }}
          />
          <NavItem
            title={t("sidebar.settings")}
            icon={settingsIcon}
            rightIcon={chevronRight}
            selected={activeItem === "settings"}
            onClick={toggleSettingsSidebar}
          />
          <NavItem
            title={t("sidebar.help")}
            icon={helpIcon}
            selected={false}
            onClick={handleHelp}
            attachedIcon={linkIcon}
            atBottom
          />
          <NavItem
            title={t("sidebar.logOut")}
            icon={logOutIcon}
            selected={false}
            onClick={handleSignOut}
          />
        </Stack>
        <Avatar
          firstName={user.firstName ?? ""}
          lastName={user.lastName ?? ""}
          email={user.email}
          size={AvatarSize.SMALL}
          stackProps={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            margin: "1.5rem 1rem 2rem",
            padding: "1.5rem 0.5rem 0",
          }}
        />
      </Stack>
      {isSettingsSidebarVisible && (
        <SettingsSidebar onClose={toggleSettingsSidebar} />
      )}
    </Box>
  );
}
