import { http } from ".";
import { HttpError, HttpResponse, ResponseStatus } from "../models/api/http";
import {
  EmailTemplateDTO,
  EmailTemplateResponse,
  EmailTemplateTypeEnum,
} from "../models/api/resource";

export const getEmailTemplates = async (
  orgId: string,
): Promise<EmailTemplateResponse> => {
  const response = await http.get<HttpResponse<EmailTemplateResponse>>(
    `/api/org/${orgId}/email_template/list`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const getEmailTemplate = async (
  orgId: string,
  type: EmailTemplateTypeEnum,
): Promise<EmailTemplateDTO> => {
  let url = `/api/org/${orgId}/email_template/${type}`;
  const response = await http.get<HttpResponse<EmailTemplateDTO>>(url);
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};
