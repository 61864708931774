import theme from ".";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";

interface ButtonProps extends MuiButtonProps {
  children: React.ReactNode;
  buttonProps?: MuiButtonProps;
  type?: "button" | "submit" | "reset";
}

export default function Button(props: ButtonProps) {
  const { children, buttonProps, type = "submit" } = props;
  return (
    <MuiButton
      variant="contained"
      type={type}
      {...buttonProps}
      sx={{
        borderRadius: "0.5rem",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:disabled": {
          backgroundColor: theme.palette.custom.purpleDisabled,
          color: theme.palette.common.white,
        },
        transition: "none",
        ...buttonProps?.sx,
      }}
    >
      {children}
    </MuiButton>
  );
}
