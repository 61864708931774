import { i18n } from "i18next";

const SEC_IN_YEAR = 60 * 60 * 24 * 365;
const MILLI_IN_YEAR = 1000 * SEC_IN_YEAR;
const MONTHS_IN_YEAR = 12;

export const formatTenure = (tenureInSecs: number) => {
  const years = tenureInSecs / SEC_IN_YEAR;
  return {
    year: Math.floor(years),
    month: Math.floor((years % 1) * MONTHS_IN_YEAR),
  };
};

export const formatTimeSinceCrossover = (timeSinceCrossoverInSecs: number) => {
  // Get years and month since crossover where year and month are rounded to the
  // nearest whole number... e.g. 1.5 years -> 2 years; 6.4 months -> 6 months
  const years = timeSinceCrossoverInSecs / SEC_IN_YEAR;
  return {
    year: Math.round(years),
    month: Math.round((years % 1) * MONTHS_IN_YEAR),
  };
};

export const getTimeSinceDeparture = (endDateAtOrg: Date | null) => {
  if (endDateAtOrg == null) {
    return {
      year: 0,
      month: 0,
    };
  }
  const currentDate = new Date();
  const years =
    (currentDate.getTime() - endDateAtOrg.getTime()) / MILLI_IN_YEAR;
  return {
    year: Math.floor(years),
    // Flooring instead of rounding to prevent 0y 12m from being displayed
    month: Math.floor((years % 1) * MONTHS_IN_YEAR),
  };
};

export const formatUTCMonthAndYear = (date: string, i18n: i18n) => {
  return new Date(date).toLocaleDateString(i18n.language, {
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  });
};

export const formatUTCYear = (date: string, i18n: i18n) => {
  return new Date(date).toLocaleDateString(i18n.language, {
    year: "numeric",
    timeZone: "UTC",
  });
};

export const getDaysSinceDate = (date: string) => {
  const pastDate = new Date(date);
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const now = new Date();
  // @ts-ignore
  return Math.max(Math.round((now - pastDate) / oneDay), 0);
};

export const displayDate = (date: string) => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });
};
