import { useTranslation } from "react-i18next";
import { useState, ChangeEvent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import textLogo from "/text-logo.svg";
import ErrorMessage from "../../theme/ErrorMessage";
import { PasswordInput } from "../../theme/Input";
import Button from "@mui/material/Button";
import { resetPassword, validateResetToken } from "../../api/auth";
import { Translation } from "../../lib/constants";
import theme from "../../theme";
import Loading from "../../components/Loading";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function ResetPassword() {
  const { t } = useTranslation(Translation.password);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordReqs, setPasswordReqs] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    match: false,
  });
  const [submitError, setSubmitError] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const handleChange =
    (key: keyof typeof formData) => (event: ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [key]: event.target.value });
    };

  useEffect(() => {
    setPasswordReqs({
      length: formData.password.length >= 8,
      lowercase: /[a-z]/.test(formData.password),
      uppercase: /[A-Z]/.test(formData.password),
      number: /[0-9]/.test(formData.password),
      special: /[!@#$%^&*-]/.test(formData.password),
      match:  formData.password !== "" && formData.password === formData.confirmPassword,
    });
  }, [formData]);

  const validateForm = () => {
    return (
      passwordReqs.length &&
      passwordReqs.lowercase &&
      passwordReqs.uppercase &&
      passwordReqs.number &&
      passwordReqs.special &&
      passwordReqs.match
    );
  };

  const handleSubmit = async () => {
    try {
      const response = await resetPassword(token!, formData.password);
      if (response) {
        navigate("/login", {
          state: { message: t("reset.notifications.loginRedirect") },
        });
      } else {
        setSubmitError(true);
      }
    } catch (error: any) {
      setSubmitError(true);
    }
  };

  const checkTokenExpiry = async (token: string) => {
    try {
      const res = await validateResetToken(token);
      if (res) {
        setLoading(false);
      } else {
        navigate("/forgot-password", {
          state: { message: t("reset.notifications.forgotRedirectSubtitle") },
        });
      }
    } catch (error) {
      navigate("/forgot-password", {
        state: { message: t("reset.notifications.forgotRedirectSubtitle") },
      });
    }
  };

  useEffect(() => {
    if (loading) {
      if (token) {
        checkTokenExpiry(token);
      } else {
        navigate("/");
      }
    }
  }, [loading]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Stack
      justifyContent="start"
      alignItems="center"
      minHeight="100vh"
      width="100vw"
      padding="2rem"
    >
      <Stack width="26rem" gap="32px" alignItems="center" justifyContent="end">
        <Box
          component="img"
          sx={{ width: "9rem" }}
          alt={t("boomerangLogoAlt", { ns: Translation.common })}
          src={textLogo}
        />

        <Stack alignItems="center" spacing="0.75rem">
          <Typography fontSize="1.875rem" fontWeight={600}>
            {t("reset.title")}
          </Typography>
          <Typography
            fontSize="1rem"
            fontWeight={400}
            color="text.secondary"
            textAlign="center"
          >
            {t("reset.subtitle")}
          </Typography>
        </Stack>

        {submitError && (
          <ErrorMessage
            error={t("error.unexpected", { ns: Translation.common })}
          />
        )}

        <Stack width="100%" gap="20px">
          <PasswordInput
            value={formData.password}
            placeholder={t("reset.passwordPlaceholder")}
            onChange={handleChange("password")}
          />
          <PasswordInput
            placeholder={t("reset.resetPasswordPlaceholder")}
            onChange={handleChange("confirmPassword")}
            value={formData.confirmPassword}
          />

          <Stack gap="12px" justifyContent="center">
            {Object.entries(passwordReqs).map(([key, isValid], ind) => {
              return (
                <Stack key={ind} direction="row" gap="8px">
                  <CheckCircleIcon
                    sx={{
                      color: isValid
                        ? theme.palette.success.dark
                        : theme.palette.grey[300],
                    }}
                  />
                  <Typography
                    fontSize="0.875rem"
                    color={theme.palette.grey[600]}
                  >
                    {t(`reset.passwordReq.${key}`)}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>

          <Button
            onClick={handleSubmit}
            disabled={!validateForm()}
            variant="contained"
            sx={{
              borderRadius: "0.5rem",
              boxShadow: "none",
              height: "2.5rem",
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                boxShadow: "none",
              },
              "&:disabled": {
                backgroundColor: theme.palette.custom.purpleDisabled,
                color: theme.palette.common.white,
              },
              transition: "none",
            }}
          >
            {t("reset.cta")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
