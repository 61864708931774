import { useState, useContext, useEffect } from "react";
import ModalTemplate from "./ModalTemplate";
import { Translation } from "../../lib/constants";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { JobDashboardDTO, RoleViewSortOptionEnum } from "../../models/api/job";
import { JobFilterOptions } from "../../models/filter";
import SearchBar from "../Searchbar";
import { SearchbarContextType } from "../../models/api/common";
import { trackGoogleAnalyticsEvent } from "../../lib/utils";
import { UserContext } from "../../lib/context";
import { SendEmailAddRoleEvent } from "../../lib/eventEnums";
import { getJobDashboard } from "../../api/job";
import theme from "../../theme";
import JobDetailsBox from "./JobDetailsBox";
import EmptyResult from "../Error/EmptyResult";
import searchIcon from "/search.svg";
import Loading from "../Loading";
import { toast } from "react-toastify";

const NUM_OF_RECENT_ROLES = 3;
const MAX_NUM_OF_ROLES_RESULTS = 10;
const SEARCH_BAR_FILTERS: JobFilterOptions = {
  status: {
    OPEN: true,
    CLOSED: false,
  },
  recruiterIds: new Set(),
  locations: new Set()
};

interface AddRoleProps {
  onClose: () => void;
  onAddRole: (jobs: JobDashboardDTO[]) => void;
  backButtonOnClick: () => void;
  maxRolesSelected: number;
  preSelectedJobIds: Set<string>;
}

export default function AddRole({
  onClose,
  onAddRole,
  backButtonOnClick,
  maxRolesSelected,
  preSelectedJobIds,
}: AddRoleProps) {
  const { t } = useTranslation(Translation.sendEmailModal);
  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedJobIds, setSelectedJobIds] = useState<Set<string>>(new Set());
  const [jobs, setJobs] = useState<JobDashboardDTO[]>([]);
  const [jobListTitle, setJobListTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (searchValue: string) => {
    trackGoogleAnalyticsEvent({
      event: SendEmailAddRoleEvent.SEND_EMAIL_ADD_ROLE_SEARCH_QUERY,
      org: user?.orgName,
      type: searchValue,
    });
    setSearchTerm(searchValue);
  };

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      let numOfRolesDisplayed = MAX_NUM_OF_ROLES_RESULTS;
      setJobListTitle(t("addRole.searchResults"));

      if (searchTerm === "") {
        numOfRolesDisplayed = NUM_OF_RECENT_ROLES;
        setJobListTitle(t("addRole.recentRoles"));
      }

      const response = await getJobDashboard(
        orgId,
        0,
        numOfRolesDisplayed,
        RoleViewSortOptionEnum.POSTED_DATE,
        true,
        SEARCH_BAR_FILTERS,
        searchTerm,
      );
      setJobs(response.jobs);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      toast.error(t("toast.addRolesError"), {
        containerId: "send-role-error",
      });
    }
  };

  const onSelectJob = (jobId: string) => {
    const newSelectedJobIds = new Set(selectedJobIds);
    newSelectedJobIds.has(jobId)
      ? newSelectedJobIds.delete(jobId)
      : newSelectedJobIds.add(jobId);
    setSelectedJobIds(newSelectedJobIds);
  };

  const unSelectHiddenJobs = () => {
    const newSelectedJobIds = new Set<string>();
    jobs.forEach((job) => {
      if (selectedJobIds.has(job.id)) {
        newSelectedJobIds.add(job.id);
      }
    });
    setSelectedJobIds(newSelectedJobIds);
  };

  useEffect(() => {
    fetchJobs();
  }, [searchTerm]);

  useEffect(() => {
    unSelectHiddenJobs();
  }, [jobs]);

  return (
    <ModalTemplate
      heading={t("addRole.label")}
      onClose={onClose}
      primaryButtonOnClick={() =>
        onAddRole(
          jobs.filter(
            (job) =>
              selectedJobIds.has(job.id) && !preSelectedJobIds.has(job.id),
          ),
        )
      }
      primaryButtonText={t("addRole.add")}
      backButtonOnClick={backButtonOnClick}
      primaryButtonDisabled={selectedJobIds.size === 0}
    >
      <Stack
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.grey[200]}`,
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
          overflow: "auto",
        })}
        height="100%"
        display="flex"
        marginTop={2}
        padding="1rem 1.5rem"
      >
        <SearchBar
          placeholder={t("addRole.searchPlaceholder")}
          onSearch={handleSearch}
          context={SearchbarContextType.ROLES_DATABASE}
          filters={SEARCH_BAR_FILTERS}
          searchbarWidth="100%"
        />
        {isLoading ? (
          <Loading />
        ) : jobs.length == 0 ? (
          <EmptyResult text={t("addRole.emptyResult")} imgSrc={searchIcon} />
        ) : (
          <Stack spacing="0.5rem">
            <Typography
              color={theme.palette.grey[500]}
              fontWeight={400}
              fontSize="0.875rem"
              lineHeight="1.25rem"
              pt="1rem"
            >
              {jobListTitle}
            </Typography>
            {jobs.map((job) => (
              <JobDetailsBox
                job={job}
                selectable={{
                  onSelect: () => onSelectJob(job.id),
                  isChecked:
                    selectedJobIds.size !== 0 && selectedJobIds.has(job.id),
                  isDisabled:
                    selectedJobIds.size === maxRolesSelected &&
                    !selectedJobIds.has(job.id),
                }}
                key={job.id}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </ModalTemplate>
  );
}
