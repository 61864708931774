import { Box, Stack, Typography, IconButton } from "@mui/material";
import { useContext } from "react";
import theme from "../../theme";
import { JobDashboardDTO } from "../../models/api/job";
import SubtitleWithIcon from "../../theme/SubtitleWithIcon";
import { UserConfigContext } from "../../lib/context";
import briefCaseIcon from "/briefcase-light.svg";
import locationIcon from "/location-marker.svg";
import userIcon from "/user.svg";
import calendarIcon from "/calendar.svg";
import { Translation } from "../../lib/constants";
import { useTranslation } from "react-i18next";
import Checkbox, { CheckedState } from "../Checkbox";
import deleteIcon from "/trash-icon.svg";
import whiteCheckIcon from "/check-white.svg";
import {
  FormattedText,
} from "../../theme/FormattedText";
import { Format } from "../../lib/utils";

interface JobDetailsBoxProps {
  job: JobDashboardDTO;
  selectable?: {
    onSelect: () => void;
    isChecked: boolean;
    isDisabled: boolean;
  };
  onDelete?: () => void;
}

export default function JobDetailsBox({
  job,
  selectable,
  onDelete,
}: JobDetailsBoxProps) {
  const { t } = useTranslation(Translation.sendEmailModal);
  const [userConfig, _setUserConfig] = useContext(UserConfigContext);

  const onClickJob = () => {
    if (selectable && !selectable.isDisabled) {
      selectable.onSelect();
    }
  };

  return (
    <Box
      className="job-details-box"
      bgcolor={theme.palette.custom.greyBackground}
      borderRadius="0.75rem"
      border="1px solid;"
      borderColor={theme.palette.grey[200]}
      padding="1.5rem"
      onClick={onClickJob}
    >
      <Box fontSize="0.875rem" lineHeight="1.25rem" mb="0.5rem">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <FormattedText
              props={{
                value: job.name,
                format: Format.uppercaseFirstLetter,
                ellipsis: false,
              }}
              style={{
                fontSize: "1rem",
                lineHeight: "1.5rem",
                fontWeight: 600,
                pr: "0.4rem",
                display: "inline",
              }}
            />
            {job.requisition_id && (
              <Typography
                display="inline"
                fontWeight={400}
                color={theme.palette.grey[600]}
              >
                ({job.requisition_id})
              </Typography>
            )}
          </Stack>
          {selectable && (
            <Checkbox
              id="job-details-checkbox"
              circleShaped
              checked={
                selectable.isChecked
                  ? CheckedState.CHECKED
                  : CheckedState.UNCHECKED
              }
              disabled={selectable.isDisabled}
              onCheck={onClickJob}
              checkedBgColor={theme.palette.primary.main}
              checkedIconSrc={whiteCheckIcon}
            />
          )}
        </Stack>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Stack gap="0.5rem">
          <SubtitleWithIcon
            id="job-details-box-departments"
            subtitle={
              job.departments.length > 0 ? job.departments[0] : t("unknown")
            }
            icon={briefCaseIcon}
            iconAlt={t("iconAlt")}
          />
          <SubtitleWithIcon
            id="job-details-box-locations"
            subtitle={
              job.locations.length > 0
                ? job.locations
                    .map((location) => Format.location(location))
                    .join(" • ")
                : t("unknown")
            }
            icon={locationIcon}
            iconAlt={t("iconAlt")}
          />
          {(userConfig == null ||
            !userConfig.isAtsScraperIntegrationOnly()) && (
            <SubtitleWithIcon
              id="job-details-box-recruiters"
              subtitle={
                job.recruiters.length > 0
                  ? job.recruiters
                      .map(
                        (recruiter) =>
                          `${Format.capitalize(
                            recruiter.first_name,
                          )} ${Format.capitalize(recruiter.last_name)}`,
                      )
                      .join(" • ")
                  : t("unknown")
              }
              icon={userIcon}
              iconAlt={t("iconAlt")}
            />
          )}
          {job.date_posted && (
            <SubtitleWithIcon
              subtitle={`${
                job.date_posted
                  ? Format.dateDayMonthYear(job.date_posted)
                  : null
              } [${job.days_open} ${t("daysAgo")}]`}
              icon={calendarIcon}
              iconAlt={t("iconAlt")}
            />
          )}
        </Stack>
        {onDelete && (
          <IconButton
            className="icon-delete-button"
            disableRipple
            sx={{
              p: "0.4rem",
              borderRadius: "0.5rem",
              "&:hover": {
                backgroundColor: theme.palette.grey[100],
              },
            }}
            onClick={onDelete}
          >
            <Box component="img" src={deleteIcon} />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
}
