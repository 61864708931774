import { ReactNode, useState } from "react";
import {
  UserConfigContext,
  FlagsContext,
  UserContext,
} from "../../lib/context";
import { AuthUser } from "../../models/auth";
import { loadUserFromStorage } from "../../lib/utils";
import { UserConfigDTO } from "../../models/config";

interface ContextProviderProps {
  children: ReactNode;
}

export default function ContextProvider(props: ContextProviderProps) {
  const userContext = useState<AuthUser | null>(loadUserFromStorage());
  const userConfigContext = useState<UserConfigDTO | null>(null);
  const flagsContext = useState<string[] | null>(null);

  return (
    <UserContext.Provider value={userContext}>
      <UserConfigContext.Provider value={userConfigContext}>
        <FlagsContext.Provider value={flagsContext}>
          {props.children}
        </FlagsContext.Provider>
      </UserConfigContext.Provider>
    </UserContext.Provider>
  );
}
