import {cssTransition, ToastContainer, ToastContainerProps} from "react-toastify";
import theme from "../../theme";
import alertCircleIcon from "/alert-circle.svg";
import {Translation} from "../../lib/constants.ts";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/CloseRounded";

const toastTransition = cssTransition({
    enter: "boom_custom_toast_fade_in",
    exit: "boom_custom_toast_fade_out",
});

interface ErrorToastContainerProps extends ToastContainerProps {
}

// @ts-ignore
const CloseButton = ({ closeToast }) => (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CloseIcon onClick={closeToast} />
    </div>
);

export default function ErrorToastContainer(props: ErrorToastContainerProps) {
    const { t } = useTranslation(Translation.alumProfile);
    return (
        <ToastContainer
            containerId={props.containerId}
            position={props.position || "top-center"}
            style={props.style || {width: "fit-content"}}
            autoClose={false}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            transition={toastTransition}
            toastStyle={{
                backgroundColor: theme.palette.notification.error.backgroundColor,
                color: theme.palette.notification.error.color,
                fontWeight: "600",
                fontFamily: "Inter",
                fontSize: "14px",
                borderRadius: "4px",
                borderColor: theme.palette.notification.error.borderColor,
                border: "1px solid",
                minHeight: "52px",
                boxShadow: "none",
            }}
            icon={(
                <img
                    src={alertCircleIcon}
                    alt={t("alertIconAlt", {ns: Translation.common})}
                />
            )}
            role="alert"
            closeButton={CloseButton}
            {...props}
        />
    );
}