import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useEffect, useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { listAlumni } from "../../api/alum";
import FilterButton from "../../components/Filter/FilterButton";
import {
  FormerDepartment,
  FormerSalary,
  LastKnownLocation,
  Tenure,
  TimeSinceDeparture,
} from "../../components/Filter/FilterFields";
import FilterPanel from "../../components/Filter/FilterPanel";
import Loading from "../../components/Loading";
import Menu from "../../components/OptionsMenu";
import SearchBar from "../../components/Searchbar";
import Sort from "../../components/Sort";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer";
import { Translation } from "../../lib/constants";
import { SearchbarContextType } from "../../models/api/common";
import Button from "../../theme/Button";
import theme from "../../theme";
import AlumniDatabaseTable from "./AlumniDatabaseTable";
import {
  AlumniDbSortEnum,
  getEmploymentTypeDisplay,
} from "../../models/api/alum";
import {
  ALUMNI_DATABASE_PAGE_SIZE,
  loadAlumniDbSortFromStorage,
  MAX_SELECTED_ALUMNI,
  setAlumniDbSortInStorage,
  trackGoogleAnalyticsEvent,
} from "../../lib/utils";
import {
  AlumniDbFilterOptions,
  getAlumniDbDefaultFilters,
} from "../../models/filter";
import EllipsisText from "../../theme/EllipsisText";
import Header from "../../theme/Header";
import { FilterMulitSelectCheckbox } from "../../components/UtilComponents";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer";
import InfoToastContainer from "../../components/Toastify/InfoToastContainer";
import { useTableContext } from "../../components/TableView";
import SendEmailModal from "../../components/SendEmailModal";
import CenterModal from "../../components/CenterModal";
import { EmailTemplateTypeEnum } from "../../models/api/resource";
import { AlumDBEvent, GAEvent } from "../../lib/eventEnums";
import { UserContext } from "../../lib/context";

export type InputError = {
  former_salary: boolean;
  tenure: boolean;
  time_since_departure: boolean;
};

export default function AlumniDatabase() {
  const { t } = useTranslation(Translation.alumniDatabase);
  const common = useTranslation(Translation.common)[0];

  const navigate = useNavigate();

  const {
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    alumniFilters,
    setAlumniFilters,
    editAlumniFilters,
    setEditAlumniFilters,
    alumni,
    setAlumni,
    firstLoadTriggered,
    setFirstLoadTriggered,
    totalRows,
    setTotalRows,
    userConfig,
    selectedAlumni,
    setSelectedAlumni,
  } = useTableContext();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>(
    t(loadAlumniDbSortFromStorage()),
  );
  const [sendEmailModalOpen, setSendEmailModalOpen] =
    useState<EmailTemplateTypeEnum | null>(null);

  const prevValues = useRef({
    page,
    searchTerm,
    selectedOption,
    alumniFilters,
    userConfig,
  }).current;

  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  const [inputError, setInputError] = useState<InputError>({
    former_salary: false,
    tenure: false,
    time_since_departure: false,
  });
  const updateInputError = (arg: keyof InputError) => (value: boolean) => {
    setInputError((prev) => ({
      ...prev,
      [arg]: value,
    }));
  };
  const toastContainerIds = {
    info: "alumni-database-filter-info",
    success: "alumni-database-filter-success",
    error: "alumni-database-filter-error",
  };

  const [filterOpen, setFilterOpen] = useState(false);

  const closeFilters = () => {
    setFilterOpen(false);
    setEditAlumniFilters(alumniFilters);
  };

  const applyFilters = () => {
    if (Object.values(inputError).some((value) => value)) {
      toast.error(common("filter.notifications.inputError"), {
        containerId: toastContainerIds.error,
        toastId: "filter-input-error",
      });
    } else {
      trackGoogleAnalyticsEvent({
        event: AlumDBEvent.ALUM_DB_FILTER,
        name: "filter",
        org: user?.orgName,
        customParameters: {
          editAlumniFilters,
        },
      });
      setPage(0);
      setAlumniFilters(editAlumniFilters);
      setFilterOpen(false);
    }
  };

  const sendRoleDisabled = selectedAlumni.size === 0;

  const menuOptions = [
    {
      disabled: selectedAlumni.size == 0,
      className: "request-referral-option",
      label: t("menuOptions.requestReferral.label"),
      handler: () =>
        openSendEmailModal(
          AlumDBEvent.ALUM_DB_REQUEST_REFERRAL,
          EmailTemplateTypeEnum.REFERRAL,
        ),
      hasDividerAbove: false,
    },
    {
      disabled: selectedAlumni.size == 0,
      className: "send-message-option",
      label: t("menuOptions.sendMessage.label"),
      handler: () =>
        openSendEmailModal(
          AlumDBEvent.ALUM_DB_SEND_MESSAGE,
          EmailTemplateTypeEnum.SEND_MESSAGE,
        ),
      hasDividerAbove: false,
    },
  ];

  const sortOptions = [
    t("sortOption.departureDateNewest"),
    t("sortOption.departureDateOldest"),
    t("sortOption.formerSalaryHighest"),
    t("sortOption.formerSalaryLowest"),
    t("sortOption.nameA-Z"),
    t("sortOption.nameZ-A"),
  ];

  const getSortOptionsDesc = (selectedOption: string) => {
    setAlumniDbSortInStorage(selectedOption);
    switch (selectedOption) {
      case t("sortOption.departureDateNewest"):
        return {
          sortOption: AlumniDbSortEnum.DEPARTURE_DATE,
          sortDesc: true,
        };
      case t("sortOption.departureDateOldest"):
        return {
          sortOption: AlumniDbSortEnum.DEPARTURE_DATE,
          sortDesc: false,
        };
      case t("sortOption.formerSalaryHighest"):
        return {
          sortOption: AlumniDbSortEnum.FORMER_SALARY,
          sortDesc: true,
        };
      case t("sortOption.formerSalaryLowest"):
        return {
          sortOption: AlumniDbSortEnum.FORMER_SALARY,
          sortDesc: false,
        };
      case t("sortOption.nameA-Z"):
        return {
          sortOption: AlumniDbSortEnum.NAME,
          sortDesc: false,
        };
      case t("sortOption.nameZ-A"):
        return {
          sortOption: AlumniDbSortEnum.NAME,
          sortDesc: true,
        };
      default:
        return {
          sortOption: AlumniDbSortEnum.DEPARTURE_DATE,
          sortDesc: true,
        };
    }
  };

  const handleSortChange = (option: string) => {
    setSelectedOption(option);
    setPage(0);
  };

  useEffect(() => {
    let hasUserConfigChanged = prevValues?.userConfig !== userConfig;
    let notFirstLoadAndNothingChanged =
      prevValues?.page === page &&
      prevValues?.searchTerm === searchTerm &&
      prevValues?.selectedOption === selectedOption &&
      prevValues?.alumniFilters === alumniFilters &&
      !hasUserConfigChanged &&
      firstLoadTriggered;
    if (notFirstLoadAndNothingChanged) return;

    if (!firstLoadTriggered || hasUserConfigChanged) {
      prevValues.userConfig = userConfig;

      setAlumniFilters(
        getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
      );
      setEditAlumniFilters(
        getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
      );
    }

    if (!firstLoadTriggered || !hasUserConfigChanged) {
      fetchAlumni();

      prevValues.page = page;
      prevValues.searchTerm = searchTerm;
      prevValues.selectedOption = selectedOption;
      prevValues.alumniFilters = alumniFilters;
    }

    if (!firstLoadTriggered) {
      setFirstLoadTriggered(true);
    }
  }, [page, selectedOption, searchTerm, alumniFilters, userConfig]);

  const fetchAlumni = async () => {
    setIsLoading(true);
    const { sortDesc, sortOption } = getSortOptionsDesc(selectedOption);
    try {
      const response = await listAlumni(
        orgId,
        page,
        ALUMNI_DATABASE_PAGE_SIZE,
        sortOption,
        sortDesc,
        searchTerm,
        alumniFilters,
      );
      setAlumni(response.alumni);
      setIsLoading(false);
      setTotalRows(response.total);
    } catch {
      // TODO: BOO-390 handle API exceptions
      toast.error(
        <>
          <span>{t("error.loadingTable.message")}</span>
          <div
            onClick={() => navigate(0)}
            style={{ marginTop: "5px", cursor: "pointer" }}
          >
            {t("error.loadingTable.buttonText")}
          </div>
        </>,
        {
          containerId: toastContainerIds.error,
        },
      );
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSearch = (searchTermValue: string) => {
    trackGoogleAnalyticsEvent({
      event: AlumDBEvent.ALUM_DB_SEARCH_QUERY,
      org: user?.orgName,
      type: searchTermValue,
    });
    setPage(0);
    setSearchTerm(searchTermValue);
  };

  const employmentTypeFilterLabel = userConfig?.org.alumEmploymentTypes
    ? new Map(
        userConfig?.org.alumEmploymentTypes.map((key) => [
          key,
          getEmploymentTypeDisplay(key, common),
        ]),
      )
    : new Map();

  const handleSendEmail = () => {
    setSendEmailModalOpen(null);
    toast.success(t("emailSuccessToast"), {
      containerId: toastContainerIds.success,
    });
  };

  const openSendEmailModal = (
    event: GAEvent,
    emailType: EmailTemplateTypeEnum,
  ) => {
    trackGoogleAnalyticsEvent({
      event: event,
      org: user?.orgName,
      customParameters: {
        num_selected: selectedAlumni.size,
        percent_selected: selectedAlumni.size / totalRows,
      },
    });
    setSendEmailModalOpen(emailType);
  };

  const handleCloseSendEmailModal = () => {
    setSendEmailModalOpen(null);
  };

  const trackSortAnalytics = (option: string) => {
    trackGoogleAnalyticsEvent({
      event: AlumDBEvent.ALUM_DB_SORT,
      org: user?.orgName,
      type: option,
    });
  };

  return (
    <Stack padding="2rem">
      <ErrorToastContainer containerId={toastContainerIds.error} />
      <SuccessToastContainer containerId={toastContainerIds.success} />
      <InfoToastContainer containerId={toastContainerIds.info} />
      <Header title={t("title")} />
      <Stack justifyContent="space-between" direction="row">
        <Stack
          direction="row"
          margin="2rem 0 1rem"
          padding="1rem 1rem 1rem 0rem"
          gap="0.75rem"
        >
          <SearchBar
            placeholder={t("searchPlaceholder")}
            onSearch={handleSearch}
            context={SearchbarContextType.ALUMNI_DATABASE}
            searchbarWidth="21.125rem"
            searchTerm={searchTerm}
            disabled={
              getNumFiltersApplied(
                alumniFilters,
                getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
              ) > 0
            }
          />
          <FilterButton
            isDisabled={isLoading || searchTerm.length > 0}
            onClick={() => setFilterOpen(true)}
            count={getNumFiltersApplied(
              alumniFilters,
              getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
            )}
          />
          <Sort
            options={sortOptions}
            selectedOption={selectedOption}
            dropdownWidth={"16.25rem"}
            onSortChange={handleSortChange}
            trackAnalyticsEvent={trackSortAnalytics}
          />
        </Stack>
        <Stack
          direction="row"
          margin="2rem 0 1rem"
          padding="1rem 1rem 1rem 0rem"
          gap="0.625rem"
        >
          {selectedAlumni.size > 0 && (
            <EllipsisText
              className="selected-count"
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.25rem"
              color={(theme) => theme.palette.grey[500]}
              style={{ alignSelf: "center" }}
            >
              {selectedAlumni.size}/{MAX_SELECTED_ALUMNI} {t("selected")}
            </EllipsisText>
          )}
          <Box
            sx={{
              border: `1px solid ${theme.palette.grey[200]}`,
              borderRadius: "0.5rem",
            }}
          >
            <Menu
              className="alumni-database-options-menu"
              items={menuOptions}
            />
          </Box>
          <Button
            buttonProps={{
              disableRipple: true,
              sx: {
                padding: "0.625rem 1rem",
                fontFamily: "inherit",
                fontSize: "0.875rem",
                fontWeight: 600,
                lineHeight: "1.25rem",
                cursor: "pointer",
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: "0.5rem",
              },
              disabled: sendRoleDisabled,
              onClick: () =>
                openSendEmailModal(
                  AlumDBEvent.ALUM_DB_SEND_ROLE,
                  EmailTemplateTypeEnum.SEND_ROLE,
                ),
            }}
          >
            {t("sendRole")}
          </Button>
        </Stack>
      </Stack>
      {isLoading ? (
        <Loading />
      ) : (
        <AlumniDatabaseTable
          alumni={alumni}
          page={page}
          pageSize={ALUMNI_DATABASE_PAGE_SIZE}
          totalRows={totalRows}
          onPageChange={handlePageChange}
          selectedAlumni={selectedAlumni}
          setSelectedAlumni={setSelectedAlumni}
        />
      )}

      <FilterPanel
        description={t("filter.subtitle")}
        isOpen={filterOpen}
        closePanel={closeFilters}
        submitEnabled={true}
        onSubmit={applyFilters}
        onClear={() =>
          setEditAlumniFilters(
            getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
          )
        }
      >
        <Stack direction="column" gap="1.5rem">
          <LastKnownLocation
            initialSelectedLocations={Array.from(
              editAlumniFilters.last_known_locations,
            ).map((location) => {
              return { label: location, value: location, selectable: true };
            })}
            selectedLocations={editAlumniFilters.last_known_locations}
            setLocations={(arg: Set<string>) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                last_known_locations: arg,
              })
            }
          />
          <FormerDepartment
            initialSelectedDepartments={Array.from(
              editAlumniFilters.former_departments,
            ).map((department) => {
              return { label: department, value: department, selectable: true };
            })}
            selectedDepartments={editAlumniFilters.former_departments}
            setDepartments={(arg: Set<string>) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                former_departments: arg,
              })
            }
          />
          {editAlumniFilters.former_employment_types.size > 0 && (
            <FilterMulitSelectCheckbox
              label={common("filter.fields.labels.formerEmploymentType")}
              optionStatus={editAlumniFilters.former_employment_types}
              optionLabels={employmentTypeFilterLabel}
              setFilters={(employmentType: string, value: boolean) => {
                setEditAlumniFilters({
                  ...editAlumniFilters,
                  former_employment_types: new Map(
                    editAlumniFilters.former_employment_types,
                  ).set(employmentType, value),
                });
              }}
            />
          )}
          <FormerSalary
            minSalary={editAlumniFilters.former_salary.min}
            maxSalary={editAlumniFilters.former_salary.max}
            setMinSalary={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                former_salary: {
                  min: arg,
                  max: editAlumniFilters.former_salary.max,
                },
              })
            }
            setMaxSalary={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                former_salary: {
                  max: arg,
                  min: editAlumniFilters.former_salary.min,
                },
              })
            }
            setInputError={updateInputError("former_salary")}
          />
          <Tenure
            minTenure={editAlumniFilters.tenure.min}
            maxTenure={editAlumniFilters.tenure.max}
            setMinTenure={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                tenure: {
                  min: arg,
                  max: editAlumniFilters.tenure.max,
                },
              })
            }
            setMaxTenure={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                tenure: {
                  max: arg,
                  min: editAlumniFilters.tenure.min,
                },
              })
            }
            setInputError={updateInputError("tenure")}
          />
          <TimeSinceDeparture
            timeSince={editAlumniFilters.time_since_departure}
            setTimeSince={(arg: { years: number; months: number }) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                time_since_departure: arg,
              })
            }
            setInputError={updateInputError("time_since_departure")}
          />
        </Stack>
      </FilterPanel>
      <CenterModal open={sendEmailModalOpen != null}>
        <SendEmailModal
          searchAllAlumni
          onSubmit={handleSendEmail}
          onClose={handleCloseSendEmailModal}
          initialJob={null}
          emailType={sendEmailModalOpen as EmailTemplateTypeEnum}
          receivers={[...selectedAlumni.values()]}
          maxSelectedReceivers={MAX_SELECTED_ALUMNI}
        />
      </CenterModal>
    </Stack>
  );
}

const getNumFiltersApplied = (
  filters: AlumniDbFilterOptions,
  defaultFilters: AlumniDbFilterOptions,
): number => {
  let count = 0;

  Object.keys(filters).forEach((key) => {
    switch (key as keyof AlumniDbFilterOptions) {
      case "last_known_locations":
        count += filters.last_known_locations.size;
        break;
      case "former_departments":
        count += filters.former_departments.size;
        break;
      case "former_employment_types":
        filters.former_employment_types.forEach((isChecked) => {
          if (isChecked) count++;
        });
        break;
      case "former_salary":
        const salary = filters.former_salary;
        const defaultSalary = defaultFilters.former_salary;
        if (
          salary.min !== defaultSalary.min ||
          salary.max !== defaultSalary.max
        ) {
          count++;
        }
        break;
      case "tenure":
        const tenure = filters.tenure;
        const defaultTenure = defaultFilters.tenure;
        if (
          tenure.min !== defaultTenure.min ||
          tenure.max !== defaultTenure.max
        ) {
          count++;
        }
        break;
      case "time_since_departure":
        if (
          filters.time_since_departure.years !==
            defaultFilters.time_since_departure.years ||
          filters.time_since_departure.months !==
            defaultFilters.time_since_departure.months
        ) {
          count++;
        }
        break;
    }
  });

  return count;
};
