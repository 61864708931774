import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UserIcon from "/user.svg";
import LocationMarkerIcon from "/location-marker.svg";
import { JobDashboardDTO } from "../../models/api/job";
import { JobStatusEnum } from "../../models/filter";
import { capitalize, Format, trackGoogleAnalyticsEvent } from "../../lib/utils";
import Tooltip from "../../theme/Tooltip";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Table, { TableColumn } from "../../components/Table";
import { Translation } from "../../lib/constants";
import { useContext } from "react";
import { UserConfigContext, UserContext } from "../../lib/context";
import { RolesDBEvent } from "../../lib/eventEnums";
import { FormattedText, FormattedTextType } from "../../theme/FormattedText";
import theme from "../../theme";

interface RolesDatabaseTableProps {
  jobs: JobDashboardDTO[];
  page: number;
  pageSize: number;
  totalRows: number;
  onPageChange: (page: number) => void;
}

export default function RolesDatabaseTable(props: RolesDatabaseTableProps) {
  const { t, i18n } = useTranslation(Translation.rolesDatabase);
  const { jobs, page, pageSize, totalRows, onPageChange } = props;
  const [user, _setUser] = useContext(UserContext);
  const [userConfig, _setUserConfig] = useContext(UserConfigContext);
  const navigate = useNavigate();
  const isAutomationEnabled =
    userConfig?.hasAutomatedJobMatchingEnabled() ?? false;
  const columnWidth = "13%";
  const columns: TableColumn<JobDashboardDTO>[] = [
    {
      id: "role",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.role.label")}
        </Typography>
      ),
      content: (job: JobDashboardDTO) => (
        <Box
          sx={{
            padding: "0.5rem 0rem",
            cursor: "pointer",
          }}
          onClick={() => {
            trackGoogleAnalyticsEvent({
              event: RolesDBEvent.ROLES_DB_ROLE_CLICK,
              name: job.name,
              org: user?.orgName,
              customParameters: {
                status: job.status,
                num_matches: job.num_matches,
                num_app: job.num_applications,
                location: job.locations,
                date: new Date(job.date_posted ?? "").toLocaleDateString(
                  i18n.language,
                  {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  },
                ),
              },
            });
            navigate(job.id);
          }}
        >
          <FormattedText
            props={{
              value: job.name,
              format: Format.uppercaseFirstLetter,
            }}
          />
          {job.departments.length > 0 && (
            <FormattedText
              props={{
                value: job.departments[0],
                format: Format.uppercaseFirstLetter,
                ellipsis: false,
                type: FormattedTextType.SECONDARY,
              }}
            />
          )}
          <FormattedText
            props={{
              value: job.requisition_id,
              type: FormattedTextType.SECONDARY,
            }}
          />
        </Box>
      ),
      styles: {
        width: "20%",
      },
    },
    {
      id: "matches",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.matches.label")}
        </Typography>
      ),
      content: (job: JobDashboardDTO) => {
        const numMatches = job.num_matches;
        const numApplicants = job.num_applications;
        return (
          <Box>
            <FormattedText
              props={{
                value: numMatches,
              }}
              style={{
                color: (theme) => {
                  if (numMatches > 0) {
                    return theme.palette.grey[800];
                  } else {
                    return theme.palette.grey[400];
                  }
                },
              }}
            />
            <FormattedText
              props={{
                value: t("table.matches.numApplicants", {
                  count: numApplicants,
                }),
              }}
              style={{
                color: (theme) => {
                  if (numApplicants > 0) {
                    return theme.palette.grey[500];
                  } else {
                    return theme.palette.grey[400];
                  }
                },
              }}
            />
          </Box>
        );
      },
      styles: {
        width: columnWidth,
      },
    },
    {
      id: "criteria",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.criteria.label")}
        </Typography>
      ),
      content: (job: JobDashboardDTO) => {
        return (
          <Stack direction="column" gap="0.5rem" alignItems="start">
            <FormattedText
              props={{
                value: job.criteria_name,
                format: Format.uppercaseFirstLetter,
                ellipsis: false,
              }}
            />
            {isAutomationEnabled && (
              <Typography
                width="max-content"
                padding="0.125rem 0.5rem"
                borderRadius="0.375rem"
                border="1px solid"
                fontSize="0.875rem"
                fontWeight={400}
                lineHeight="1.25rem"
                borderColor={
                  job.is_criteria_automated
                    ? theme.palette.custom.automatedBorder
                    : theme.palette.custom.manualBorder
                }
                color={
                  job.is_criteria_automated
                    ? theme.palette.custom.automatedColor
                    : theme.palette.custom.manualColor
                }
                sx={{
                  backgroundColor: job.is_criteria_automated
                    ? theme.palette.custom.automatedBackground
                    : theme.palette.custom.manualBackground,
                }}
              >
                {job.is_criteria_automated
                  ? t("criteriaSection.automated")
                  : t("criteriaSection.manual")}
              </Typography>
            )}
          </Stack>
        );
      },
      styles: {
        width: columnWidth,
      },
    },
    {
      id: "location",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.location.label")}
        </Typography>
      ),
      content: (job: JobDashboardDTO) => {
        if (job.locations.length === 0) return <></>;
        const numLocations = job.locations.length;
        return (
          <Stack direction="row" gap="0.5rem" alignItems="center">
            <FormattedText
              props={{
                value: job.locations[0],
                format: Format.location,
                ellipsis: false,
              }}
            />
            {numLocations > 1 && (
              <Tooltip
                title={
                  <>
                    {job.locations.slice(1).map((location, idx) => (
                      <Stack
                        key={idx}
                        direction="row"
                        gap="0.25rem"
                        alignItems="center"
                      >
                        <Box component="img" src={LocationMarkerIcon} />
                        <FormattedText
                          props={{
                            value: location,
                            format: Format.location,
                            ellipsis: false,
                          }}
                          style={{
                            fontSize: "0.75rem",
                            lineHeight: "1.125rem",
                            color: "inherit",
                          }}
                        />
                      </Stack>
                    ))}
                  </>
                }
              >
                <Typography
                  fontSize="0.75rem"
                  fontWeight={500}
                  lineHeight="1.125rem"
                  padding="2px 6px"
                  borderRadius="6px"
                  sx={(theme) => ({
                    color: theme.palette.grey[600],
                    background: theme.palette.grey[50],
                    cursor: "default",
                  })}
                >
                  +{numLocations - 1}
                </Typography>
              </Tooltip>
            )}
          </Stack>
        );
      },
      styles: {
        width: columnWidth,
      },
    },
    ...(userConfig == null || !userConfig.isAtsScraperIntegrationOnly()
      ? [
          {
            id: "recruiter",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.recruiter.label")}
              </Typography>
            ),
            content: (job: JobDashboardDTO) => {
              if (job.recruiters.length === 0) return <></>;
              const numRecruiters = job.recruiters.length;
              return (
                <Stack direction="row" gap="0.5rem" alignItems="center">
                  <FormattedText
                    props={{
                      value: `${job.recruiters[0].first_name} ${job.recruiters[0].last_name}`,
                      format: Format.uppercaseFirstLetter,
                    }}
                  />
                  {numRecruiters > 1 && (
                    <Tooltip
                      title={
                        <>
                          {job.recruiters.slice(1).map((recruiter, idx) => (
                            <Stack
                              key={idx}
                              direction="row"
                              gap="0.25rem"
                              alignItems="center"
                            >
                              <Box component="img" src={UserIcon} />
                              <FormattedText
                                props={{
                                  value: `${recruiter.first_name} ${recruiter.last_name}`,
                                  format: Format.uppercaseFirstLetter,
                                }}
                                style={{
                                  fontSize: "0.75rem",
                                  lineHeight: "1.125rem",
                                  color: "inherit",
                                }}
                              />
                            </Stack>
                          ))}
                        </>
                      }
                    >
                      <Typography
                        fontSize="0.75rem"
                        fontWeight={500}
                        lineHeight="1.125rem"
                        padding="2px 6px"
                        borderRadius="6px"
                        sx={(theme) => ({
                          color: theme.palette.grey[600],
                          background: theme.palette.grey[50],
                          cursor: "default",
                        })}
                      >
                        +{numRecruiters - 1}
                      </Typography>
                    </Tooltip>
                  )}
                </Stack>
              );
            },
            styles: {
              width: columnWidth,
            },
          },
        ]
      : []),
    {
      id: "date",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.date.label")}
        </Typography>
      ),
      content: (job: JobDashboardDTO) => {
        if (job.date_posted == null) return <></>;
        return (
          <FormattedText
            props={{
              value: job.date_posted,
              format: Format.dateDayMonthYear,
              ellipsis: false,
            }}
          />
        );
      },
      styles: {
        width: columnWidth,
      },
    },
    {
      id: "status",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.status.label")}
        </Typography>
      ),
      content: (job: JobDashboardDTO) => (
        <Stack gap="0.25rem">
          <FormattedText
            props={{
              value: job.status,
              format: (value: string) =>
                value === JobStatusEnum.OPEN
                  ? t("table.status.open")
                  : value === JobStatusEnum.CLOSED
                  ? t("table.status.closed")
                  : capitalize(job.status),
            }}
            style={{
              color: (theme) => {
                if (job.status === JobStatusEnum.CLOSED) {
                  return theme.palette.grey[400];
                } else {
                  return theme.palette.grey[800];
                }
              },
              paddingInline: "0.5rem",
            }}
          />
          {job.status === JobStatusEnum.CLOSED && job.boomerang_hire && (
            <Typography
              width="max-content"
              fontSize="0.75rem"
              lineHeight="1.125rem"
              padding="2px 6px"
              borderRadius="6px"
              sx={(theme) => ({
                color: theme.palette.primary.main,
                background: theme.palette.primary.light,
                border: `1px solid ${theme.palette.custom.purpleBorder}`,
              })}
            >
              {t("table.status.boomerangHire")}
            </Typography>
          )}
          {job.status === JobStatusEnum.CLOSED && !job.boomerang_hire && (
            <Typography
              width="max-content"
              fontSize="0.75rem"
              lineHeight="1.125rem"
              padding="2px 6px"
              borderRadius="6px"
              sx={(theme) => ({
                color: theme.palette.grey[600],
                background: theme.palette.grey[50],
                border: `1px solid ${theme.palette.grey[200]}`,
              })}
            >
              {t("table.status.externalHire")}
            </Typography>
          )}
        </Stack>
      ),
      styles: {
        width: columnWidth,
      },
    },
  ];

  return (
    <Table
      columns={columns}
      rows={jobs}
      page={page}
      pageSize={pageSize}
      totalRows={totalRows}
      onPageChange={onPageChange}
      minWidth="65rem"
      categoryPlural={t("paginationFooter.roles")}
      categorySingular={t("paginationFooter.role")}
      emptyResultText={t("emptyResult")}
    />
  );
}
