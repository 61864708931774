import { SERVER_URL } from "../env";
import {
  loadCsrfTokenFromStorage,
  removeUserFromStorage,
  trackGoogleAnalyticsEvent,
} from "../lib/utils";
import axios, { AxiosResponse } from "axios";
import { ResponseStatus } from "../models/api/http";
import { FailureEvent } from "../lib/eventEnums";

export const http = {
  get: async function <T>(
    path: string,
    redirectOn401: Boolean = true,
  ): Promise<AxiosResponse<T>> {
    const response = await axios.get(SERVER_URL + path, {
      withCredentials: true,
      validateStatus: (_status) => true,
    });

    // 401 Unauthorized, redirect to login page
    if (redirectOn401 && response.status === 401) {
      removeUserFromStorage();
      window.location.assign("/login");
    }

    if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: path,
        type: String(response.status),
        message: response.data.message,
      });
    }

    return response;
  },
  post: async function <T>(
    path: string,
    body: object = {},
    redirectOn401: Boolean = true,
  ): Promise<AxiosResponse<T>> {
    const response = await axios.post(SERVER_URL + path, body, {
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": loadCsrfTokenFromStorage(),
        "Content-Type": "application/json",
      },
      validateStatus: (_status) => true,
    });

    // 401 Unauthorized, redirect to login page
    if (redirectOn401 && response.status === 401) {
      removeUserFromStorage();
      window.location.assign("/login");
    }

    if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: path,
        type: String(response.status),
        message: response.data.message,
      });
    }

    return response;
  },
  patch: async function <T>(
    path: string,
    body: object = {},
    redirectOn401: Boolean = true,
  ): Promise<AxiosResponse<T>> {
    const response = await axios.patch(SERVER_URL + path, body, {
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": loadCsrfTokenFromStorage(),
        "Content-Type": "application/json",
      },
    });

    // 401 Unauthorized, redirect to login page
    if (redirectOn401 && response.status === 401) {
      removeUserFromStorage();
      window.location.assign("/login");
    }

    return response;
  },
  delete: async function <T>(
    path: string,
    body: object = {},
    redirectOn401: Boolean = true,
  ): Promise<AxiosResponse<T>> {
    const response = await axios.delete(SERVER_URL + path, {
      data: body,
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": loadCsrfTokenFromStorage(),
        "Content-Type": "application/json",
      },
    });

    // 401 Unauthorized, redirect to login page
    if (redirectOn401 && response.status === 401) {
      removeUserFromStorage();
      window.location.assign("/login");
    }

    if (response.data.status === ResponseStatus.ERROR) {
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: path,
        type: String(response.status),
        message: response.data.message,
      });
    }

    return response;
  },
};
