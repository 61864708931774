export type HttpResponse<T> = {
  status: ResponseStatus;
  data: T | null;
  message: string | null;
};

export enum ResponseStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export class HttpError extends Error {
  constructor(
    public status: number,
    public message: string = "",
  ) {
    super();
  }
}
