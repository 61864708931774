// components/SvgButton/SvgButton.tsx
import { Box } from "@mui/material";
import theme from "./index.ts";

export enum SvgButtonSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large"
}

interface SvgButtonProps {
  id?: string;
  src: string;
  onClick?: () => void;
  isDisabled?: boolean;
  size?: SvgButtonSize;
  alt?: string;
}

const sizes = {
  [SvgButtonSize.SMALL]: {
    imgSize: "1rem",
  },
  [SvgButtonSize.MEDIUM]: {
    imgSize: "1.25rem",
  },
  [SvgButtonSize.LARGE]: {
    imgSize: "1.5rem",
  },
};

export const SvgButton = ({
  id,
  src,
  onClick,
  isDisabled = false,
  size = SvgButtonSize.LARGE,
  alt,
}: SvgButtonProps) => {
  const sizeProps = sizes[size];

  return (
    <Box
      id={id}
      component="button"
      onClick={!isDisabled && onClick ? onClick : undefined}
      sx={{
        background: "none",
        border: "none",
        cursor: !isDisabled ? "pointer" : "default",
        display: "inline-flex",
        borderRadius: "0.5rem",
        "&:hover": {
          backgroundColor: !isDisabled ? theme.palette.grey[50] : "initial",
        },
        "&:disabled": {
          cursor: "default",
        },
      }}
      disabled={isDisabled}
    >
      <Box
        component="img"
        src={src}
        alt={alt}
        sx={{
          width: sizeProps.imgSize,
          height: sizeProps.imgSize,
          opacity: isDisabled ? 0.5 : 1,
        }}
      />
    </Box>
  );
};