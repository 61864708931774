import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../theme/Header";
import { Translation } from "../../lib/constants";
import CriteriaList from "../../components/CriteriaList";
import {
  listSnPMatchingCriteria,
  editSalesPartnershipsCriteria,
  createSalesPartnershipsCriteria,
  deleteSalesPartnershipsCriteria,
} from "../../api/criteria";
import { SalesPartnershipsCriteriaDTO } from "../../models/api/criteria";
import { Stack } from "@mui/material";
import { HttpError } from "../../models/api/http";
import SettingsInfoAlert from "../../components/SettingsInfoAlert";
import { toast } from "react-toastify";
import EditCriteria, { NotificationInfo } from "../../components/EditCriteria";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer";
import InfoToastContainer from "../../components/Toastify/InfoToastContainer";
import ConfirmActionModal from "../../components/ConfirmActionModal";
import theme from "../../theme";
import {trackGoogleAnalyticsEvent} from "../../lib/utils.ts";
import {FailureEvent} from "../../lib/eventEnums.ts";
import { UserContext } from "../../lib/context.ts";

const SNP_DISABLED_HTTP_STATUS = 409;

export default function SalesAndPartnershipMatching() {
  const { t } = useTranslation(Translation.settings);

  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  const [criteriaToEdit, setCriteriaToEdit] =
    useState<SalesPartnershipsCriteriaDTO | null>(null);
  const [criteriaToCreate, setCriteriaToCreate] =
    useState<SalesPartnershipsCriteriaDTO | null>(null);
  const [criteriaToDelete, setCriteriaToDelete] =
    useState<SalesPartnershipsCriteriaDTO | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [criteriaList, setCriteriaList] = useState<
    SalesPartnershipsCriteriaDTO[]
  >([]);
  const [isSnPEnabled, setIsSnPEnabled] = useState<Boolean>(true);

  const toastContainerIds = {
    info: "snp-matching-info",
    success: "snp-matching-success",
    error: "snp-matching-error",
  };

  const fetchCriteria = async () => {
    setIsLoading(true);
    try {
      const data = await listSnPMatchingCriteria(orgId);
      setCriteriaList(data.criteria);
    } catch (error) {
      if (
        error instanceof HttpError &&
        error.status === SNP_DISABLED_HTTP_STATUS
      ) {
        setIsSnPEnabled(false);
        console.log("sales and partnerships is disabled on the org level");
      } else {
        console.log("fetching criterias failed");
        setIsError(true);
      }
    }
    setIsLoading(false);
  };

  const updateCriteria = async (
    _: SalesPartnershipsCriteriaDTO | null,
    arg: SalesPartnershipsCriteriaDTO | null,
  ) => {
    try {
      await editSalesPartnershipsCriteria(orgId, arg!);
      fetchCriteria();
      return true;
    } catch (error) {
      console.log("update criteria failed", error);
      return false;
    }
  };

  const updateIsEnabled = async (
    arg: SalesPartnershipsCriteriaDTO,
    isChecked: boolean,
  ) => {
    updateCriteria(arg, { ...arg, is_enabled: isChecked });
  };

  const closeWindow = (notification?: NotificationInfo) => {
    setCriteriaToEdit(null);
    setCriteriaToCreate(null);
    setCriteriaToDelete(null);

    if (notification) {
      if (notification.type === "success") {
        toast.success(notification.message, {
          containerId: toastContainerIds.success,
        });
      } else if (notification.type === "info") {
        toast.info(notification.message, {
          containerId: toastContainerIds.info,
        });
      } else {
        trackGoogleAnalyticsEvent({
          event: FailureEvent.FAILURE,
          name: toastContainerIds.error,
          type: "Error Toast",
          message: notification.message,
        })
        toast.error(notification.message, {
          containerId: toastContainerIds.error,
        });
      }
    }
  };

  const onCreateNewClick = () => {
    const newCriteria: SalesPartnershipsCriteriaDTO = {
      receiver_email: "",
      interests: {
        roles: [],
        companies: [],
      },
      id: "",
      name: "",
      is_enabled: true,
      last_updated: "",
    };
    setCriteriaToCreate(newCriteria);
  };

  const createCriteria = async (
    _: SalesPartnershipsCriteriaDTO | null,
    arg: SalesPartnershipsCriteriaDTO | null,
  ) => {
    try {
      await createSalesPartnershipsCriteria(orgId, arg!);
      fetchCriteria();
      return true;
    } catch (error) {
      console.log("create criteria failed", error);
      return false;
    }
  };

  const deleteCriteria = async (arg: SalesPartnershipsCriteriaDTO | null) => {
    try {
      await deleteSalesPartnershipsCriteria(orgId, arg!);
      fetchCriteria();
      closeWindow({
        message: t("editCriteria.notifications.deletedSuccess"),
        type: "info",
      });
    } catch (error) {
      console.log("delete criteria failed", error);
      closeWindow({
        message: t("editCriteria.notifications.deletedError"),
        type: "error",
      });
    }
  };

  const duplicateCriteria = (arg: SalesPartnershipsCriteriaDTO) => {
    setCriteriaToCreate({
      ...arg,
      name: "Copy of " + arg.name,
    });
  };

  useEffect(() => {
    fetchCriteria();
  }, []);

  return (
    <Stack padding="2rem">
      <ErrorToastContainer containerId={toastContainerIds.error} />
      <SuccessToastContainer containerId={toastContainerIds.success} />
      <InfoToastContainer containerId={toastContainerIds.info} />
      <Header title={t("salesAndPartnershipMatching.title")} />
      {isSnPEnabled ? (
        <CriteriaList
          isLoading={isLoading}
          isError={isError}
          listHeader={t("salesAndPartnershipMatching.heading")}
          listSubHeader={t("salesAndPartnershipMatching.subHeading")}
          criteriaList={criteriaList}
          setCriteriaToEdit={setCriteriaToEdit}
          createNew={{
            onCreateNewClick: onCreateNewClick,
            duplicateCriteria: duplicateCriteria,
            setCriteriaToDelete: setCriteriaToDelete,
            updateIsEnabled: updateIsEnabled,
            noMatchHeading: t("salesAndPartnershipMatching.noMatchHeading"),
            noMatchSubHeading: t(
              "salesAndPartnershipMatching.noMatchSubHeading",
            ),
            buttonText: t("salesAndPartnershipMatching.buttonText"),
          }}
        />
      ) : (
        <SettingsInfoAlert
          heading={t("salesAndPartnershipMatching.inactiveAlertHeading")}
        />
      )}
      <EditCriteria
        heading={t("salesAndPartnershipMatching.editHeading")}
        criteriaToEdit={criteriaToEdit}
        setCriteriaToEdit={setCriteriaToEdit}
        closeEditWindow={closeWindow}
        onSubmit={updateCriteria}
        criteriaList={criteriaList}
        toastContainerIds={toastContainerIds}
      />
      <EditCriteria
        heading={t("salesAndPartnershipMatching.createHeading")}
        criteriaToEdit={criteriaToCreate}
        setCriteriaToEdit={setCriteriaToCreate}
        closeEditWindow={closeWindow}
        onSubmit={createCriteria}
        criteriaList={criteriaList}
        toastContainerIds={toastContainerIds}
      />
      <ConfirmActionModal
        open={criteriaToDelete !== null}
        primaryButtonHandler={() => deleteCriteria(criteriaToDelete)}
        secondaryButtonHandler={() => setCriteriaToDelete(null)}
        primaryButtonBgColor={theme.palette.error.main}
        primaryButtonHoverBgColor={theme.palette.error.dark}
        heading={t(`editCriteria.modals.delete.heading`)}
        subHeading={t(`editCriteria.modals.delete.subHeading`)}
        primaryButtonText={t(`editCriteria.modals.delete.primaryButton`)}
        secondaryButtonText={t(`editCriteria.modals.delete.secondaryButton`)}
      />
    </Stack>
  );
}
