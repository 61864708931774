import {
  Box,
  FormControlLabel,
  Grid,
  Link,
  OutlinedInput,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import Radio from "../Radio";
import { AnnotatedInput } from "../UtilComponents";
import Button from "../../theme/Button";
import MuiButton from "@mui/material/Button";
import { Translation } from "../../lib/constants";
import { useTranslation } from "react-i18next";
import CenterModal from "../CenterModal";

type EmailSettingsProps = {
  emailFrequencyStr: string;
  emailFrequencyType: string;
  isEmailFrequencyError: boolean;
  handleFrequencyTypeChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  updateFrequencyAmount: (event: React.ChangeEvent<HTMLInputElement>) => void;
  saveFrequencyChanges: () => void;
  discardModalOpen: boolean;
  discardChanges: () => void;
  copy: {
    explanation: string;
    recieverEmailDescription: string;
    emailCadenceExplanation: string;
  }
};

export default function EmailSettings(props: EmailSettingsProps) {
  const {
    emailFrequencyStr,
    emailFrequencyType,
    isEmailFrequencyError,
    handleFrequencyTypeChange,
    updateFrequencyAmount,
    saveFrequencyChanges,
    discardModalOpen,
    discardChanges,
    copy
  } = props;
  const { t } = useTranslation(Translation.settings);
  return (
    <Stack paddingTop="1.5rem" className="email-settings-container">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Typography
          fontSize="1.125rem"
          lineHeight="1.75rem"
          fontWeight={600}
          paddingBottom=".25rem"
        >
          {t("emailSettings.title")}
        </Typography>
        <Typography
          color={theme.palette.grey[500]}
          fontSize="0.875rem"
          fontWeight={400}
          lineHeight="1.25rem"
          paddingBottom="1.25rem"
        >
          {copy.explanation}
        </Typography>
      </Box>
      <Box
        paddingBottom="2rem"
        paddingTop="2rem"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Stack direction="row" gap="2rem">
          <Typography
            fontSize="0.875rem"
            fontWeight={600}
            lineHeight="1.25rem"
            width="280px"
          >
            {t("emailSettings.senderEmail")}
          </Typography>
          <OutlinedInput
            className="sender-email-input"
            value="hello@hiboomerang.com"
            disabled={true}
            sx={{
              width: "512px",
              borderRadius: "0.5rem",
              height: "40px",
              backgroundColor: theme.palette.grey[50],
              "& .MuiOutlinedInput-input": {
                padding: "10px 14px",
                fontSize: "0.875rem",
                color: theme.palette.grey[400],
                borderColor: theme.palette.grey[300],
              },
            }}
          ></OutlinedInput>
        </Stack>
      </Box>
      <Box
        paddingBottom="2rem"
        paddingTop="2rem"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Stack direction="row" gap="2rem">
          <Typography
            fontSize="0.875rem"
            fontWeight={600}
            lineHeight="1.25rem"
            width="280px"
          >
            {t("emailSettings.recieverEmail")}
          </Typography>
          <Typography
            width="512px"
            fontSize="0.875rem"
            fontWeight={400}
            lineHeight="1.25rem"
          >
            {copy.recieverEmailDescription}
            <Link
              href="tasupport@hiboomerang.com"
              underline="none"
              color={theme.palette.primaryColor[600]}
            >
              {" "}
              tasupport@hiboomerang.com.
            </Link>
          </Typography>
        </Stack>
      </Box>
      <Box
        paddingBottom="2rem"
        paddingTop="2rem"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Stack direction="row" gap="2rem">
          <Box>
            <Typography
              fontSize="0.875rem"
              fontWeight={600}
              lineHeight="1.25rem"
              width="280px"
            >
              {t("emailSettings.emailCadence")}
            </Typography>
            <Typography
              fontSize="0.875rem"
              fontWeight={400}
              color={theme.palette.grey[500]}
              lineHeight="1.25rem"
              width="280px"
            >
              {copy.emailCadenceExplanation}
            </Typography>
          </Box>
          <Box>
            <Typography
              fontSize="0.875rem"
              fontWeight={600}
              lineHeight="1.25rem"
            >
              {t("emailSettings.deliveryFrequency.title")}
            </Typography>
            <Stack direction="row">
              <RadioGroup
                row
                value={emailFrequencyType}
                onChange={handleFrequencyTypeChange}
              >
                <FormControlLabel
                  value="WEEKS"
                  control={<Radio className="weeks-selection" />}
                  label={t("emailSettings.deliveryFrequency.weekly")}
                  sx={{
                    fontSize: "0.875rem",
                    paddingRight: "2.5rem",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "0.875rem",
                      fontWeight: 400,
                      lineHeight: "1.25rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="MONTHS"
                  control={<Radio className="months-selection" />}
                  label={t("emailSettings.deliveryFrequency.monthly")}
                  sx={{
                    fontSize: "0.875rem",
                    paddingRight: "1.5rem",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "0.875rem",
                      fontWeight: 400,
                      lineHeight: "1.25rem",
                    },
                  }}
                />
              </RadioGroup>
            </Stack>
            <Stack direction="row" alignContent="center" paddingTop="1rem">
              <Typography
                fontSize="0.875rem"
                fontWeight={400}
                lineHeight="1.25rem"
                paddingRight="1rem"
                paddingTop="0.5rem"
              >
                {t("emailSettings.deliveryFrequency.repeatsText")}
              </Typography>
              <AnnotatedInput
                value={emailFrequencyStr}
                onChange={updateFrequencyAmount}
                annotation={
                  emailFrequencyType === "WEEKS"
                    ? t("emailSettings.deliveryFrequency.weeks")
                    : t("emailSettings.deliveryFrequency.months")
                }
                error={isEmailFrequencyError}
              ></AnnotatedInput>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        paddingTop="1.25rem"
      >
        <Button
          buttonProps={{
            id: "save-changes",
            onClick: () => saveFrequencyChanges(),
            sx: {
              borderRadius: "0.5rem",
              width: "5rem",
              padding: "0.675rem 1rem",
              height: "2.5rem",
              backgroundColor: theme.palette.primaryColor[600],
            },
            disableRipple: true,
          }}
        >
          {t("emailSettings.save")}
        </Button>
      </Grid>
      <CenterModal open={discardModalOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "29rem",
            padding: (theme) => theme.spacing(4),
            backgroundColor: (theme) => theme.palette.background.paper,
            borderRadius: (theme) => theme.spacing(2),
          }}
        >
          <Stack spacing={2} direction="column">
            <Typography
              fontSize="1.1rem"
              fontWeight={600}
              lineHeight="1.5rem"
              color={(theme) => theme.palette.grey[800]}
            >
              {t("emailSettings.discardEmailHeader")}
            </Typography>
            <Typography
              fontSize="1rem"
              fontWeight={400}
              lineHeight="1.2rem"
              color={(theme) => theme.palette.grey[500]}
            >
              {t("emailSettings.discardWarning")}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={1}
            >
              <Grid item>
                <MuiButton
                  disableRipple
                  variant="contained"
                  type="submit"
                  onClick={() => saveFrequencyChanges()}
                  sx={(theme) => ({
                    background: theme.palette.background.default,
                    color: theme.palette.grey[600],
                    borderRadius: "0.5rem",
                    border: 1,
                    borderColor: theme.palette.grey[300],
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      background: theme.palette.background.default,
                    },
                  })}
                >
                  {t("emailSettings.saveChanges")}
                </MuiButton>
              </Grid>
              <Grid item>
                <MuiButton
                  className="discard-changes-button"
                  disableRipple
                  variant="contained"
                  type="submit"
                  onClick={() => discardChanges()}
                  sx={{
                    background: "#D92D20",
                    borderRadius: "0.5rem",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      background: "#D92D20",
                    },
                  }}
                >
                  {t("emailSettings.discard")}
                </MuiButton>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </CenterModal>
    </Stack>
  );
}
