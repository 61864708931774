import { AlumDTO, NoteDTO } from "../../models/api/alum.ts";
import Box from "@mui/material/Box";
import { Button as MuiButton, Stack, Typography } from "@mui/material";
import EmptyList from "../EmptyList";
import noteSvg from "/note.svg";
import plusSvg from "/plus.svg";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants.ts";
import { useContext, useState } from "react";
import { createNote, deleteNote } from "../../api/note.ts";
import ConfirmActionModal from "../ConfirmActionModal";
import ErrorToastContainer from "../Toastify/ErrorToastContainer.tsx";
import { toast } from "react-toastify";
import InfoToastContainer from "../Toastify/InfoToastContainer.tsx";
import SuccessToastContainer from "../Toastify/SuccessToastContainer.tsx";
import { ShowAllNotesModal } from "./ShowAllNotesModal.tsx";
import { AddNoteModal } from "./AddNoteModal.tsx";
import { ExpandedNoteModal } from "./ExpandedNoteModal.tsx";
import { SvgButton } from "../../theme/SvgButton.tsx";
import { NoteList } from "./NoteList.tsx";
import { UserContext } from "../../lib/context.ts";

interface NotesProps {
  alum: AlumDTO;
  limitTo?: number;
}

const toastContainerIds = {
  success: "notes-success",
  error: "notes-error",
  info: "notes-info",
};

export default function Notes({ alum, limitTo }: NotesProps) {
  const [notes, setNotes] = useState(alum.notes ?? []);
  const [selectedNote, setSelectedNote] = useState<NoteDTO | null>(null);
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showAllNotesModal, setShowAllNotesModal] = useState(false);
  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);
  const [addNoteInputText, setAddNoteInputText] = useState("");
  const [user, _] = useContext(UserContext);
  const orgId = user?.orgId ?? "";
  const { t } = useTranslation(Translation.alumProfile);

  const handleSaveNote = async () => {
    try {
      const newNote = await createNote(orgId, alum.id, addNoteInputText);
      alum.notes = [newNote, ...alum.notes];
      const updatedNotes = [newNote, ...notes];
      setNotes(updatedNotes);
      alum.notes = updatedNotes;
      setShowAddNoteModal(false);
      setAddNoteInputText("");
      toast.success(t("notes.toast.saveSuccess"), {
        containerId: toastContainerIds.success,
        toastId: "save-note-success",
      });
    } catch (error) {
      console.error("Failed to create note:", error);
      toast.error(t("notes.toast.saveError"), {
        containerId: toastContainerIds.error,
        toastId: "save-note-error",
      });
    }
  };
  const handleDeleteNote = async () => {
    if (!selectedNote) return;

    try {
      await deleteNote(orgId, selectedNote.id);
      const updatedNotes = notes.filter((note) => note.id !== selectedNote.id);
      setNotes(updatedNotes);
      alum.notes = updatedNotes;
      setSelectedNote(null);
      setShowDeleteNoteModal(false);
      toast.info(t("notes.toast.deleteSuccess"), {
        containerId: toastContainerIds.info,
        toastId: "delete-note-success",
      });
    } catch (error) {
      console.error("Failed to delete note:", error);
      toast.error(t("notes.toast.deleteError"), {
        containerId: toastContainerIds.error,
        toastId: "delete-note-error",
      });
    }
  };

  const sortedNotes = [...(notes ?? [])].sort((a, b) => {
    return (
      new Date(b.created_date).getTime() - new Date(a.created_date).getTime()
    );
  });
  const previewNotes = limitTo ? sortedNotes.slice(0, limitTo) : sortedNotes;
  const showSeeAll = (limitTo !== undefined) && sortedNotes.length > limitTo;

  const handleCloseAddNote = () => {
    if (addNoteInputText.trim().length > 0) {
      setShowDiscardModal(true);
    } else {
      setShowAddNoteModal(false);
    }
  };

  const handleNoteClick = (note: NoteDTO) => {
    setSelectedNote(note);
  };

  const handleConfirmDiscard = () => {
    setShowDiscardModal(false);
    setShowAddNoteModal(false);
    setAddNoteInputText("");
    toast.info(t("notes.toast.discardedMessage"), {
      containerId: toastContainerIds.info,
      toastId: "discard-note-info",
    });
  };

  return (
    <>
      <Box id="notes">
        <SuccessToastContainer
          containerId={toastContainerIds.success}
          style={{ width: "652px" }}
        />
        <ErrorToastContainer
          containerId={toastContainerIds.error}
          style={{ width: "652px" }}
        />
        <InfoToastContainer
          containerId={toastContainerIds.info}
          style={{ width: "652px" }}
        />
        <Stack gap="1.5rem">
          <Stack direction="row" gap="1rem">
            <Typography fontWeight={600} fontSize="1rem" flexGrow={1}>
              {t("notes.title")}
            </Typography>
            <SvgButton
              id={"add-note-button"}
              src={plusSvg}
              onClick={() => setShowAddNoteModal(true)}
            />
          </Stack>
          {notes.length === 0 ? (
            <Stack gap="1rem">
              <EmptyList
                icon={noteSvg}
                iconAlt={t("notes.empty.iconAlt")}
                title={t("notes.empty.title")}
                message={t("notes.empty.subtitle")}
              />
              <MuiButton
                className="add-note-button"
                sx={{
                  padding: "0.625rem 1rem",
                  fontFamily: "inherit",
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  lineHeight: "1.25rem",
                  background: theme.palette.common.white,
                  cursor: "pointer",
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: "0.5rem",
                  color: theme.palette.grey[600],
                  "&:hover": {
                    background: theme.palette.grey[50],
                  },
                  "&:active": {
                    color: theme.palette.grey[800],
                  },
                }}
                onClick={() => setShowAddNoteModal(true)}
              >
                {t("notes.empty.addNote")}
              </MuiButton>
            </Stack>
          ) : (
            <NoteList notes={previewNotes} onNoteClick={handleNoteClick} showFinalDivider={showSeeAll} />
          )}
          {showSeeAll && (
            <Typography
              fontWeight={600}
              fontSize="0.875rem"
              color={theme.palette.primary.main}
              onClick={() => setShowAllNotesModal(true)}
              style={{ cursor: "pointer" }}
            >
              {t("notes.showAll")}
            </Typography>
          )}
        </Stack>
      </Box>

      <ShowAllNotesModal
        open={showAllNotesModal}
        onClose={() => setShowAllNotesModal(false)}
        notes={sortedNotes}
        onNoteClick={(note) => {
          handleNoteClick(note);
        }}
      />

      <AddNoteModal
        open={showAddNoteModal}
        onClose={handleCloseAddNote}
        onSave={handleSaveNote}
        value={addNoteInputText}
        onChange={setAddNoteInputText}
        characterLimit={1000}
      />

      <ExpandedNoteModal
        note={selectedNote}
        onClose={() => {
          setSelectedNote(null);
          setShowAllNotesModal(false);
        }}
        onBack={() => setSelectedNote(null)}
        onDiscard={() => setShowDeleteNoteModal(true)}
        displayBackButton={showAllNotesModal}
      />

      <ConfirmActionModal
        open={showDeleteNoteModal}
        onClose={() => setShowDeleteNoteModal(false)}
        primaryButtonHandler={() => void handleDeleteNote()}
        secondaryButtonHandler={() => setShowDeleteNoteModal(false)}
        primaryButtonBgColor={theme.palette.error.main}
        primaryButtonHoverBgColor={theme.palette.error.dark}
        heading={t("notes.deleteModal.title")}
        subHeading={t("notes.deleteModal.message")}
        primaryButtonText={t("notes.deleteModal.delete")}
        secondaryButtonText={t("notes.deleteModal.cancel")}
      />
      <ConfirmActionModal
        open={showDiscardModal}
        primaryButtonHandler={handleConfirmDiscard}
        secondaryButtonHandler={() => setShowDiscardModal(false)}
        primaryButtonBgColor={theme.palette.error.main}
        primaryButtonHoverBgColor={theme.palette.error.dark}
        heading={t("notes.discardModal.title")}
        subHeading={t("notes.discardModal.message")}
        primaryButtonText={t("notes.discardModal.discard")}
        secondaryButtonText={t("notes.discardModal.cancel")}
      />
    </>
  );
}
