import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../theme/Header";
import { Translation } from "../../lib/constants";
import CriteriaList from "../../components/CriteriaList";
import {
  editReferralMatchingCriteria,
  listReferralMatchingCriteria,
} from "../../api/criteria";
import { ReferralMatchingCriteriaDTO } from "../../models/api/criteria";
import { Box, Stack, Tabs, Tab, useTheme } from "@mui/material";
import SettingsInfoAlert from "../../components/SettingsInfoAlert";
import { HttpError } from "../../models/api/http";
import EmailSettings from "../../components/EmailSettings";
import {
  EmailSettingsType,
  getEmailFrequency,
  updateEmailFrequency,
} from "../../api/email";
import { toast } from "react-toastify";
import {isInteger, trackGoogleAnalyticsEvent} from "../../lib/utils";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer";
import InfoToastContainer from "../../components/Toastify/InfoToastContainer";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer";
import EditCriteria, { NotificationInfo } from "../../components/EditCriteria";
import {FailureEvent} from "../../lib/eventEnums.ts";
import { UserContext } from "../../lib/context.ts";

const REFERRALS_DISABLED_HTTP_STATUS = 409;

export default function ReferralMatching() {
  const theme = useTheme();
  const { t } = useTranslation(Translation.settings);

  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  const [tabValue, setTabValue] = useState(0);
  const [criteriaToEdit, setCriteriaToEdit] =
    useState<ReferralMatchingCriteriaDTO | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [criteriaList, setCriteriaList] = useState<
    ReferralMatchingCriteriaDTO[]
  >([]);
  const [isReferralEnabled, setIsReferralEnabled] = useState<Boolean>(true);

  const [emailFrequencyAmount, setEmailFrequencyAmount] = useState(0);
  const [emailFrequencyStr, setEmailFrequencyStr] = useState("0");
  const [emailFrequencyType, setEmailFrequencyType] = useState("WEEKS");
  const [isEmailFrequencyError, setIsEmailFrequencyError] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [outgoingTabValue, setOutgoingTabValue] = useState(0);

  const toastContainerIds = {
    info: "referral-matching-info",
    success: "referral-matching-success",
    error: "referral-matching-error",
  };

  const fetchEmailFrequency = async () => {
    try {
      const data = await getEmailFrequency(orgId, EmailSettingsType.Referrals);
      setEmailFrequencyAmount(data.frequency);
      setEmailFrequencyType(data.frequency_unit);
      setEmailFrequencyStr(data.frequency?.toString() ?? "0");
    } catch {
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "email-frequency-error",
        type: "Error Toast",
        message: t("emailSettings.fetchError"),
      })
      toast.error(t("emailSettings.fetchError"), {
        containerId: toastContainerIds.error,
        toastId: "email-frequency-error",
      });
    }
  };

  const saveEmailFrequency = async () => {
    setDiscardModalOpen(false);
    if (isEmailFrequencyError) {
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "email-frequency-error",
        type: "Error Toast",
        message: t("emailSettings.fetchError"),
      })
      toast.error(t("emailSettings.saveError"), {
        containerId: toastContainerIds.error,
        toastId: "email-frequency-error",
      });
      return;
    }
    try {
      await updateEmailFrequency(
        orgId,
        EmailSettingsType.Referrals,
        emailFrequencyAmount,
        emailFrequencyType,
      );
      toast.success(t("emailSettings.saveSuccess"), {
        containerId: toastContainerIds.success,
        toastId: "email-settings-success",
      });
      setHasUnsavedChanges(false);
    } catch (error) {
      console.log("update email frequency failed", error);
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "email-settings-error",
        type: "Error Toast",
        message: t("emailSettings.saveError"),
      })
      toast.error(t("emailSettings.saveError"), {
        containerId: toastContainerIds.error,
        toastId: "email-settings-error",
      });
    }
    if (outgoingTabValue !== tabValue) {
      setTabValue(outgoingTabValue);
    }
  };

  const handleFrequencyTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHasUnsavedChanges(true);
    setEmailFrequencyType((event.target as HTMLInputElement).value);
  };

  const updateEmailFrequencyAmount = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHasUnsavedChanges(true);
    let val = event.target.value;
    setEmailFrequencyStr(val);
    if (!isInteger(val)) {
      setIsEmailFrequencyError(true);
      return;
    }
    setIsEmailFrequencyError(false);
    setEmailFrequencyAmount(parseInt(val));
  };

  const discardChanges = () => {
    fetchEmailFrequency();
    setDiscardModalOpen(false);
    setHasUnsavedChanges(false);
    toast.info(t("emailSettings.discardChangesMessage"), {
      containerId: toastContainerIds.info,
      toastId: "email-settings-discard",
    });
    setTabValue(outgoingTabValue);
  };

  const fetchCriteria = async () => {
    setIsLoading(true);
    try {
      const data = await listReferralMatchingCriteria(orgId);
      setCriteriaList(data.criteria);
    } catch (error) {
      if (
        error instanceof HttpError &&
        error.status === REFERRALS_DISABLED_HTTP_STATUS
      ) {
        setIsReferralEnabled(false);
        console.log("referral is disabled on the org level");
      } else {
        console.log("fetching criterias failed");
        setIsError(true);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (tabValue === 0) {
      fetchCriteria();
    }
    if (tabValue === 1) {
      fetchEmailFrequency();
    }
  }, [tabValue]);

  const handleTabChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setOutgoingTabValue(newValue);
    if (hasUnsavedChanges) {
      setDiscardModalOpen(hasUnsavedChanges);
    } else {
      setTabValue(newValue);
    }
  };

  const updateCriteria = async (
    _: ReferralMatchingCriteriaDTO | null,
    newCriteria: ReferralMatchingCriteriaDTO | null,
  ) => {
    try {
      await editReferralMatchingCriteria(orgId, newCriteria!);
      fetchCriteria();
      return true;
    } catch (error) {
      console.log("update criteria failed", error);
      return false;
    }
  };

  const closeEditWindow = (notification?: NotificationInfo) => {
    setCriteriaToEdit(null);

    if (notification) {
      if (notification.type === "success") {
        toast.success(notification.message, {
          containerId: toastContainerIds.success,
        });
      } else if (notification.type === "info") {
        toast.info(notification.message, {
          containerId: toastContainerIds.info,
        });
      } else {
        trackGoogleAnalyticsEvent({
          event: FailureEvent.FAILURE,
          name: toastContainerIds.error,
          type: "Error Toast",
          message: notification.message,
        })
        toast.error(notification.message, {
          containerId: toastContainerIds.error,
        });
      }
    }
  };

  return (
    <Stack padding="2rem">
      <ErrorToastContainer containerId={toastContainerIds.error} />
      <SuccessToastContainer containerId={toastContainerIds.success} />
      <InfoToastContainer containerId={toastContainerIds.info} />
      <Header title={t("referralMatching.title")} showBorder={false} />
      {tabValue === 0 && !isReferralEnabled && (
        <SettingsInfoAlert
          heading={t("referralMatching.inactiveAlertHeading")}
        />
      )}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="role matching tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <Tab
            label={t("matchingCriteria")}
            disableRipple
            sx={(theme) => ({
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.grey[500],
              "&.Mui-selected": {
                color: theme.palette.grey[800],
              },
            })}
          />
          <Tab
            label={t("emailSettings.title")}
            disableRipple
            sx={(theme) => ({
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.grey[500],
              "&.Mui-selected": {
                color: theme.palette.grey[800],
              },
            })}
          />
        </Tabs>
      </Box>

      {tabValue === 0 && isReferralEnabled && (
        <CriteriaList
          isLoading={isLoading}
          isError={isError}
          listHeader={t("referralMatching.heading")}
          listSubHeader={t("referralMatching.subHeading")}
          criteriaList={criteriaList}
          setCriteriaToEdit={setCriteriaToEdit}
          toggleDisabledTooltip={t("referralMatching.toggleDisabled")}
        />
      )}
      {tabValue === 1 && (
        <EmailSettings
          emailFrequencyStr={emailFrequencyStr}
          emailFrequencyType={emailFrequencyType}
          isEmailFrequencyError={isEmailFrequencyError}
          handleFrequencyTypeChange={handleFrequencyTypeChange}
          updateFrequencyAmount={updateEmailFrequencyAmount}
          saveFrequencyChanges={saveEmailFrequency}
          discardModalOpen={discardModalOpen}
          discardChanges={discardChanges}
          copy={{
            explanation: t("referralMatching.emailSettings.explanation"),
            recieverEmailDescription: t(
              "referralMatching.emailSettings.recieverEmailDescription",
            ),
            emailCadenceExplanation: t(
              "referralMatching.emailSettings.emailCadenceExplanation",
            ),
          }}
        />
      )}

      <EditCriteria
        heading={t("referralMatching.editHeading")}
        criteriaToEdit={criteriaToEdit}
        setCriteriaToEdit={setCriteriaToEdit}
        onSubmit={updateCriteria}
        closeEditWindow={closeEditWindow}
        criteriaList={criteriaList}
        toastContainerIds={toastContainerIds}
      />
    </Stack>
  );
}
