import { Box, Stack, Typography } from "@mui/material";
import hazardIcon from "/hazard-icon.svg";
import theme from "../../theme";
import Tooltip from "../../theme/Tooltip";
import InitialsIcon from "../../theme/InitialsIcon";
import { Receiver } from "../SendEmailModal";

interface WarningBoxProps {
  alumsWithoutEmail: Receiver[];
  warningTextUnderline: string;
  warningTextRemaining: string;
}

export function WarningBox(props: WarningBoxProps) {
  const { alumsWithoutEmail, warningTextUnderline, warningTextRemaining } =
    props;
  return (
    <Box
      bgcolor={theme.palette.custom.warningBackground}
      borderRadius="0.25rem"
      border="1px solid"
      borderColor={theme.palette.custom.warningBorder}
      mb="1rem"
    >
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Box component="img" src={hazardIcon} padding="1rem"></Box>

        <Typography
          display="inline"
          fontSize="0.875rem"
          fontWeight={400}
          color={theme.palette.custom.warningText}
          lineHeight="1.25rem"
        >
          {" "}
          <Tooltip
            title={
              <>
                {alumsWithoutEmail.map((alum) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing="0.5rem"
                    bgcolor={theme.palette.grey[800]}
                  >
                    <InitialsIcon
                      firstName={alum.firstName}
                      lastName={alum.lastName}
                      size="1.5rem"
                      fontSize="0.75rem"
                      BoxProps={{
                        sx: {
                          bgcolor: theme.palette.grey[600],
                          placeItems: "center",
                        },
                      }}
                      typographyProps={{
                        color: theme.palette.common.white,
                      }}
                    />
                    <Typography fontSize="0.75rem" lineHeight="1.125rem">
                      {alum.firstName + " " + alum.lastName}
                    </Typography>
                  </Stack>
                ))}
              </>
            }
          >
            <Box sx={{ textDecoration: "underline", display: "inline" }}>
              {alumsWithoutEmail.length} {warningTextUnderline}
            </Box>
          </Tooltip>{" "}
          {warningTextRemaining}
        </Typography>
      </Stack>
    </Box>
  );
}
