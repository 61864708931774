import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import CenterModal from "../CenterModal";
import { NoteDTO } from "../../models/api/alum";
import closeIcon from "/x-close.svg";
import { NoteList } from "./NoteList.tsx";
import { SvgButton } from "../../theme/SvgButton.tsx";
import Box from "@mui/material/Box";

interface ShowAllNotesModalProps {
  open: boolean;
  onClose: () => void;
  notes: NoteDTO[];
  onNoteClick: (note: NoteDTO) => void;
}

export const ShowAllNotesModal = ({
  open,
  onClose,
  notes,
  onNoteClick,
}: ShowAllNotesModalProps) => {
  const { t } = useTranslation(Translation.alumProfile);

  return (
    <CenterModal
      open={open}
      onClose={onClose}
      sx={{
        width: "640px",
        padding: "1.5rem 0rem 3rem",
        maxHeight: "94vh",
        outline: "none",
        overflow: "auto",
      }}
    >
      <Stack id="show-all-notes-modal" gap="1.5rem">
        <Stack direction="row" px="1.5rem">
          <Typography fontWeight={600} fontSize="1.25rem" flexGrow={1}>
            {t("notes.title")}
          </Typography>
          <SvgButton src={closeIcon} onClick={onClose} />
        </Stack>
        <Divider />
        <Box px="1.5rem">
          <NoteList notes={notes} onNoteClick={onNoteClick} />
        </Box>
      </Stack>
    </CenterModal>
  );
};
