import { useState } from "react";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "../../theme/IconButton";
import theme from "../../theme";
import { Typography } from "@mui/material";
import { IconButton as MuiIconButton } from "@mui/material";

export enum ArrowPlacement {
  SIDES,
  TOP_RIGHT,
}

interface CarouselProps {
  title?: string;
  items: JSX.Element[];
  itemWidth: string;
  emptyState?: JSX.Element;
  arrowPlacement?: ArrowPlacement;
  itemsPerSlide?: number;
}

export default function Carousel({
  title,
  items,
  itemWidth,
  emptyState,
  arrowPlacement = ArrowPlacement.TOP_RIGHT,
  itemsPerSlide = 1,
}: CarouselProps) {
  const [index, setIndex] = useState(0);

  if (items.length === 0 || itemsPerSlide <= 0) {
    return (
      <>
        {title && <Typography fontWeight={600}>{title}</Typography>}
        {emptyState}
      </>
    );
  }

  const isScrollable = items.length > itemsPerSlide;
  const totalNumOfSlides = items.length - itemsPerSlide + 1;

  return (
    <Stack>
      {(title ||
        (arrowPlacement === ArrowPlacement.TOP_RIGHT && isScrollable)) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pb="1.5rem"
        >
          <Typography fontWeight={600}>{title}</Typography>
          {arrowPlacement === ArrowPlacement.TOP_RIGHT && isScrollable && (
            <Stack direction="row" spacing="0.5rem">
              <MuiIconButton
                id="top-right-arrow-placement-left-arrow"
                aria-label="arrow-back"
                onClick={() => setIndex((prevIndex) => prevIndex - 1)}
                disabled={index <= 0}
                className="icon-button"
              >
                <ArrowBackRoundedIcon />
              </MuiIconButton>
              <MuiIconButton
                id="top-right-arrow-placement-right-arrow"
                aria-label="arrow-forward"
                onClick={() => setIndex((prevIndex) => prevIndex + 1)}
                disabled={index >= totalNumOfSlides - 1}
                className="icon-button"
              >
                <ArrowForwardRoundedIcon />
              </MuiIconButton>
            </Stack>
          )}
        </Stack>
      )}
      <Stack
        direction="row"
        gap="1.5rem"
        alignItems="center"
        justifyContent="flex-start"
      >
        {isScrollable && arrowPlacement === ArrowPlacement.SIDES && (
          <IconButton
            id="side-arrow-placement-left-arrow"
            Icon={ArrowBackIosRoundedIcon}
            onClick={() => setIndex((prevIndex) => prevIndex - 1)}
            isDisabled={index <= 0}
          />
        )}
        <Box
          position="relative"
          width={`calc(${itemWidth} * ${itemsPerSlide} + ${
            itemsPerSlide - 1
          }rem)`}
          overflow="hidden"
        >
          {items.map((item, itemIndex) => (
            <Box
              key={itemIndex}
              className="carousel-item"
              width="100%"
              position="absolute"
              // prettier-ignore
              left={`calc(${itemWidth} * ${itemIndex - index} + ${itemIndex - index}rem)`}
              sx={{
                transition: "left 300ms ease-in",
              }}
            >
              {item}
            </Box>
          ))}
          <Stack direction="row" spacing="1rem" visibility="hidden">
            {Array.from({ length: itemsPerSlide }, (_, i) => i + index).map(
              (idx) => items[idx],
            )}
          </Stack>
        </Box>
        {isScrollable && arrowPlacement === ArrowPlacement.SIDES && (
          <IconButton
            id="side-arrow-placement-right-arrow"
            Icon={ArrowForwardIosRoundedIcon}
            onClick={() => setIndex((prevIndex) => prevIndex + 1)}
            isDisabled={index >= totalNumOfSlides - 1}
          />
        )}
      </Stack>
      {isScrollable && (
        <Stack
          direction="row"
          gap="0.75rem"
          width="max-content"
          margin="auto"
          visibility={isScrollable ? "visible" : "hidden"}
          pt="1.5rem"
          className="carousel-dots"
        >
          {Array(totalNumOfSlides)
            .fill(0)
            .map((_, pageIndex) => (
              <Box
                key={pageIndex}
                width="0.5rem"
                height="0.5rem"
                borderRadius="50%"
                sx={{
                  cursor: "pointer",
                  background:
                    index === pageIndex
                      ? theme.palette.grey[500]
                      : theme.palette.grey[100],
                  transition: "background 150ms ease-in",
                }}
                onClick={() => setIndex(pageIndex)}
              />
            ))}
        </Stack>
      )}
    </Stack>
  );
}
