import Box from "@mui/material/Box";
import Breadcrumbs from "../../components/Breadcrumbs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { getJobAlumniMatches } from "../../api/job";
import SearchBar from "../../components/Searchbar";
import Sort from "../../components/Sort";
import { UserContext, UserConfigContext } from "../../lib/context";
import {
  Format,
  PAGE_SIZE,
  capitalize,
  loadRoleViewAlumniMatchesSortFromStorage,
  setRoleViewAlumniMatchesSortInStorage,
  trackGoogleAnalyticsEvent,
} from "../../lib/utils";
import { AlumniMatchesSortOptionEnum } from "../../models/api/job";
import { JobStatusEnum } from "../../models/filter.ts";
import theme from "../../theme";
import Button from "../../theme/Button";
import externalLink from "/link-external-primary.svg";
import editIcon from "/edit.svg";
import RolesViewOverview from "./RolesViewOverview";
import RolesViewTable from "./RolesViewTable";
import { INITIAL_FETCH_SIZE, RouteId, Translation } from "../../lib/constants";
import { SearchbarContextType } from "../../models/api/common";
import CenterModal from "../../components/CenterModal";
import { Receiver } from "../../components/SendEmailModal";
import Loading from "../../components/Loading";
import { GAEvent, RoleViewEvent, SendMessageEvent } from "../../lib/eventEnums";
import { toast } from "react-toastify";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer.tsx";
import SendEmailModal from "../../components/SendEmailModal/index.tsx";
import { EmailTemplateTypeEnum } from "../../models/api/resource.ts";
import { RolesViewData } from "../../route.tsx";
import { useTableContext } from "../../components/TableView/index.tsx";
import {
  FormattedText,
  FormattedTextType,
} from "../../theme/FormattedText.tsx";
import Menu from "../../components/OptionsMenu";
import Tooltip from "../../theme/Tooltip.tsx";

export default function RolesView({ baseRoute }: { baseRoute: string }) {
  const { t } = useTranslation(Translation.rolesView);
  const { jobId } = useParams();

  const navigate = useNavigate();

  if (!jobId) return <></>;

  const jobData =
    baseRoute === RouteId.alumDatabase
      ? useRouteLoaderData(RouteId.alumDatabaseRolesView)
      : useRouteLoaderData(RouteId.rolesDatabaseRolesView);

  if (!jobData) return <></>;

  const { job } = jobData as RolesViewData;

  const [numAlumSelected, setNumAlumSelected] = useState(0);
  const [sendEmailModalOpen, setSendEmailModalOpen] =
    useState<EmailTemplateTypeEnum | null>(null);
  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";
  const [userConfig, _setUserConfig] = useContext(UserConfigContext);
  const [isLoading, setIsLoading] = useState(false);

  const viewJobDisabled = job?.status !== JobStatusEnum.OPEN;
  const isAutomationEnabled =
    userConfig?.hasAutomatedJobMatchingEnabled() ?? false;

  const [selectedOption, setSelectedOption] = useState<string>(
    t(loadRoleViewAlumniMatchesSortFromStorage()),
  );

  const [nextMatchingRun, setNextMatchingRun] = useState<string>(
    new Date().toString(),
  );

  const {
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    totalRows,
    setTotalRows,
    firstLoadTriggered,
    setFirstLoadTriggered,
    jobMatches,
    setJobMatches,
    selectedIds,
    setSelectedIds,
    lastJobId,
    setLastJobId,
  } = useTableContext();
  let prevValues = useRef({ selectedOption, searchTerm }).current;

  const sortOptions = [
    t("sortOption.strongestMatch"),
    t("sortOption.weakestMatch"),
    t("sortOption.newestStatusDate"),
    t("sortOption.oldestStatusDate"),
    t("sortOption.nameA-Z"),
    t("sortOption.nameZ-A"),
  ];
  const getSortOptionsDesc = (selectedOption: string) => {
    setRoleViewAlumniMatchesSortInStorage(selectedOption);
    switch (selectedOption) {
      case t("sortOption.strongestMatch"):
        return {
          sortOption: AlumniMatchesSortOptionEnum.MATCH_STRENGTH,
          sortDesc: true,
        };
      case t("sortOption.weakestMatch"):
        return {
          sortOption: AlumniMatchesSortOptionEnum.MATCH_STRENGTH,
          sortDesc: false,
        };
      case t("sortOption.newestStatusDate"):
        return {
          sortOption: AlumniMatchesSortOptionEnum.STATUS_MODIFIED_DATE,
          sortDesc: true,
        };
      case t("sortOption.oldestStatusDate"):
        return {
          sortOption: AlumniMatchesSortOptionEnum.STATUS_MODIFIED_DATE,
          sortDesc: false,
        };
      case t("sortOption.nameA-Z"):
        return {
          sortOption: AlumniMatchesSortOptionEnum.NAME,
          sortDesc: false,
        };
      case t("sortOption.nameZ-A"):
        return {
          sortOption: AlumniMatchesSortOptionEnum.NAME,
          sortDesc: true,
        };
      default:
        return {
          sortOption: AlumniMatchesSortOptionEnum.MATCH_STRENGTH,
          sortDesc: true,
        };
    }
  };

  useEffect(() => {
    const nextRun = new Date();
    if (nextRun.getHours() >= 4) {
      nextRun.setDate(nextRun.getDate() + 1);
    }
    nextRun.setHours(4);
    nextRun.setMinutes(0);
    setNextMatchingRun(nextRun.toString());
  }, []);

  const openSendEmailModal = (
    event: GAEvent,
    emailType: EmailTemplateTypeEnum,
  ) => {
    trackGoogleAnalyticsEvent({
      event: event,
      org: user?.orgName,
      customParameters: {
        num_selected: numAlumSelected,
        percent_selected: selectedIds.size / jobMatches.length,
      },
    });
    setSendEmailModalOpen(emailType);
  };

  const handleCloseSendRoleModal = () => {
    setSendEmailModalOpen(null);
  };

  const handleSendEmail = () => {
    setSendEmailModalOpen(null);
    toast.success(t("sendRole.successToast"), {
      containerId: "roles-view",
      toastId: "send-role",
    });
  };

  // If this is a different job compared to the last time we visited roles view, we want to treat
  // this like a new route and reset the page number and search term.
  useEffect(() => {
    resetOnNewJobIds(jobId);
  }, [jobId]);

  // Fetch all the job matches at once (paginate on FE), re-runs whenever jobId or sortOption changes
  useEffect(() => {
    if (
      prevValues.selectedOption !== selectedOption ||
      prevValues.searchTerm !== searchTerm ||
      !firstLoadTriggered
    ) {
      fetchJobMatches();
      setLastJobId(jobId);
      setFirstLoadTriggered(true);
      prevValues.selectedOption = selectedOption;
      prevValues.searchTerm = searchTerm;
    }
  }, [selectedOption, searchTerm]);

  const resetOnNewJobIds = (jobId: string) => {
    if (lastJobId !== jobId) {
      setPage(0);
      setSearchTerm("");
      setLastJobId(jobId);
    }
    return lastJobId !== jobId;
  };

  const fetchJobMatches = async () => {
    setIsLoading(true);
    try {
      const { sortDesc, sortOption } = getSortOptionsDesc(selectedOption);
      const response = await getJobAlumniMatches(
        orgId,
        jobId,
        page,
        INITIAL_FETCH_SIZE,
        sortOption,
        sortDesc,
        searchTerm,
      );
      setJobMatches(response.alumni_matches);
      setIsLoading(false);
      setTotalRows(response.total);
    } catch (error) {
      // TODO: BOO-1036
    }
  };

  if (!user || !job) return <></>;

  const handleSearch = (searchValue: string) => {
    trackGoogleAnalyticsEvent({
      event: RoleViewEvent.ROLE_VIEW_SEARCH_QUERY,
      org: user?.orgName,
      type: searchValue,
    });
    setSearchTerm(searchValue);
  };

  const handleSortChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleViewJobPostingClicked = () => {
    if (viewJobDisabled) return;

    if (job?.job_posting_urls?.length ?? 0 > 0) {
      const firstUrl = job?.job_posting_urls?.[0]?.url;
      if (firstUrl) {
        window.open(firstUrl, "_blank");
      }
    } else if (job?.career_site_url) {
      window.open(job.career_site_url, "_blank");
    }
  };

  const trackSortAnalytics = (option: string) => {
    trackGoogleAnalyticsEvent({
      event: RoleViewEvent.ROLE_VIEW_SORT,
      org: user?.orgName,
      type: option,
    });
  };

  const onRowClick = (alumId: string) => {
    if (baseRoute === RouteId.rolesDatabase) {
      navigate(`alumni/${alumId}`);
    } else {
      navigate(`/alumni-database/${alumId}`);
    }
  };

  const isSendingDisabled =
    job.status != JobStatusEnum.OPEN || numAlumSelected <= 0;

  return (
    <Stack padding="2rem">
      <SuccessToastContainer
        containerId="roles-view"
        style={{ width: "155px" }}
      />
      <Box paddingBottom="1rem">
        <Breadcrumbs />
      </Box>
      <Box
        borderTop={(theme) => `1px solid ${theme.palette.divider}`}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Stack
          direction="row"
          alignItems="flex-end"
          spacing={1}
          paddingTop="2rem"
        >
          <FormattedText
            props={{
              value: job.name,
              format: Format.uppercaseFirstLetter,
            }}
            style={{
              fontWeight: 600,
              fontSize: "1.5rem",
              lineHeight: "2rem",
            }}
          />
          {job.requisition_id && (
            <FormattedText
              props={{
                value: `(${job.requisition_id})`,
                type: FormattedTextType.SECONDARY,
              }}
            />
          )}
          <Typography
            fontSize="0.875rem"
            fontWeight={400}
            lineHeight="1.25rem"
            color={(theme) => theme.palette.grey[800]}
            align="center"
          >
            •
          </Typography>
          <FormattedText
            props={{
              value: job.status,
              format: (value: string) =>
                value === JobStatusEnum.OPEN
                  ? t("header.status.open")
                  : value === JobStatusEnum.CLOSED
                  ? t("header.status.closed")
                  : capitalize(value),
            }}
            style={{
              color:
                job.status === JobStatusEnum.OPEN
                  ? theme.palette.grey[800]
                  : theme.palette.grey[400],
            }}
          />
          {job.status === JobStatusEnum.CLOSED && job.boomerang_hire && (
            <Typography
              width="max-content"
              fontSize="0.75rem"
              lineHeight="1.125rem"
              padding="2px 6px"
              borderRadius="6px"
              sx={(theme) => ({
                color: theme.palette.primary.main,
                background: theme.palette.primary.light,
                border: `1px solid ${theme.palette.custom.purpleBorder}`,
              })}
            >
              {t("header.status.boomerangHire")}
            </Typography>
          )}
          {job.status === JobStatusEnum.CLOSED && !job?.boomerang_hire && (
            <Typography
              width="max-content"
              fontSize="0.75rem"
              lineHeight="1.125rem"
              padding="2px 6px"
              borderRadius="6px"
              sx={(theme) => ({
                color: theme.palette.grey[600],
                background: theme.palette.grey[50],
                border: `1px solid ${theme.palette.grey[200]}`,
              })}
            >
              {t("header.status.externalHire")}
            </Typography>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            component="button"
            onClick={handleViewJobPostingClicked}
            color={(theme) => theme.palette.grey[600]}
            fontWeight={600}
            fontSize="1.0rem"
            sx={{
              fontSize: "0.875rem",
              backgroundColor: "transparent",
              padding: "10px",
              borderRadius: "8px",
              cursor: viewJobDisabled ? "not-allowed" : "pointer",
              display: "inline-flex",
              alignItems: "center",
              gap: "0.75rem",
              border: "none",
              opacity: viewJobDisabled ? 0.5 : 1,
              "&:hover": {
                backgroundColor: viewJobDisabled
                  ? "transparent"
                  : theme.palette.grey[50],
              },
            }}
          >
            {t("viewJobPosting")}
            <Box
              component="img"
              src={externalLink}
              width="1.25rem"
              height="1.25rem"
              sx={{
                userSelect: "none",
                verticalAlign: "top",
              }}
            />
          </Typography>
        </Stack>
        <RolesViewOverview job={job} />
      </Box>
      <div>
        <Stack
          direction="row"
          marginTop="1.5rem"
          justifyContent="space-between"
        >
          <Stack id="criteria-information" direction="row" gap="0.5rem">
            <Typography
              color={theme.palette.grey[800]}
              fontSize="1rem"
              fontWeight="500"
              lineHeight="1.5rem"
              alignSelf="center"
            >
              {job.criteria_name === ""
                ? t("criteriaSection.defaultName")
                : job.criteria_name}
            </Typography>
            {isAutomationEnabled && (
              <Typography
                sx={{
                  padding: "0.125rem 0.375rem",
                  borderRadius: "0.375rem",
                  fontSize: "0.75rem",
                  lineHeight: "1.125rem",
                  backgroundColor: job.is_criteria_automated
                    ? theme.palette.tag.automated.background
                    : theme.palette.tag.manual.background,
                  color: job.is_criteria_automated
                    ? theme.palette.tag.automated.text
                    : theme.palette.tag.manual.text,
                  border: "1px solid",
                  borderColor: job.is_criteria_automated
                    ? theme.palette.tag.automated.border
                    : theme.palette.tag.manual.border,
                  height: "1.5rem",
                  alignSelf: "center",
                }}
              >
                {job.is_criteria_automated
                  ? t("criteriaSection.automated")
                  : t("criteriaSection.manual")}
              </Typography>
            )}
            <Tooltip
              title={t("criteriaSection.editTooltip")}
              componentsProps={{
                tooltip: {
                  sx: {
                    padding: "0.5rem 0.75rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.5rem",
                    borderRadius: "0.5rem",
                    alignSelf: "stretch",
                  },
                },
              }}
            >
              <Box
                id="edit-criteria-button"
                component="img"
                src={editIcon}
                sx={{
                  cursor: "pointer",
                  width: "1.25rem",
                  height: "1.25rem",
                  alignSelf: "center",
                }}
                onClick={() =>
                  navigate("/settings/role-matching", {
                    state: {
                      criteriaId: job.criteria_id,
                    },
                  })
                }
              />
            </Tooltip>
          </Stack>
          <Stack id="matching-run-information" direction="row" gap="1rem">
            <Stack direction="row" gap="0.25rem">
              <Typography
                marginLeft="auto"
                color={theme.palette.grey[500]}
                fontSize="0.75rem"
                fontWeight="400"
                lineHeight="1.125rem"
              >
                {t("criteriaSection.lastMatchRun")}:
              </Typography>
              {job.time_of_last_matching_run ? (
                <FormattedText
                  props={{
                    value: job.time_of_last_matching_run,
                    format: Format.dateAsRelativeTime,
                    ellipsis: false,
                    type: FormattedTextType.TERTIARY,
                  }}
                />
              ) : (
                <Typography
                  color={theme.palette.grey[500]}
                  fontSize="0.75rem"
                  fontWeight="400"
                  lineHeight="1.125rem"
                >
                  {t("criteriaSection.na")}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" gap="0.25rem">
              <Typography
                color={theme.palette.grey[500]}
                fontSize="0.75rem"
                fontWeight="400"
                lineHeight="1.125rem"
              >
                {t("criteriaSection.nextMatchRun")}:
              </Typography>
              <FormattedText
                props={{
                  value: nextMatchingRun,
                  format: Format.dateAsRelativeTime,
                  ellipsis: false,
                  type: FormattedTextType.TERTIARY,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          marginTop="2rem"
          marginBottom="1.5rem"
          gap="0.75rem"
          alignItems="center"
        >
          <SearchBar
            placeholder={t("searchPlaceholder")}
            onSearch={handleSearch}
            context={SearchbarContextType.ROLES_VIEW}
            jobId={jobId}
            searchTerm={searchTerm}
          />
          <Sort
            options={Object.values(sortOptions)}
            selectedOption={selectedOption}
            onSortChange={handleSortChange}
            trackAnalyticsEvent={trackSortAnalytics}
          />
          <Box marginLeft="auto">
            <Stack direction="row" spacing={1.25} alignItems="center">
              {numAlumSelected > 0 && (
                <Typography
                  color={(theme) => theme.palette.grey[500]}
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1.25rem"
                >
                  {numAlumSelected + " " + t("sendRole.selected")}
                </Typography>
              )}
              <Box
                border={`1px solid ${theme.palette.grey[200]}`}
                borderRadius="0.5rem"
              >
                <Menu
                  className="alumni-database-options-menu"
                  items={[
                    {
                      disabled: false,
                      className: "send-message-option",
                      label: t("menuOptions.sendMessage.label"),
                      handler: () =>
                        openSendEmailModal(
                          SendMessageEvent.SEND_MESSAGE_WINDOW,
                          EmailTemplateTypeEnum.SEND_MESSAGE,
                        ),
                      hasDividerAbove: false,
                    },
                  ]}
                  disabled={isSendingDisabled}
                />
              </Box>
              <Button
                buttonProps={{
                  id: "send-role-button",
                  onClick: () =>
                    openSendEmailModal(
                      RoleViewEvent.ROLE_VIEW_SEND_ROLE,
                      EmailTemplateTypeEnum.SEND_ROLE,
                    ),
                  disabled: isSendingDisabled,
                  disableRipple: true,
                  sx: {
                    fontSize: "0.875rem",
                    boxShadow: "none",
                    height: "40px",
                    lineHeight: "40px",
                    backgroundColor: theme.palette.primary.main,
                    "&:hover": {
                      backgroundColor: theme.palette.custom.purpleHover,
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: theme.palette.custom.purplePressed,
                      boxShadow: "none",
                    },
                  },
                }}
              >
                {t("sendRole.label")}
              </Button>
            </Stack>
          </Box>
        </Stack>
        {isLoading ? (
          <Loading />
        ) : (
          <RolesViewTable
            jobMatches={jobMatches}
            page={page}
            pageSize={PAGE_SIZE}
            totalRows={totalRows}
            onPageChange={setPage}
            onCheckboxClick={setNumAlumSelected}
            isRoleClosed={job.status === JobStatusEnum.CLOSED}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            onClick={onRowClick}
          />
        )}
      </div>
      <CenterModal open={sendEmailModalOpen != null}>
        <SendEmailModal
          searchAllAlumni={false}
          onSubmit={handleSendEmail}
          onClose={handleCloseSendRoleModal}
          initialJob={
            sendEmailModalOpen === EmailTemplateTypeEnum.SEND_ROLE ? job : null
          }
          selectedIds={selectedIds}
          emailType={sendEmailModalOpen as EmailTemplateTypeEnum}
          receivers={jobMatches.map(
            (match) =>
              ({
                id: match.applicant_id,
                firstName: match.first_name,
                lastName: match.last_name,
                email: match.email,
                status: match.status,
              }) as Receiver,
          )}
        />
      </CenterModal>
    </Stack>
  );
}
