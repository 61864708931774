import { Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { autocompleteDepartment, autocompleteLocation } from "../../api/filter";
import { Translation } from "../../lib/constants";
import { isInteger } from "../../lib/utils";
import theme from "../../theme";
import MultiSelectComboBox, { MultiSelectOption } from "../MultiSelectComboBox";
import ApiSupportedMultiSelectComboBox from "../MultiSelectComboBox/ApiSupportedMultiSelectComboBox";
import { AnnotatedInput, RangedAnnotatedInput } from "../UtilComponents";
import { Format } from "../../lib/utils";
import { BasicProfileDTO } from "../../models/api/filter";
import { UserContext } from "../../lib/context";

export const LastKnownLocation = ({
  initialSelectedLocations,
  selectedLocations,
  setLocations,
}: {
  initialSelectedLocations: MultiSelectOption[];
  selectedLocations: Set<string>;
  setLocations: (arg: Set<string>) => void;
}) => {
  const { t } = useTranslation(Translation.common);

  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  return (
    <Stack gap="0.5rem">
      <Typography
        fontSize="0.875rem"
        fontWeight={500}
        lineHeight="1.25rem"
        color={theme.palette.grey[800]}
      >
        {t("filter.fields.labels.lastKnownLocation")}
      </Typography>
      <ApiSupportedMultiSelectComboBox
        suggestions={initialSelectedLocations}
        selectedValues={selectedLocations}
        setSelectedValues={setLocations}
        placeholder={
          selectedLocations.size == 0
            ? t("filter.fields.input.placeholders.lastKnownLocation")
            : ""
        }
        getOptions={async (searchTerm: string) => {
          try {
            const response = await autocompleteLocation(orgId, searchTerm);

            return response.last_known_locations.map((location) => {
              return {
                value: location,
                label: location,
                selectable: true,
              } as MultiSelectOption;
            });
          } catch (error) {
            console.error(
              "Error fetching autocomplete suggestions for location filter:",
              error,
            );
            return [];
          }
        }}
        format={Format.location}
      />
    </Stack>
  );
};

export const FormerDepartment = ({
  initialSelectedDepartments,
  selectedDepartments,
  setDepartments,
}: {
  initialSelectedDepartments: MultiSelectOption[];
  selectedDepartments: Set<string>;
  setDepartments: (arg: Set<string>) => void;
}) => {
  const { t } = useTranslation(Translation.common);

  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  return (
    <Stack gap="0.5rem">
      <Typography
        fontSize="0.875rem"
        fontWeight={500}
        lineHeight="1.25rem"
        color={theme.palette.grey[800]}
      >
        {t("filter.fields.labels.formerDepartment")}
      </Typography>
      <ApiSupportedMultiSelectComboBox
        suggestions={initialSelectedDepartments}
        selectedValues={selectedDepartments}
        setSelectedValues={setDepartments}
        placeholder={
          selectedDepartments.size == 0
            ? t("filter.fields.input.placeholders.formerDepartment")
            : ""
        }
        getOptions={async (searchTerm: string) => {
          try {
            const response = await autocompleteDepartment(orgId, searchTerm);

            return response.former_departments.map((department) => {
              return {
                value: department,
                label: department,
                selectable: true,
              } as MultiSelectOption;
            });
          } catch (error) {
            console.error(
              "Error fetching autocomplete suggestions for department filter:",
              error,
            );
            return [];
          }
        }}
        format={Format.uppercaseFirstLetter}
      />
    </Stack>
  );
};

export const FormerSalary = ({
  minSalary,
  maxSalary,
  setMinSalary,
  setMaxSalary,
  setInputError,
}: {
  minSalary: string;
  maxSalary: string;
  setMinSalary: (arg: string) => void;
  setMaxSalary: (arg: string) => void;
  setInputError: (arg: boolean) => void;
}) => {
  const { t } = useTranslation(Translation.common);

  return (
    <RangedInput
      min={minSalary}
      max={maxSalary}
      setMin={setMinSalary}
      setMax={setMaxSalary}
      setInputError={setInputError}
      label={t("filter.fields.labels.formerSalary")}
      annotation={t("filter.fields.input.usd")}
      id="ranged-input-former-salary"
    />
  );
};

export const Tenure = ({
  minTenure,
  maxTenure,
  setMinTenure,
  setMaxTenure,
  setInputError,
}: {
  minTenure: string;
  maxTenure: string;
  setMinTenure: (arg: string) => void;
  setMaxTenure: (arg: string) => void;
  setInputError: (arg: boolean) => void;
}) => {
  const { t } = useTranslation(Translation.common);

  return (
    <RangedInput
      min={minTenure}
      max={maxTenure}
      setMin={setMinTenure}
      setMax={setMaxTenure}
      setInputError={setInputError}
      label={t("filter.fields.labels.tenure")}
      annotation={t("filter.fields.input.years")}
      id="ranged-input-tenure"
    />
  );
};

export const TimeSinceDeparture = ({
  timeSince,
  setTimeSince,
  setInputError,
}: {
  timeSince: { years: number; months: number };
  setTimeSince: (arg: { years: number; months: number }) => void;
  setInputError: (arg: boolean) => void;
}) => {
  const { t } = useTranslation(Translation.common);
  const [years, setYears] = useState<string>("");
  const [months, setMonths] = useState<string>("");
  const [error, setError] = useState({ months: false, years: false });

  useEffect(() => {
    setInputError(error.months || error.years);
  }, [error]);

  useEffect(() => {
    setYears(timeSince.years.toString());
    setMonths(timeSince.months.toString());
  }, [timeSince]);

  useEffect(() => {
    const monthsValid = isInteger(months);
    const yearsValid = isInteger(years);
    setError({ months: !monthsValid, years: !yearsValid });
    if (monthsValid && yearsValid) {
      const yearsInt = parseInt(years);
      const monthsInt = parseInt(months);
      if (yearsInt !== timeSince.years || monthsInt !== timeSince.months) {
        setTimeSince({ years: yearsInt, months: monthsInt });
      }
    }
  }, [years, months]);

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYears(event.target.value);
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonths(event.target.value);
  };

  return (
    <Stack gap="0.5rem">
      <Typography
        fontSize="0.875rem"
        fontWeight={500}
        lineHeight="1.25rem"
        color={theme.palette.grey[800]}
      >
        {t("filter.fields.labels.timeSinceDeparture")}
      </Typography>
      <Stack direction="row" gap="8px">
        <AnnotatedInput
          id="time-since-departure-years-input"
          annotation={t("filter.fields.input.years")}
          value={years}
          error={error.years}
          onChange={handleYearChange}
          width="100%"
        />
        <AnnotatedInput
          id="time-since-departure-months-input"
          annotation={t("filter.fields.input.months")}
          value={months}
          error={error.months}
          onChange={handleMonthChange}
          width="100%"
        />
      </Stack>
    </Stack>
  );
};

export const Recruiters = ({
  allRecruiters,
  selectedIds,
  setSelectedIds,
}: {
  allRecruiters: BasicProfileDTO[];
  selectedIds: Set<string>;
  setSelectedIds: (arg: Set<string>) => void;
}) => {
  const { t } = useTranslation(Translation.common);

  return (
    <Stack gap="0.5rem">
      <Typography
        fontSize="0.875rem"
        fontWeight={500}
        lineHeight="1.25rem"
        color={theme.palette.grey[800]}
      >
        {t("filter.fields.labels.recruiter")}
      </Typography>
      <MultiSelectComboBox
        options={allRecruiters.map((recruiter) => ({
          value: recruiter.id,
          label: `${recruiter.first_name} ${recruiter.last_name}`,
          selectable: true,
        }))}
        selectedValues={selectedIds}
        setSelectedValues={setSelectedIds}
        showChevron
        placeholder={
          selectedIds.size == 0
            ? t("filter.fields.input.placeholders.recruiter")
            : ""
        }
        matchFromStartFilter
      />
    </Stack>
  );
};

const RangedInput = ({
  min,
  max,
  setMin,
  setMax,
  setInputError,
  label,
  annotation,
  id,
}: {
  min: string;
  max: string;
  setMin: (arg: string) => void;
  setMax: (arg: string) => void;
  setInputError: (arg: boolean) => void;
  label: string;
  annotation: string;
  id: string;
}) => {
  const [error, setError] = useState({
    min: false,
    max: false,
    range: false,
  });

  useEffect(() => {
    setInputError(error.min || error.max || error.range);
  }, [error]);

  useEffect(() => {
    const minValid = isInteger(min) || min === "";
    const maxValid = isInteger(max) || max === "";
    const rangeValid =
      !minValid ||
      !maxValid ||
      min === "" ||
      max === "" ||
      parseInt(min) <= parseInt(max);
    setError({
      min: !minValid,
      max: !maxValid,
      range: !rangeValid,
    });
  }, [min, max]);

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMin(event.target.value);
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMax(event.target.value);
  };

  return (
    <Stack gap="0.5rem">
      <Typography
        fontSize="0.875rem"
        fontWeight={500}
        lineHeight="1.25rem"
        color={theme.palette.grey[800]}
      >
        {label}
      </Typography>
      <RangedAnnotatedInput
        label={label}
        minValue={min}
        maxValue={max}
        handleMinChange={handleMinChange}
        handleMaxChange={handleMaxChange}
        annotation={annotation}
        minError={error.min}
        maxError={error.max}
        rangeError={error.range}
        id={id}
      />
    </Stack>
  );
};

export const JobLocations = ({
  allLocations,
  selectedLocations,
  setSelectedLocations,
}: {
  allLocations: string[];
  selectedLocations: Set<string>;
  setSelectedLocations: (arg: Set<string>) => void;
}) => {
  const { t } = useTranslation(Translation.common);

  return (
    <Stack gap="0.5rem">
      <Typography
        fontSize="0.875rem"
        fontWeight={500}
        lineHeight="1.25rem"
        color={theme.palette.grey[800]}
      >
        {t("filter.fields.labels.location")}
      </Typography>
      <MultiSelectComboBox
        options={allLocations.map((location) => ({
          value: location,
          label: `${location}`,
          selectable: true,
        }))}
        selectedValues={selectedLocations}
        setSelectedValues={setSelectedLocations}
        showChevron
        placeholder={
          selectedLocations.size == 0
            ? t("filter.fields.input.placeholders.location")
            : ""
        }
      />
    </Stack>
  );
};
