import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import { Translation } from "../../lib/constants";
import { useTranslation } from "react-i18next";
import { RoleMatchingCriteriaDTO } from "../../models/api/criteria.ts";
import theme from "../../theme";
import CriteriaListItem from "../../components/CriteriaList/CriteriaListItem.tsx";
import briefcaseIcon from "/briefcase.svg";
import Button from "../../theme/Button.tsx";
import Stack from "@mui/material/Stack";
import HelpCircleIcon from "/help-circle-secondary.svg";
import { TooltipWhite } from "../../theme/Tooltip";

interface RoleMatchingProps {
  criteriaList: RoleMatchingCriteriaDTO[];
  globalCriteriaList: RoleMatchingCriteriaDTO[];
  onEdit: (criteria: RoleMatchingCriteriaDTO) => void;
  onToggle: (criteria: RoleMatchingCriteriaDTO, newValue: boolean) => void;
  onDelete: (criteria: RoleMatchingCriteriaDTO) => void;
  onCreateNew: () => void;
}

export default function RoleMatchingCriteriaList({
  criteriaList,
  globalCriteriaList,
  onEdit,
  onToggle,
  onDelete,
  onCreateNew,
}: RoleMatchingProps) {
  const { t } = useTranslation(Translation.settings);

  return (
    <Stack>
      <Box className="global-criteria-list" paddingBottom="2.25rem">
        <Typography
          fontSize="1rem"
          fontWeight={600}
          lineHeight="1.75rem"
          color={(theme) => theme.palette.grey[800]}
          className="criteria-list-header"
          paddingTop="0.75rem"
          paddingBottom="1.25rem"
        >
          {t("roleMatching.globalHeading")}
        </Typography>
        {globalCriteriaList?.map((criteria) => {
          const menuOptions = [
            {
              className: "menu-options-edit",
              label: t("roleMatching.edit"),
              hasDividerAbove: false,
              handler: () => {
                onEdit(criteria);
              },
            },
          ];
          return (
            <CriteriaListItem
              key={criteria.id}
              menuOptions={menuOptions}
              criteria={criteria}
              enableToggle={false}
              onToggle={(event) => {
                void onToggle(criteria, event.target.checked);
              }}
              onEdit={() => {
                onEdit(criteria);
              }}
            />
          );
        })}
      </Box>
      <Box className="custom-criteria-list">
        <Stack direction="row" paddingBottom="2rem">
          <Stack direction="row" alignItems="center">
            <Typography
              fontSize="1rem"
              fontWeight={600}
              lineHeight="1.5rem"
              color={(theme) => theme.palette.grey[800]}
              className="criteria-list-header"
              alignSelf="center"
            >
              {t("roleMatching.customHeading")}
            </Typography>
            <TooltipWhite
              className="custom-header-tooltip"
              arrow={false}
              title={t("roleMatching.tooltip.customHeading")}
            >
              <Box
                component="img"
                src={HelpCircleIcon}
                marginLeft="0.3rem"
                height="1rem"
                width="1rem"
              />
            </TooltipWhite>
          </Stack>
          <Box flexGrow={1} />
          {criteriaList?.length > 0 && (
            <Button
              buttonProps={{
                className: "new-custom-criteria-button",
                onClick: onCreateNew,
                disableRipple: true,
                sx: {
                  borderRadius: "0.5rem",
                  padding: "0.675rem 1rem",
                  backgroundColor: theme.palette.primaryColor[600],
                  height: "2.5rem",
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  lineHeight: "1.25rem",
                },
              }}
            >
              {t("roleMatching.newCriteria")}
            </Button>
          )}
        </Stack>
        {criteriaList?.length === 0 && (
          <Stack direction="column" justifyContent="center" alignItems="center">
            <IconButton
              style={{
                padding: "0.75rem",
                borderRadius: "50%",
                backgroundColor: theme.palette.grey[100],
              }}
            >
              <Box component="img" src={briefcaseIcon} />
            </IconButton>
            <Typography
              style={{
                paddingTop: "1.25rem",
                color: theme.palette.grey[800],
                fontSize: "1rem",
                fontWeight: 600,
              }}
            >
              {t("roleMatching.noCustomCriteriaHeading")}
            </Typography>
            <Typography
              style={{
                color: theme.palette.grey[500],
                fontSize: "0.875rem",
                fontWeight: 400,
              }}
            >
              {t("roleMatching.noCustomCriteriaTextLineOne")}
            </Typography>
            <Typography
              style={{
                paddingBottom: "1.5rem",
                color: theme.palette.grey[500],
                fontSize: "0.875rem",
                fontWeight: 400,
              }}
            >
              {t("roleMatching.noCustomCriteriaTextLineTwo")}
            </Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0.5rem",
                boxShadow: "none",
                height: "2.5rem",
                backgroundColor: theme.palette.primary.main,
              }}
              buttonProps={{
                onClick: onCreateNew,
                disableRipple: true,
              }}
            >
              {t("roleMatching.newCriteria")}
            </Button>
          </Stack>
        )}
        <Stack gap="1rem">
          {criteriaList?.map((criteria) => {
            const menuOptions = [
              {
                className: "menu-options-edit",
                label: t("roleMatching.edit"),
                hasDividerAbove: false,
                handler: () => {
                  onEdit(criteria);
                },
              },
              {
                className: "menu-options-delete",
                label: t("roleMatching.delete"),
                handler: () => {
                  onDelete(criteria);
                },
                hasDividerAbove: true,
                color: theme.palette.errorColor[700],
              },
            ];

            return (
              <CriteriaListItem
                key={criteria.id}
                menuOptions={menuOptions}
                criteria={criteria}
                enableToggle={true}
                onToggle={(event) => {
                  void onToggle(criteria, event.target.checked);
                }}
                onEdit={() => {
                  onEdit(criteria);
                }}
              />
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
}
