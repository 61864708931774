import { useTranslation } from "react-i18next";
import Stack, { StackProps } from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Translation } from "../lib/constants";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ErrorMessageProps {
  error: string;
  stackProps?: StackProps;
}

export default function ErrorMessage(props: ErrorMessageProps) {
  const { error, stackProps } = props;
  const { t } = useTranslation(Translation.login);
  const Icon = error === t("invalidMFA") ? ErrorOutlineIcon : CancelOutlinedIcon;
  return (
    <Stack
      flexDirection="row"
      gap="0.8rem"
      padding="1.38rem 1rem"
      sx={(theme) => ({
        border: `1px solid ${theme.palette.custom.errorBorder}`,
        borderRadius: "0.25rem",
        backgroundColor: theme.palette.error.light,
      })}
      {...stackProps}
    >
      <Icon sx={(theme) => ({ color: theme.palette.error.dark })} />
      <Typography fontSize="0.875rem" color="error.dark">
        {error}
      </Typography>
    </Stack>
  );
}
