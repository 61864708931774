import { useTranslation } from "react-i18next";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { RouteId, Translation } from "../../lib/constants";
import theme from "../../theme";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import RoleHistoryTimeline from "../../components/RoleHistoryTimeline";
import Breadcrumbs from "../../components/Breadcrumbs";
import { AlumProfileData } from "../../route";

export default function RoleHistory({ baseRoute }: { baseRoute: string }) {
  const { t } = useTranslation(Translation.alumProfile);
  const alumData =
    baseRoute === RouteId.alumDatabase
      ? useRouteLoaderData(RouteId.alumDatabaseAlumProfile)
      : useRouteLoaderData(RouteId.rolesDatabaseAlumProfile);
  if (!alumData) return <></>;

  const { alum } = alumData as AlumProfileData;
  const navigate = useNavigate();

  return (
    <Stack padding="2rem">
      <Box
        paddingBottom="1rem"
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        <Breadcrumbs />
      </Box>
      <Stack id="role-history" direction="row" gap="2rem" padding="2rem 0">
        <Stack direction="row" gap="0.5rem">
          <Box
            className="back-button"
            width="1.5rem"
            height="1.5rem"
            borderRadius="0.25rem"
            display="grid"
            color={theme.palette.grey[600]}
            sx={(theme) => ({
              placeItems: "center",
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.grey[700],
                backgroundColor: theme.palette.grey[50],
              },
            })}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon sx={{ color: "inherit", width: "1.25rem" }} />
          </Box>
          <Typography fontWeight={600} minWidth={{ lg: "17.5rem" }}>
            {t("roleHistory.title")}
          </Typography>
        </Stack>
        <RoleHistoryTimeline alum={alum} />
      </Stack>
    </Stack>
  );
}
