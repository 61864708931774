import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import checkIcon from "/check.svg";
import minusIcon from "/minus.svg";

interface CheckboxProps {
  checked: CheckedState;
  disabled?: boolean;
  onCheck: (arg?: boolean) => void;
  id?: string;
  error?: boolean;
  circleShaped?: boolean;
  checkedBgColor?: string;
  checkedIconSrc?: string;
}

export default function Checkbox({
  checked,
  disabled,
  onCheck,
  id,
  error,
  circleShaped,
  checkedBgColor,
  checkedIconSrc,
}: CheckboxProps) {
  const { t } = useTranslation(Translation.common);

  const backgroundColor = (theme: Theme, isHovered: boolean) => {
    if (disabled) {
      return theme.palette.grey[100];
    }
    switch (checked) {
      case CheckedState.CHECKED:
        return checkedBgColor ?? theme.palette.primary.light;
      case CheckedState.MIXED:
        return theme.palette.primary.light;
      default:
        if (isHovered) return theme.palette.grey[50];
        else return theme.palette.common.white;
    }
  };

  const borderColor = (theme: Theme) => {
    if (error) {
      return theme.palette.custom.errorBorder;
    }
    switch (checked) {
      case CheckedState.CHECKED:
        return theme.palette.primary.main;
      case CheckedState.MIXED:
        return theme.palette.primary.main;
      default:
        return theme.palette.grey[300];
    }
  };

  return (
    <Box
      id={id}
      sx={(theme) => ({
        display: "grid",
        placeItems: "center",
        width: "20px",
        height: "20px",
        padding: "2px",
        borderRadius: circleShaped ? "50%" : "4px",
        backgroundColor: backgroundColor(theme, false),
        "&:hover": { backgroundColor: backgroundColor(theme, true) },
        border: "1px solid",
        borderColor: borderColor(theme),
        boxSizing: "border-box",
        cursor: disabled ? "auto" : "pointer",
      })}
      onClick={() => {
        if (!disabled) onCheck(checked === CheckedState.CHECKED);
      }}
    >
      {checked === CheckedState.CHECKED && (
        <Box
          component="img"
          alt={t("checkIconAlt")}
          src={checkedIconSrc ?? checkIcon}
          width="14px"
          height="14px"
          sx={{ userSelect: "none" }}
        />
      )}
      {checked === CheckedState.MIXED && (
        <Box
          component="img"
          alt={t("minusIconAlt")}
          src={minusIcon}
          width="14px"
          height="14px"
          sx={{ userSelect: "none" }}
        />
      )}
    </Box>
  );
}

export enum CheckedState {
  CHECKED,
  UNCHECKED,
  MIXED,
}
