import { createContext } from "react";
import { AuthUser } from "../models/auth";
import { UserConfigDTO } from "../models/config";
import { UserManager } from "oidc-client-ts";
import { AUTHORITY, CLIENT_ID } from "../env";
import { REDIRECT_URL } from "./constants";

type UserContext = [AuthUser | null, (user: AuthUser | null) => void];
type UserConfigContext = [
  UserConfigDTO | null,
  (config: UserConfigDTO | null) => void,
];
type FlagsContext = [string[] | null, (flags: string[] | null) => void];

export const UserContext = createContext<UserContext>([null, () => {}]);
export const UserConfigContext = createContext<UserConfigContext>([
  null,
  () => {},
]);
export const FlagsContext = createContext<FlagsContext>([null, () => {}]);

export const OAuthUserManager = new UserManager({
  authority: AUTHORITY,
  client_id: CLIENT_ID,
  redirect_uri: REDIRECT_URL,
  scope: "openid email",
});