import { Box, Divider, Stack, Typography } from "@mui/material";
import { TooltipWhite } from "../../theme/Tooltip.tsx";
import { AlumDTO, RelationshipTypeEnum } from "../../models/api/alum.ts";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants.ts";
import HelpCircleIcon from "/help-circle-secondary.svg";
import CompanyContactsCard from "./CompanyContactsCard.tsx";
import theme from "../../theme";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { useContext, useState } from "react";
import CenterModal from "../CenterModal";
import { trackGoogleAnalyticsEvent } from "../../lib/utils.ts";
import { AlumProfileEvent } from "../../lib/eventEnums.ts";
import { UserContext } from "../../lib/context.ts";
import EmptyList from "../EmptyList/index.tsx";
import contactIcon from "/user-square.svg";

interface CompanyContactsProps {
  alum: AlumDTO;
  limitTo?: number;
}

export default function CompanyContacts({
  alum,
  limitTo,
}: CompanyContactsProps) {
  const [showAllCompanyContacts, setShowAllCompanyContacts] = useState(false);
  const { t } = useTranslation(Translation.alumProfile);
  const [user, _setUser] = useContext(UserContext);

  const sortedContacts = [...(alum.company_contacts ?? [])]
    .filter((contact) => contact.email)
    .sort((a, b) => {
      if (a.relationship_type === b.relationship_type) {
        return a.time_since_crossover - b.time_since_crossover;
      }
      return a.relationship_type === RelationshipTypeEnum.MANAGER ? -1 : 1;
    });

  const companyContacts = limitTo
    ? sortedContacts.slice(0, limitTo)
    : sortedContacts;

  const showSeeAll = limitTo && sortedContacts.length > limitTo;

  const handleSeeAllContactsClick = () => {
    setShowAllCompanyContacts(true);
    trackGoogleAnalyticsEvent({
      event: AlumProfileEvent.ALUM_PROFILE_CONTACTS_SEE_ALL,
      type: "alum_profile_see_all_contacts",
      org: user?.orgName,
    });
  };

  return (
    <>
      <Box id="company-contacts">
        <Stack gap="1.5rem">
          <Stack direction="row">
            <Typography fontWeight={600} fontSize="1rem">
              {t("companyContacts.title")}
            </Typography>
            <TooltipWhite title={t("companyContacts.tooltip")}>
              <Box component="img" src={HelpCircleIcon} marginLeft="0.25rem" />
            </TooltipWhite>
          </Stack>
          {companyContacts.length === 0 ? (
            <EmptyList
              icon={contactIcon}
              iconAlt={t("companyContacts.iconAlt")}
              title={t("companyContacts.empty.title")}
              message={t("companyContacts.empty.subtitle")}
            />
          ) : (
            companyContacts.map((contact, idx) => (
              <Stack key={idx} gap="1.25rem">
                <CompanyContactsCard contact={contact} />
                {(showSeeAll ?? idx + 1 == companyContacts.length) && (
                  <Divider />
                )}
              </Stack>
            ))
          )}
          {showSeeAll && (
            <Typography
              fontWeight={600}
              fontSize="0.875rem"
              color={theme.palette.primary.main}
              onClick={() => handleSeeAllContactsClick()}
              style={{ cursor: "pointer" }}
            >
              {t("companyContacts.showAll")}
            </Typography>
          )}
        </Stack>
      </Box>
      <CenterModal
        open={showAllCompanyContacts}
        onClose={() => setShowAllCompanyContacts(false)}
        sx={{
          width: "640px",
          padding: "1.5rem 1.5rem 3rem",
          maxHeight: "94vh",
          outline: "none",
          overflow: "auto",
        }}
      >
        <Stack
          id="company-contacts-modal"
          gap="1.5rem"
          className="contacts-stack"
        >
          <Stack direction="row">
            <Typography fontWeight={600} fontSize="1.25rem" flexGrow={1}>
              {t("companyContacts.title")}
            </Typography>
            <CloseIcon
              className="close-icon"
              style={{ cursor: "pointer" }}
              onClick={() => setShowAllCompanyContacts(false)}
            />
          </Stack>
          {sortedContacts.map((contact, idx) => (
            <Stack key={idx} gap="1.25rem">
              <CompanyContactsCard contact={contact} />
              {idx + 1 != sortedContacts.length && <Divider />}
            </Stack>
          ))}
        </Stack>
      </CenterModal>
    </>
  );
}
