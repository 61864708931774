import { Typography, IconButton, Divider, Drawer, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MuiButton from "@mui/material/Button";
import theme from "../../theme";
import {
  ApplyToAllRoles,
  TimeSinceDeparture,
  ProximityToRole,
  TotalTenure,
  DepartmentAndLocation,
  EnableCriteria,
  NameAndInterests,
  ReceiverEmail,
  Name,
  Automation,
  DepartmentAndRoles,
} from "./CriteriaFields";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import {
  AllCriteriaFields,
  AtsDepartmentDTO,
  RoleMatchingCriteriaDTO,
} from "../../models/api/criteria";
import {
  isReferralMatching,
  isRoleMatching,
  isRoleMatchingList,
  isSnPMatching,
  isSnPMatchingList,
  REFERRAL_MATCHING_CRITERIA_DEFAULT_VALUES,
  resetToDefault,
  ROLE_MATCHING_CRITERIA_DEFAULT_VALUES,
} from "../../models/criteria";
import { Translation } from "../../lib/constants";
import { toast } from "react-toastify";
import ConfirmActionModal from "../ConfirmActionModal";
import { trackGoogleAnalyticsEvent } from "../../lib/utils.ts";
import { FailureEvent } from "../../lib/eventEnums.ts";
import { UserConfigContext } from "../../lib/context.ts";
import { cloneDeep } from "lodash";

enum Modal {
  None = "",
  Reset = "reset",
  Discard = "discard",
  Save = "save",
  Delete = "delete",
}

export type NotificationInfo = {
  message: string;
  type: "success" | "error" | "info";
};

export type InputError = {
  time_since_departure: boolean;
  proximity_to_role: boolean;
  department_and_location: boolean;
  name: boolean;
  receiver_email: boolean;
};

export type EmptyError = {
  name_and_interests: boolean;
  receiver_email: boolean;
  department_and_roles: boolean;
  name: boolean;
};

type Props<T> = {
  heading: string;
  criteriaToEdit: T;
  setCriteriaToEdit: (arg: (oldCriteria: T) => T) => void;
  onSubmit: (original: T | null, arg: T) => Promise<boolean>;
  closeEditWindow: (arg?: NotificationInfo) => void;
  criteriaList: T[];
  toastContainerIds: {
    info: string;
    success: string;
    error: string;
  };
  getModalInfo?: () => Promise<JSX.Element>;
  onDelete?: (arg: T) => Promise<boolean>;
  drawerId?: string;
  departments?: AtsDepartmentDTO[];
};

export default function EditCriteria<T>({
  heading,
  criteriaToEdit,
  setCriteriaToEdit,
  onSubmit,
  onDelete,
  closeEditWindow,
  criteriaList,
  toastContainerIds,
  getModalInfo,
  drawerId,
  departments,
}: Props<T>) {
  const { t } = useTranslation(Translation.settings);
  const [userConfig, _setUserConfig] = useContext(UserConfigContext);
  const roleMatchingCriteria: RoleMatchingCriteriaDTO | null = isRoleMatching(
    criteriaToEdit,
  )
    ? criteriaToEdit
    : null;

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [modal, setModal] = useState(Modal.None);
  const [inputError, setInputError] = useState<InputError>({
    time_since_departure: false,
    proximity_to_role: false,
    department_and_location: false,
    receiver_email: false,
    name: false,
  });
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [modalInfoElement, setModalInfoElement] = useState<JSX.Element>(<></>);
  const [oldCriteria, setOldCriteria] = useState<T | null>(null);

  useEffect(() => {
    if (!oldCriteria) {
      setOldCriteria(cloneDeep(criteriaToEdit));
    }
  }, [criteriaToEdit]);

  const updateField = (arg: keyof AllCriteriaFields) => (value: any) => {
    setUnsavedChanges(true);
    setCriteriaToEdit((criteriaToEdit) => ({
      ...criteriaToEdit,
      [arg]: value,
    }));
  };

  const deleteCriteria = async () => {
    if (onDelete) {
      const res = await onDelete(criteriaToEdit);
      closeDrawer(
        res
          ? {
              message: t("editCriteria.notifications.deletedSuccess"),
              type: "info",
            }
          : {
              message: t("editCriteria.notifications.deletedError"),
              type: "error",
            },
      );
    }
  };

  const handleSubmit = async (reset: boolean) => {
    setIsSaveButtonClicked(true);
    if (Object.values(inputError).some((value) => value)) {
      showModal(Modal.None);
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "edit-criteria-input-error",
        type: "Error Toast",
        message: t("editCriteria.notifications.inputError"),
      });
      toast.error(t("editCriteria.notifications.inputError"), {
        containerId: toastContainerIds.error,
        toastId: "edit-criteria-input-error",
      });
    } else if (checkEmptyErrors()) {
      showModal(Modal.None);
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "edit-criteria-empty-error",
        type: "Error Toast",
        message: t("editCriteria.notifications.emptyError"),
      });
      toast.error(t("editCriteria.notifications.emptyError"), {
        containerId: toastContainerIds.error,
        toastId: "edit-criteria-empty-error",
      });
    } else if (reset) {
      const res = await onSubmit(oldCriteria, resetToDefault(criteriaToEdit));
      closeDrawer(
        res
          ? { message: t("editCriteria.notifications.reset"), type: "info" }
          : {
              message: t("editCriteria.notifications.savedError"),
              type: "error",
            },
      );
    } else {
      const res = await onSubmit(oldCriteria, criteriaToEdit);
      closeDrawer(
        res
          ? {
              message: t("editCriteria.notifications.savedSuccess"),
              type: "success",
            }
          : {
              message: t("editCriteria.notifications.savedError"),
              type: "error",
            },
      );
    }
  };

  const attemptCloseDrawer = () => {
    setIsSaveButtonClicked(false);
    if (unsavedChanges) {
      showModal(Modal.Discard);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = (notification?: NotificationInfo) => {
    setUnsavedChanges(false);
    showModal(Modal.None);
    closeEditWindow(notification);
    setIsSaveButtonClicked(false);
    setOldCriteria(null);
  };

  const modalPrimaryButtonHandler = () => {
    if (modal == Modal.Discard) {
      closeDrawer({
        message: t("editCriteria.notifications.discard"),
        type: "info",
      });
    } else if (modal == Modal.Reset) {
      setCriteriaToEdit(resetToDefault(criteriaToEdit));
      resetErrors();
      setUnsavedChanges(true);
      showModal(Modal.None);
      toast.info(t("editCriteria.notifications.reset"), {
        containerId: toastContainerIds.info,
        toastId: "edit-criteria-reset",
      });
      setIsSaveButtonClicked(false);
    } else if (modal == Modal.Save) {
      handleSubmit(false);
    } else if (modal == Modal.Delete) {
      deleteCriteria();
    }
  };

  const modalSecondaryButtonHandler = () => {
    if (modal == Modal.Discard) {
      handleSubmit(false);
    } else if (
      modal == Modal.Reset ||
      modal == Modal.Save ||
      modal == Modal.Delete
    ) {
      showModal(Modal.None);
    }
  };

  const updateInputError = (arg: keyof InputError) => (value: boolean) => {
    setInputError((prev) => ({
      ...prev,
      [arg]: value,
    }));
  };

  const checkEmptyErrors = () => {
    if (isRoleMatching(criteriaToEdit)) {
      const isDepartmentAndRolesEmpty =
        criteriaToEdit.ats_department == null ||
        (!criteriaToEdit.apply_to_all_roles_in_department &&
          criteriaToEdit.job_titles.length == 0);
      const isNameEmpty = criteriaToEdit.name.trim() == "";

      return isDepartmentAndRolesEmpty || isNameEmpty;
    } else if (isSnPMatching(criteriaToEdit)) {
      const isNameAndInterestsEmpty =
        criteriaToEdit.name.trim() == "" ||
        criteriaToEdit.interests.roles.length === 0;
      const isReceiverAndEmailEmpty =
        criteriaToEdit.receiver_email.trim() == "";

      return isNameAndInterestsEmpty || isReceiverAndEmailEmpty;
    } else if (isReferralMatching(criteriaToEdit)) {
      // no empty errors for referral matching, leaving this here for clarity
      return false;
    }
  };

  const resetErrors = () => {
    setInputError({
      time_since_departure: false,
      proximity_to_role: false,
      department_and_location: false,
      receiver_email: false,
      name: false,
    });
  };

  const showAutomatedWorkflowFields = () => {
    const isAutomatedFlowOrgEnabled =
      userConfig?.hasAutomatedJobMatchingEnabled() ?? false;
    const isBaselineCriteria = roleMatchingCriteria?.is_baseline ?? false;
    console.log(userConfig);
    return isAutomatedFlowOrgEnabled && !isBaselineCriteria;
  };

  const showModal = async (modal: Modal) => {
    setModal(modal);
    if (modal == Modal.Save && getModalInfo) {
      setIsLoading(true);
      try {
        const infoElement = await getModalInfo();
        setModalInfoElement(infoElement);
        setIsLoading(false);
      } catch (e) {
        setModalInfoElement(<></>);
        setIsLoading(false);
        console.log("fetching modal info failed");
      }
    } else {
      setModalInfoElement(<></>);
    }
  };

  return (
    <Drawer
      id={drawerId ? drawerId : "edit-criteria-drawer"}
      anchor="right"
      open={!(criteriaToEdit == null)}
      onClose={attemptCloseDrawer}
    >
      <Stack
        width={700}
        height="100vh"
        role="presentation"
        display="flex"
        flexDirection="column"
      >
        <Stack padding="24px" gap="24px" flexGrow="1" overflow="scroll">
          <Stack
            id="edit-criteria-drawer-header"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              id="edit-criteria-drawer-heading"
              fontSize="20px"
              fontWeight={600}
              lineHeight="28px"
              color={(theme) => theme.palette.grey[800]}
            >
              {heading}
            </Typography>
            <IconButton
              disableRipple
              aria-haspopup="true"
              onClick={attemptCloseDrawer}
              style={{ padding: 0 }}
            >
              <ClearIcon />
            </IconButton>
          </Stack>

          {isRoleMatching(criteriaToEdit) &&
            isRoleMatchingList(criteriaList) && (
              <>
                <Stack
                  direction="column"
                  style={{
                    borderRadius: "12px",
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    width: "100%",
                    padding: "24px",
                    display: "flex",
                    gap: "32px",
                  }}
                >
                  {!criteriaToEdit.is_baseline && (
                    <>
                      <EnableCriteria
                        isEnabled={criteriaToEdit.is_enabled}
                        setIsEnabled={updateField("is_enabled")}
                      />
                      <Divider />
                      <Name
                        criteriaId={criteriaToEdit.id}
                        name={criteriaToEdit.name}
                        setName={updateField("name")}
                        setNameExistsError={updateInputError("name")}
                        isSaveButtonClicked={isSaveButtonClicked}
                        criteriaList={criteriaList}
                      />
                      <DepartmentAndRoles
                        criteria={criteriaToEdit}
                        department={criteriaToEdit.ats_department}
                        setDepartment={updateField("ats_department")}
                        roles={criteriaToEdit.job_titles}
                        setRoles={updateField("job_titles")}
                        applyToAllRolesInDepartment={
                          criteriaToEdit.apply_to_all_roles_in_department
                        }
                        setApplyToAllRolesInDepartment={updateField(
                          "apply_to_all_roles_in_department",
                        )}
                        isSaveButtonClicked={isSaveButtonClicked}
                        departments={departments ?? []}
                        criteriaList={criteriaList}
                      />
                      <Divider />
                    </>
                  )}
                  <ApplyToAllRoles
                    applyToAll={criteriaToEdit.apply_to_existing_roles}
                    setApplyToAll={updateField("apply_to_existing_roles")}
                  />
                </Stack>
                <Divider />
                <TimeSinceDeparture
                  timeSince={criteriaToEdit.time_since_departure}
                  setTimeSince={updateField("time_since_departure")}
                  setInputError={updateInputError("time_since_departure")}
                  defaultValue={
                    ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.time_since_departure
                  }
                />
                <ProximityToRole
                  proximity={criteriaToEdit.proximity_to_role}
                  setProximity={updateField("proximity_to_role")}
                  setInputError={updateInputError("proximity_to_role")}
                  defaultValue={
                    ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.proximity_to_role
                  }
                />
                <TotalTenure
                  tenure={criteriaToEdit.total_tenure}
                  setTenure={updateField("total_tenure")}
                  defaultValue={
                    ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.total_tenure
                  }
                />

                {showAutomatedWorkflowFields() && (
                  <>
                    <Divider />
                    <Automation
                      isAutomated={criteriaToEdit.is_automated}
                      setIsAutomated={updateField("is_automated")}
                    />
                  </>
                )}
              </>
            )}
          {isReferralMatching(criteriaToEdit) && (
            <>
              <TimeSinceDeparture
                timeSince={criteriaToEdit.time_since_departure}
                setTimeSince={updateField("time_since_departure")}
                setInputError={updateInputError("time_since_departure")}
                defaultValue={
                  REFERRAL_MATCHING_CRITERIA_DEFAULT_VALUES.time_since_departure
                }
              />
              <DepartmentAndLocation
                sameDepartment={criteriaToEdit.match_to_same_department}
                setSameDepartment={updateField("match_to_same_department")}
                proximity={criteriaToEdit.proximity_to_role}
                setProximity={updateField("proximity_to_role")}
                setInputError={updateInputError("department_and_location")}
                defaultProximityValue={
                  REFERRAL_MATCHING_CRITERIA_DEFAULT_VALUES.proximity_to_role
                }
                defaultSameDepartmentValue={
                  REFERRAL_MATCHING_CRITERIA_DEFAULT_VALUES.match_to_same_department
                }
              />
            </>
          )}
          {isSnPMatching(criteriaToEdit) && isSnPMatchingList(criteriaList) && (
            <>
              <EnableCriteria
                isEnabled={criteriaToEdit.is_enabled}
                setIsEnabled={updateField("is_enabled")}
              />
              <Divider />
              <NameAndInterests
                name={criteriaToEdit.name}
                setName={updateField("name")}
                interests={criteriaToEdit.interests}
                setInterests={updateField("interests")}
                setNameExistsError={updateInputError("name")}
                id={criteriaToEdit.id}
                criteriaList={criteriaList}
                isSaveButtonClicked={isSaveButtonClicked}
                infoToastContainerId={toastContainerIds.info}
              />
              <Divider />
              <ReceiverEmail
                email={criteriaToEdit.receiver_email}
                setEmail={updateField("receiver_email")}
                setFormatError={updateInputError("receiver_email")}
                isSaveButtonClicked={isSaveButtonClicked}
              />
            </>
          )}
        </Stack>

        <Stack
          direction="row"
          justifyContent="end"
          gap="12px"
          padding="16px 24px"
          borderTop="1px solid var(--Gray-200, #EAECF0)"
        >
          {roleMatchingCriteria &&
            !roleMatchingCriteria.is_baseline &&
            onDelete && (
              <MuiButton
                id={"delete-button"}
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "1.2rem",
                  width: "92px",
                  height: "40px",
                  borderRadius: "8px",
                  color: theme.palette.errorColor[700],
                  marginRight: "auto",
                  "&:hover": {
                    background: theme.palette.errorColor[50],
                  },
                }}
                disableRipple
                onClick={() => showModal(Modal.Delete)}
              >
                {t("editCriteria.delete")}
              </MuiButton>
            )}
          {!isSnPMatching(criteriaToEdit) && (
            <MuiButton
              id={"reset-all-button"}
              variant="outlined"
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "1.2rem",
                width: "92px",
                height: "40px",
                borderRadius: "8px",
                borderColor: theme.palette.grey[300],
                color: theme.palette.grey[600],
                "&:hover": {
                  borderColor: theme.palette.grey[300],
                },
              }}
              disableRipple
              onClick={() => showModal(Modal.Reset)}
            >
              {t("editCriteria.resetAll")}
            </MuiButton>
          )}
          <MuiButton
            id={"save-button"}
            sx={{
              bgcolor: theme.palette.primaryColor[600],
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "1.2rem",
              width: "80px",
              height: "40px",
              borderRadius: "8px",
              color: "white",
              "&:hover": {
                bgcolor: theme.palette.primaryColor[700],
              },
              transition: "none",
            }}
            disableRipple
            onClick={() => {
              if (isRoleMatching(criteriaToEdit)) {
                showModal(Modal.Save);
              } else {
                handleSubmit(false);
              }
            }}
          >
            {t("editCriteria.save")}
          </MuiButton>
        </Stack>

        <ConfirmActionModal
          open={modal !== Modal.None}
          primaryButtonHandler={modalPrimaryButtonHandler}
          secondaryButtonHandler={modalSecondaryButtonHandler}
          primaryButtonBgColor={
            modal === Modal.Discard || modal === Modal.Delete
              ? theme.palette.error.main
              : theme.palette.primary.main
          }
          primaryButtonHoverBgColor={
            modal === Modal.Discard || modal === Modal.Delete
              ? theme.palette.error.dark
              : theme.palette.custom.purpleHover
          }
          heading={t(`editCriteria.modals.${modal}.heading`)}
          subHeading={t(`editCriteria.modals.${modal}.subHeading`)}
          primaryButtonText={t(`editCriteria.modals.${modal}.primaryButton`)}
          secondaryButtonText={t(
            `editCriteria.modals.${modal}.secondaryButton`,
          )}
          isLoading={isLoading}
          infoElement={modalInfoElement}
        />
      </Stack>
    </Drawer>
  );
}
