import { RadioProps } from "@mui/material";
import MUIRadio from "@mui/material/Radio";
import theme from "../../theme";

export default function Radio(props: RadioProps) {
  return (
    <MUIRadio
      disableRipple
      size="small"
      sx={{
        padding: "0.5rem",
        color: theme.palette.grey[300],
        background: {
          width: "0.375rem",
          height: "0.375rem",
        },
        "&.Mui-checked": {
          color: theme.palette.primaryColor[600],
        },
      }}
      {...props}
    />
  );
}
