import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { Translation } from "./lib/constants";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: "en",
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    ns: [
      Translation.common,
      Translation.login,
      Translation.rolesDatabase,
      Translation.settings,
      Translation.rolesView,
      Translation.alumProfile,
      Translation.email,
    ],
    defaultNS: Translation.common,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

// This i18n instance can be imported and used throughout the app. However, be careful with how you use it.
// i18n.init is ran asynchronously, so it's not guaranteed to have the language JSONS loaded.
// Instead, use the useTranslation hook from react-i18next to ensure that the language JSONs are ready before using them.
export default i18n;
