import Typography, { TypographyProps } from "@mui/material/Typography";
import { isOverflowX } from "../lib/utils";
import { useEffect, useRef, useState } from "react";
import Tooltip from "./Tooltip";

export default function EllipsisText(props: TypographyProps) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const ref = useRef<HTMLElement>(null);

  const handleResize = () => {
    if (!ref.current) return;
    setIsOverflowing(isOverflowX(ref.current));
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isOverflowing) {
    return (
      <Tooltip title={props.children}>
        <Typography
          ref={ref}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          {...props}
        />
      </Tooltip>
    );
  }

  return (
    <Typography
      ref={ref}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      onMouseEnter={(event) => {
        isOverflowX(event.currentTarget);
      }}
      {...props}
    />
  );
}
