export type NameDTO = {
  first_name: string;
  last_name: string;
};

export enum SearchbarContextType {
  ROLES_VIEW = "RolesView",
  ROLES_DATABASE = "RolesDatabase",
  ALUMNI_DATABASE = "AlumniDatabase",
}
