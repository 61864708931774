import { useTranslation } from "react-i18next";
import { useState, ChangeEvent, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import textLogo from "/text-logo.svg";
import ErrorMessage from "../../theme/ErrorMessage";
import Input from "../../theme/Input";
import { forgotPassword } from "../../api/auth";
import {trackGoogleAnalyticsEvent, validateEmail} from "../../lib/utils";
import TextLink from "../../theme/TextLink";
import "../../CustomToastAnimations.css";
import { Translation } from "../../lib/constants";
import theme from "../../theme";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer.tsx";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer.tsx";
import {FailureEvent} from "../../lib/eventEnums.ts";

enum PageStatus {
  INIT,
  EMAIL_SENT,
  ERROR,
}

export default function ForgotPassword() {
  const { t } = useTranslation(Translation.password);
  const [pageState, setPageState] = useState<PageStatus>(PageStatus.INIT);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const location = useLocation();

  useEffect(() => {
    const message = location.state?.message ?? "";
    if (message) {
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "forgot-password-error",
        type: "Error Toast",
        message: message,
      });
      toast.error(
          message,
          {containerId: "forgot-password-error", toastId: "state-error"}
      );
    }
  }, [location]);

  const handleResend = async () => {
    try {
      const response = await forgotPassword(email);
      if (response) {
        toast.success(
            t("forgot.resendSuccess"),
            {containerId: "forgot-password-success", toastId: "resend-success"}
        );
      } else {
        trackGoogleAnalyticsEvent(
            {
              event: FailureEvent.FAILURE,
              name: "forgot-password-error",
              type: "Error Toast",
              message: t("forgot.resendError")
            }
        )
        toast.error(
            t("forgot.resendError"),
            {containerId: "forgot-password-error", toastId: "resend-error"}
            )
      }
    } catch (error: any) {
        trackGoogleAnalyticsEvent({
              event: FailureEvent.FAILURE,
              name: "forgot-password-error",
              type: "Error Toast",
              message: t("forgot.resendError")
            })
      toast.error(
          t("forgot.resendError"),
          {containerId: "forgot-password-error", toastId: "resend-error"}
      )
    }
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    if (email == "") {
      setEmailError(t("forgot.emailRequired"));
      return;
    } else if (!validateEmail(email)) {
      setEmailError(t("forgot.invalidEmail"));
      return;
    }
    setEmailError("");
    try {
      const response = await forgotPassword(email);
      if (response) {
        setPageState(PageStatus.EMAIL_SENT);
      } else {
        setPageState(PageStatus.ERROR);
      }
    } catch (error: any) {
      setPageState(PageStatus.ERROR);
    }
  };

  return (
    <Stack
      justifyContent="start"
      alignItems="center"
      minHeight="100vh"
      width="100vw"
      padding="2rem"
    >
      <Stack
        width="22.5rem"
        height="33.15rem"
        gap="32px"
        alignItems="center"
        justifyContent="end"
      >
        <Box
          component="img"
          sx={{ width: "9rem" }}
          alt={t("boomerangLogoAlt", { ns: Translation.common })}
          src={textLogo}
        />

        <Stack alignItems="center" spacing="0.75rem">
          <Typography fontSize="1.875rem" fontWeight={600}>
            {pageState === PageStatus.EMAIL_SENT
              ? t("forgot.checkTitle")
              : t("forgot.title")}
          </Typography>
          <Typography
            fontSize="1rem"
            fontWeight={400}
            color="text.secondary"
            textAlign="center"
          >
            {pageState === PageStatus.EMAIL_SENT
              ? ( <> {t("forgot.checkSubtitle")} <Typography color="grey.800"> {email} </Typography> </> )
              : t("forgot.subtitle")}
          </Typography>
        </Stack>

        {pageState === PageStatus.ERROR && (
          <ErrorMessage
            error={t("error.unexpected", { ns: Translation.common })}
          />
        )}

        <Stack width="100%">
          {pageState !== PageStatus.EMAIL_SENT && (
            <Input
              label={t("forgot.email")}
              placeholder={t("forgot.emailPlaceholder")}
              onChange={handleEmailChange}
              error={emailError}
              stackProps={{ marginBottom: "24px" }}
              inputProps={{ value: email }}
            />
          )}

          {pageState === PageStatus.EMAIL_SENT ? (
            <Stack direction="row" justifyContent="center" alignItems="center" gap="5px">
              <Typography
                fontSize="1rem"
                fontWeight={400}
                color="text.secondary"
              >
                {t("forgot.didntReceiveEmail")}
              </Typography>{" "}
              <Typography
                fontSize="0.875rem"
                fontWeight={600}
                color="primary.main"
                onClick={handleResend}
                style={{ cursor: "pointer" }}
              >
                {t("forgot.resendEmail")}
              </Typography>
            </Stack>
          ) : (
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                borderRadius: "0.5rem",
                boxShadow: "none",
                height: "2.5rem",
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  boxShadow: "none",
                },
                "&:disabled": {
                  backgroundColor: theme.palette.custom.purpleDisabled,
                  color: theme.palette.common.white,
                },
                transition: "none",
              }}
            >
              {t("forgot.cta")}
            </Button>
          )}
        </Stack>

        <TextLink
          text={t("forgot.backToLogin")}
          icon={<ArrowBackIcon />}
          linkProps={{
            to: "/login",
            style: {
              margin: "1rem 0",
              color: theme.palette.grey[600],
            },
          }}
        />
      </Stack>
      <ErrorToastContainer containerId={"forgot-password-error"} />
      <SuccessToastContainer containerId={"forgot-password-success"} />
    </Stack>
  );
}
