import { useContext, useEffect, useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { UserContext } from "../../lib/context";
import Avatar, { AvatarSize } from "../../theme/Avatar";
import Carousel, { ArrowPlacement } from "../../components/Carousel";
import CenterModal from "../../components/CenterModal";
import { useTranslation } from "react-i18next";
import briefcaseIcon from "/briefcase.svg";
import RoleCard from "./RoleCard";
import { JobMatchStatus, MatchAttributeType } from "../../models/job";
import { RouteId, Translation } from "../../lib/constants";
import theme from "../../theme";
import "react-toastify/dist/ReactToastify.css";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer.tsx";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer.tsx";
import { toast } from "react-toastify";
import SendEmailModal from "../../components/SendEmailModal/index.tsx";
import { EmailTemplateTypeEnum } from "../../models/api/resource.ts";
import { formatAnnuPay, trackGoogleAnalyticsEvent } from "../../lib/utils.ts";
import { AlumProfileEvent, FailureEvent } from "../../lib/eventEnums.ts";
import { AlumProfileData } from "../../route.tsx";
import { JobMatchDTO } from "../../models/api/alum.ts";
import { JobDashboardDTO } from "../../models/api/job.ts";
import { getJob } from "../../api/job.ts";
import Button from "../../theme/Button.tsx";
import { Box, Stack, Typography, Divider } from "@mui/material";
import RoleHistoryTimelineV2 from "../../components/RoleHistoryTimeline/RoleHistoryTimelineV2.tsx";
import { formatTenure, getTimeSinceDeparture } from "../../lib/time.ts";
import i18n from "i18next";
import CompanyContacts from "../../components/CompanyContacts/CompanyContacts.tsx";
import Education from "../../components/Education/index.tsx";
import EmptyList from "../../components/EmptyList/index.tsx";
import Notes from "../../components/Notes/Notes.tsx";

const SEND_ROLE_ERROR_TIMEOUT = 200;
const ROLE_HISTORY_LIMIT = 5;
const EDUCATION_LIMIT = 3;

function StatisticsCard({ label, value }: { label: string; value: string }) {
  return (
    <Stack
      padding="1rem"
      gap="0.5rem"
      border={(theme) => `1px solid ${theme.palette.grey[200]}`}
      borderRadius="0.75rem"
      alignItems="flex-start"
      flex="1 0 0"
    >
      <Typography fontSize="0.75rem" color="textSecondary">
        {label}
      </Typography>
      <Typography fontWeight={500}>{value}</Typography>
    </Stack>
  );
}

export default function AlumProfileV2({ baseRoute }: { baseRoute: string }) {
  const { t } = useTranslation(Translation.alumProfile);
  const common = useTranslation(Translation.common)[0];

  const navigate = useNavigate();

  const alumData =
    baseRoute === RouteId.alumDatabase
      ? useRouteLoaderData(RouteId.alumDatabaseAlumProfile)
      : useRouteLoaderData(RouteId.rolesDatabaseAlumProfile);

  if (!alumData) return <></>;

  const { alum } = alumData as AlumProfileData;

  const [user, _setUser] = useContext(UserContext);
  if (!user || !alum) return <></>;

  const endDateAtOrg = alum.end_date_at_org
    ? new Date(alum.end_date_at_org)
    : null;
  const timeSinceDeparture = getTimeSinceDeparture(endDateAtOrg);

  const totalTenure = alum.tenure_at_org
    ? formatTenure(alum.tenure_at_org)
    : null;

  const payRate = alum.former_salary;
  const payPeriod = alum.former_salary_pay_period;
  const annualSalary =
    payRate === 0 || payPeriod == null
      ? null
      : formatAnnuPay(payRate, payPeriod, common, i18n.language);

  const companyContactsDisplayLimit = 3;
  const notesDisplayLimit = 3;

  const [sendEmailModalOpen, setSendEmailModalOpen] =
    useState<EmailTemplateTypeEnum | null>(null);
  const [sendRoleJob, setSendRoleJob] = useState<JobDashboardDTO | null>(null);
  const [isSubscribed, setIsSubscribed] = useState(true);

  const hasCompanyContacts = () => {
    const companyContactLength = alum?.company_contacts?.length ?? 0;
    return companyContactLength > 0;
  };

  useEffect(() => {
    setIsSubscribed(alum.is_subscribed);
  }, []);

  const handleOpenSendRoleModal = async (jobMatch: JobMatchDTO) => {
    if (alum.email && isSubscribed) {
      trackGoogleAnalyticsEvent({
        event: AlumProfileEvent.ALUM_PROFILE_SEND_ROLE,
        type: "send_role_click",
        org: user?.orgName,
        customParameters: {
          status: jobMatch.status,
        },
      });
      try {
        const job = await getJob(user.orgId, jobMatch.job_id);
        setSendRoleJob(job);
        setSendEmailModalOpen(EmailTemplateTypeEnum.SEND_ROLE);
      } catch {
        setSendRoleJob(null);
        setSendEmailModalOpen(EmailTemplateTypeEnum.SEND_ROLE);

        trackGoogleAnalyticsEvent({
          event: FailureEvent.FAILURE,
          name: "get-job-error",
          type: "Error Toast",
          message: t("toast.jobError"),
        });

        setTimeout(function () {
          toast.warning(t("toast.jobError"), {
            containerId: "send-email-error",
            toastId: "get-job-error",
          });
        }, SEND_ROLE_ERROR_TIMEOUT);
      }
    } else if (!alum.email) {
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "no-email",
        type: "Error Toast",
        message: t("roleMatches.noEmailMessage"),
      });
      toast.error(t("roleMatches.noEmailMessage"), {
        containerId: "alum-profile-error",
        toastId: "no-email",
      });
    } else {
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "no-subscription",
        type: "Error Toast",
        message: t("roleMatches.notSubscribedMessage"),
      });
      toast.error(t("roleMatches.notSubscribedMessage"), {
        containerId: "alum-profile-error",
        toastId: "no-subscription",
      });
    }
  };

  const handleOpenSendMessageModal = async () => {
    if (!alum.email) {
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "no-email",
        type: "Error Toast",
        message: t("toast.noEmailForMessage"),
      });
      toast.error(t("toast.noEmailForMessage"), {
        containerId: "alum-profile-error",
        toastId: "no-email",
      });
      return;
    }

    trackGoogleAnalyticsEvent({
      event: AlumProfileEvent.ALUM_PROFILE_SEND_MESSAGE,
      type: "send_message_click",
      org: user?.orgName,
    });
    setSendEmailModalOpen(EmailTemplateTypeEnum.SEND_MESSAGE);
  };

  const handleCloseSendEmailModal = () => {
    setSendEmailModalOpen(null);
  };

  const handleSendEmail = () => {
    handleCloseSendEmailModal();
    toast.success(t("toast.success"), {
      containerId: "alum-profile-success",
      toastId: "send-role",
    });
  };

  const onRoleCardClick = (jobId: string) => {
    trackGoogleAnalyticsEvent({
      event: AlumProfileEvent.ALUM_PROFILE_ROLE_MATCH,
      type: "role_card_click",
      org: user?.orgName,
    });

    if (baseRoute === RouteId.rolesDatabase) {
      navigate(`/roles-database/${jobId}`);
    } else {
      navigate(`job/${jobId}`);
    }
  };

  return (
    <Box bgcolor={theme.palette.grey[50]} minHeight="100vh">
      <ErrorToastContainer containerId={"alum-profile-error"} />
      <SuccessToastContainer containerId={"alum-profile-success"} />
      <Box
        padding="2rem"
        paddingBottom="1rem"
        bgcolor={theme.palette.background.default}
      >
        <Breadcrumbs />
      </Box>
      <Box display="flex" justifyContent="center">
        <Stack
          direction="row"
          spacing="1rem"
          width="100%"
          maxWidth="1250px"
          px="2rem"
          pt="1rem"
          pb="4rem"
          sx={{
            "& > .MuiStack-root": {
              gap: "1rem",
            },
            "& > .MuiStack-root > .MuiBox-root": {
              bgcolor: theme.palette.background.default,
              padding: "1.5rem",
              borderRadius: "0.75rem",
              width: "100%",
            },
          }}
        >
          <Stack width="65%">
            <Box id="overview">
              <Stack gap="1.25rem">
                <Avatar
                  firstName={alum.first_name}
                  lastName={alum.last_name}
                  email={alum.email}
                  location={alum.last_known_location}
                  size={AvatarSize.LARGE}
                  photoUrl={alum.profile_photo_url}
                  isSubscribed={true}
                  callToAction={
                    <Button
                      buttonProps={{
                        className: "send-message",
                        onClick: handleOpenSendMessageModal,
                        disableRipple: true,
                        sx: {
                          fontSize: "0.875rem",
                          boxShadow: "none",
                          padding: "0.625rem 1rem",
                          lineHeight: "1.25rem",
                          backgroundColor: theme.palette.primary.main,
                          "&:hover": {
                            backgroundColor: theme.palette.custom.purpleHover,
                            boxShadow: "none",
                          },
                          "&:active": {
                            backgroundColor: theme.palette.custom.purplePressed,
                            boxShadow: "none",
                          },
                        },
                      }}
                    >
                      {t("sendMessage")}
                    </Button>
                  }
                />
                <Divider />
                <Stack
                  id="overview-stats"
                  direction="row"
                  marginTop="0.25rem"
                  gap="0.5rem"
                >
                  <StatisticsCard
                    label={t("overviewStats.timeSinceDeparture")}
                    value={
                      timeSinceDeparture
                        ? t("overviewStats.yearsAndMonths", {
                            years: timeSinceDeparture.year,
                            months: timeSinceDeparture.month,
                          })
                        : t("overviewStats.unavailable")
                    }
                  />
                  <StatisticsCard
                    label={t("overviewStats.totalTenure")}
                    value={
                      totalTenure
                        ? t("overviewStats.yearsAndMonths", {
                            years: totalTenure.year,
                            months: totalTenure.month,
                          })
                        : t("overviewStats.unavailable")
                    }
                  />
                  <StatisticsCard
                    label={t("overviewStats.lastKnownSalary")}
                    value={annualSalary ?? t("overviewStats.unavailable")}
                  />
                </Stack>
              </Stack>
            </Box>
            <RoleHistoryTimelineV2 alum={alum} limitTo={ROLE_HISTORY_LIMIT} />
            <Education alum={alum} limitTo={EDUCATION_LIMIT} />
          </Stack>
          <Stack width="35%">
            <Box id="role-matches">
              <Carousel
                title={t("roleMatches.title")}
                items={alum.job_matches.map((jobMatch, idx) => (
                  <RoleCard
                    key={idx}
                    title={jobMatch.title}
                    department={jobMatch.department}
                    locations={jobMatch.locations}
                    recruiters={jobMatch.recruiters.map(
                      (recruiter) =>
                        `${recruiter.first_name} ${recruiter.last_name}`,
                    )}
                    datePosted={jobMatch.date_posted}
                    matchAttribute={jobMatch.notable_feature}
                    matchAttributeType={
                      jobMatch.notable_feature_tag as MatchAttributeType
                    }
                    status={jobMatch.status as JobMatchStatus}
                    statusModifiedDate={
                      jobMatch.status_modified_date
                        ? new Date(jobMatch.status_modified_date)
                        : null
                    }
                    emailsSent={jobMatch.emails_sent}
                    requisitionId={jobMatch.requisition_id}
                    onSendRole={() => handleOpenSendRoleModal(jobMatch)}
                    onClick={() => onRoleCardClick(jobMatch.job_id)}
                  />
                ))}
                arrowPlacement={ArrowPlacement.TOP_RIGHT}
                itemsPerSlide={1}
                itemWidth="100%"
                emptyState={
                  <EmptyList
                    icon={briefcaseIcon}
                    iconAlt={t("roleMatches.iconAlt")}
                    title={t("roleMatches.empty.title")}
                    message={t("roleMatches.empty.subtitle")}
                  />
                }
              />
            </Box>
            {hasCompanyContacts() && (
              <CompanyContacts
                alum={alum}
                limitTo={companyContactsDisplayLimit}
              />
            )}
            <Notes alum={alum} limitTo={notesDisplayLimit} />
          </Stack>
        </Stack>
      </Box>
      <CenterModal open={sendEmailModalOpen != null}>
        <SendEmailModal
          searchAllAlumni={false}
          onSubmit={handleSendEmail}
          onClose={handleCloseSendEmailModal}
          initialJob={
            sendEmailModalOpen === EmailTemplateTypeEnum.SEND_ROLE
              ? sendRoleJob
              : null
          }
          selectedIds={new Set([alum.id])}
          emailType={sendEmailModalOpen as EmailTemplateTypeEnum}
          receivers={[
            {
              id: alum.id,
              firstName: alum.first_name,
              lastName: alum.last_name,
              email: alum.email,
            },
          ]}
          isReceiversReadOnly={true}
        />
      </CenterModal>
    </Box>
  );
}
