import { http } from "./index.ts";
import { HttpError, HttpResponse, ResponseStatus } from "../models/api/http.ts";
import { NoteDTO } from "../models/api/alum.ts";

export const createNote = async (
  orgId: string,
  employeeId: string,
  content: string,
): Promise<NoteDTO> => {
  const response = await http.post<HttpResponse<NoteDTO>>(`/api/org/${orgId}/note/`, {
    employee_id: employeeId,
    content: content,
  });
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const deleteNote = async (
  orgId: string,
  noteId: string,
): Promise<void> => {
  const response = await http.delete<HttpResponse<void>>(`/api/org/${orgId}/note/${noteId}`);

  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
};