import theme from "../../theme";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { AlumDTO } from "../../models/api/alum";
import { Translation } from "../../lib/constants";
import Divider from "@mui/material/Divider";
import { formatUTCYear } from "../../lib/time";
import { FormattedText, FormattedTextType } from "../../theme/FormattedText";
import { Format, trackGoogleAnalyticsEvent } from "../../lib/utils";
import GradHatIcon from "/graduation-hat-01.svg";
import CenterModal from "../CenterModal";
import { useContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { AlumProfileEvent } from "../../lib/eventEnums.ts";
import { UserContext } from "../../lib/context.ts";
import EmptyList from "../EmptyList/index.tsx";

export default function Education({
  alum,
  limitTo,
}: {
  alum: AlumDTO;
  limitTo?: number;
}) {
  const { t } = useTranslation(Translation.alumProfile);
  const [user, _setUser] = useContext(UserContext);
  const [showFullHistory, setShowFullHistory] = useState(false);
  const educationHistory = alum.education_history.slice(0, limitTo);

  const handleSeeAllEducationClick = () => {
    setShowFullHistory(true);
    trackGoogleAnalyticsEvent({
      event: AlumProfileEvent.ALUM_PROFILE_EDUCATION_SEE_ALL,
      type: "alum_profile_see_all_education",
      org: user?.orgName,
    });
  };

  return (
    <Box id="education">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        pb="1.5rem"
      >
        <Typography fontWeight={600}>{t("education.title")}</Typography>
      </Stack>

      {alum.education_history.length === 0 ? (
        <EmptyList
          icon={GradHatIcon}
          iconAlt={t("roleHistory.orgLogoAlt")}
          title={t("education.empty.title")}
          message={t("education.empty.subtitle")}
        />
      ) : (
        <>
          <Stack width="100%">
            {educationHistory
              .filter((edu) => edu.school_name)
              .map((edu, index) => {
                return (
                  <>
                    <EducationRow key={index} education={edu} />

                    <Divider
                      sx={{
                        color: theme.palette.grey[200],
                        marginBottom: "20px",
                      }}
                    />
                  </>
                );
              })}
          </Stack>

          {limitTo && alum.education_history.length > limitTo && (
            <Typography
              color="inherit"
              fontSize="14px"
              fontWeight={600}
              lineHeight="20px"
              sx={{
                userSelect: "none",
                cursor: "pointer",
                color: theme.palette.primaryColor[600],
              }}
              className="see-all-education"
              onClick={() => handleSeeAllEducationClick()}
            >
              {t("education.showAll")}
            </Typography>
          )}

          <CenterModal open={showFullHistory}>
            <Box width="640px" minHeight="50vh">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                p="24px"
                borderBottom={`1px solid ${theme.palette.grey[200]}`}
                marginBottom="24px"
              >
                <Typography fontWeight={600}>{t("education.title")}</Typography>

                <CloseIcon
                  className="close-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowFullHistory(false)}
                />
              </Stack>

              <Stack width="100%" paddingX="24px" className="education-stack">
                {alum.education_history
                  .filter((edu) => edu.school_name)
                  .map((edu, index) => {
                    return (
                      <>
                        <EducationRow key={index} education={edu} />
                        <Divider
                          sx={{
                            color: theme.palette.grey[200],
                            marginBottom: "20px",
                          }}
                        />
                      </>
                    );
                  })}
              </Stack>
            </Box>
          </CenterModal>
        </>
      )}
    </Box>
  );
}

type EducationHistoryDTO = {
  school_name: string;
  degree: string | null;
  start_date: string | null;
  end_date: string | null;
  major: string | null;
  minor: string | null;
  logo_url?: string;
};

function EducationRow({ education }: { education: EducationHistoryDTO }) {
  const { t, i18n } = useTranslation(Translation.alumProfile);

  const startDate =
    education.start_date && formatUTCYear(education.start_date, i18n);
  const endDate = education.end_date && formatUTCYear(education.end_date, i18n);

  let dateString = "";
  if (startDate && endDate) {
    dateString = `${startDate} - ${endDate}`;
  } else if (startDate && !endDate) {
    dateString = `${startDate} - ${t("roleHistory.present")}`;
  } else if (!startDate && endDate) {
    dateString = `${t("roleHistory.unspecified")} - ${endDate}`;
  } else {
    dateString = t("roleHistory.unspecified");
  }

  return (
    <Stack direction="row" gap="1rem">
      <Stack width="2rem" gap="4px" alignItems="center">
        <Box
          padding="8px"
          borderRadius="50%"
          overflow="hidden"
          display="grid"
          sx={{
            placeItems: "center",
            backgroundColor: theme.palette.grey[100],
          }}
        >
          <Box
            component="img"
            alt={t("roleHistory.orgLogoAlt")}
            src={education.logo_url ?? GradHatIcon}
            width="24px"
            height="24px"
          />
        </Box>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginBottom="20px"
      >
        <Stack gap="0.25rem" width="60%">
          <FormattedText
            props={{
              value: education.school_name,
              format: Format.uppercaseFirstLetter,
              type: FormattedTextType.PRIMARY,
              ellipsis: false,
            }}
            style={{ style: { overflowWrap: "break-word" }, fontWeight: 600 }}
          />
          <FormattedText
            props={{
              value: `${education.degree ?? ""}${
                education.degree && education.major ? ", " : ""
              }${education.major ?? ""}`,
              format: Format.uppercaseFirstLetter,
              type: FormattedTextType.PRIMARY,
              ellipsis: false,
            }}
            style={{ style: { overflowWrap: "break-word" } }}
          />
        </Stack>

        <Stack justifyContent="space-between" alignItems="end" height="100%">
          <FormattedText
            props={{
              value: dateString,
              type: FormattedTextType.SECONDARY,
            }}
            style={{
              textAlign: "right",
            }}
          />

          <Stack
            direction="row"
            gap="4px"
            alignItems="center"
            paddingX="6px"
            sx={{
              backgroundColor: theme.palette.grey[50],
              borderRadius: "16px",
              padding: "0.125rem 0.375rem",
              width: "max-content",
              marginTop: "0.25rem",
            }}
          >
            <Typography
              color={theme.palette.grey[800]}
              fontSize="0.75rem"
              lineHeight="1.125rem"
            >
              {t("education.external")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
