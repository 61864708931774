import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import sortIcon from "/switch-vertical.svg";
import Card from "@mui/material/Card";
import Radio from "@mui/material/Radio";
import CheckIcon from "@mui/icons-material/Check";
import { Translation } from "../../lib/constants";

type TrackAnalyticsEventFunction = {
  (option: string): void;
};

interface SortProps {
  options: string[];
  selectedOption: string;
  dropdownWidth?: string;
  onSortChange: (option: string) => void;
  trackAnalyticsEvent?: TrackAnalyticsEventFunction;
}

export default function Sort(props: SortProps) {
  const { t } = useTranslation(Translation.common);
  const [isExpanded, setIsExpanded] = useState(false);
  const sortRef = useRef<HTMLDivElement>(null);

  const handleExpandSortBar = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const handleSelectSortOption = (option: string) => {
    if (props.trackAnalyticsEvent) {
      props.trackAnalyticsEvent(option);
    }
    props.onSortChange(option);
    handleExpandSortBar();
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (sortRef.current && !sortRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sortRef]);

  return (
    <Box position={"relative"} ref={sortRef}>
      <Card
        id="sort-bar"
        onClick={handleExpandSortBar}
        style={{ cursor: "pointer", border: "none", boxShadow: "none" }}
      >
        <Stack
          height="40px"
          direction="row"
          gap="0rem"
          alignItems="center"
          padding="0.625rem 1rem"
          border={(theme) => `1px solid ${theme.palette.grey[300]}`}
          borderRadius="0.5rem"
          sx={(theme) => ({
            cursor: "pointer",
            background: isExpanded
              ? theme.palette.grey[50]
              : theme.palette.background.default,
            "&:hover": {
              backgroundColor: theme.palette.grey[50],
            },
          })}
        >
          <Box
            component="img"
            alt={t("filterIconAlt")}
            src={sortIcon}
            width="1.25rem"
            height="1.25rem"
            sx={{
              marginRight: "0.5rem",
              userSelect: "none",
            }}
          />
          <Typography
            fontSize="0.875rem"
            fontWeight={500}
            color={(theme) => theme.palette.grey[isExpanded ? 800 : 600]}
            sx={{
              marginRight: "0.25rem",
              userSelect: "none",
            }}
          >
            {t("sort")}
          </Typography>
          <Typography
            fontSize="0.875rem"
            fontWeight={400}
            color={(theme) => theme.palette.grey[isExpanded ? 800 : 600]}
            sx={{
              userSelect: "none",
            }}
          >
            {props.selectedOption}
          </Typography>
        </Stack>
      </Card>

      {isExpanded && (
        <div>
          <Stack
            height="max-content"
            direction="column"
            border={(theme) => `1px solid ${theme.palette.grey[200]}`}
            borderRadius="0.5rem"
            sx={(theme) => ({
              cursor: "pointer",
              position: "absolute",
              backgroundColor: theme.palette.common.white,
              zIndex: 10,
              marginTop: "0.25rem",
              boxShadow: `0px 12px 9px -4px ${theme.palette.grey[200]}`,
            })}
          >
            {props.options.map((option, index) => {
              return (
                <Stack
                  key={index}
                  width={props.dropdownWidth ?? "13rem"}
                  padding="0.25rem 0.25rem"
                  id={"sort-option-row-index-" + index.toString()}
                  onClick={() => handleSelectSortOption(option)}
                  sx={(theme) => ({
                    borderBottom:
                      index != props.options.length - 1 && index % 2 === 1
                        ? `1px solid ${theme.palette.grey[200]}`
                        : "",
                  })}
                >
                  <Stack
                    height="2.5rem"
                    direction="row"
                    alignItems="center"
                    borderRadius="0.5rem"
                    padding="0.5rem 0rem 0.5rem 0.75rem"
                    sx={(theme) => ({
                      "&:hover": {
                        backgroundColor: `${theme.palette.grey[50]}`,
                      },
                    })}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize="0.875rem"
                      color={(theme) => theme.palette.grey[800]}
                    >
                      {option}
                    </Typography>
                    <Radio
                      checked={props.selectedOption === option}
                      icon={<div />}
                      checkedIcon={<CheckIcon />}
                      size="small"
                      sx={{
                        marginLeft: "auto",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </div>
      )}
    </Box>
  );
}
