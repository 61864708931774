import Input, { InputProps } from "./Input";
import { Box, Button, Chip, Stack } from "@mui/material";
import IconX from "@mui/icons-material/ClearRounded";
import theme from ".";
import { useTranslation } from "react-i18next";
import { Translation } from "../lib/constants";
import { useState } from "react";
import xClose from "/x-close.svg";

interface MultiChipInputProps {
  id?: string;
  inputProps: InputProps;
  chipValues: string[];
  setChipValues: (arg: string[]) => void;
  tooltipText?: string;
  onAlreadyAdded?: () => void;
}

export default function MultiChipInput({
  id,
  inputProps,
  chipValues,
  setChipValues,
  tooltipText,
  onAlreadyAdded,
}: MultiChipInputProps) {
  const { t } = useTranslation(Translation.common);
  const [inputValue, setInputValue] = useState<string>("");

  const addValue = () => {
    if (
      !chipValues.some(
        (value) => value.toLowerCase() === inputValue.trim().toLowerCase(),
      )
    ) {
      setChipValues([...chipValues, inputValue.trim()]);
    } else {
      onAlreadyAdded && onAlreadyAdded();
    }
    setInputValue("");
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      addValue();
    }
  };

  const addButton = (
    <Button
      disabled={!inputValue}
      sx={{
        color: theme.palette.primary.main,
        px: "1rem",
        ml: 0.8,
        borderRadius: "0.5rem",
        fontSize: "0.875rem",
        height: "100%",
        "&:disabled": {
          opacity: 0.4,
          color: theme.palette.primary.dark,
        },
      }}
      onClick={addValue}
      startIcon={<IconX sx={{ transform: "rotate(45deg) scale(0.9)" }} />}
    >
      {t("add")}
    </Button>
  );

  const chipDeleteButton = (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        p="0.1rem"
        borderRadius="0.2rem"
        sx={{
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            bgcolor: theme.palette.grey[100],
          },
        }}
      >
        <Box component="img" src={xClose} />
      </Box>
    </Box>
  );

  return (
    <Stack id={id}>
      <Input
        {...inputProps}
        inputProps={{ value: inputValue, onKeyDown: handleKeyDown }}
        onChange={(event) => setInputValue(event.target.value)}
        rightSideComponent={addButton}
        tooltipText={tooltipText}
      />
      <Stack
        display="flex"
        direction="row"
        flexWrap="wrap"
        className="multi-chip-values"
      >
        {chipValues.map((value, index) => (
          <Chip
            key={index}
            onDelete={() =>
              setChipValues(chipValues.filter((chip) => chip !== value))
            }
            size="small"
            sx={{
              marginTop: "0.375rem",
              marginRight: "0.375rem",
              borderRadius: "0.4rem",
              p: "0.8rem 0.2rem",
              borderColor: theme.palette.grey[300],
              color: theme.palette.grey[800],
            }}
            deleteIcon={chipDeleteButton}
            label={value}
            variant="outlined"
          />
        ))}
      </Stack>
    </Stack>
  );
}
