import React, { useState, useCallback, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import searchIcon from "/search.svg";
import Box from "@mui/material/Box";
import debounce from "lodash/debounce";
import {
  getJobAutocompleteSuggestions,
  autocompleteAlumniMatchesSearch,
} from "../../api/job";
import {
  ALUMNI_DATABASE_SEARCH_SUGGESTIONS_LIMIT,
  DEBOUNCE_DELAY,
} from "../../lib/utils";
import { Translation } from "../../lib/constants";
import { NameDTO, SearchbarContextType } from "../../models/api/common";
import { autocompleteAlumniDatabaseSearch } from "../../api/alum";
import { JobFilterOptions } from "../../models/filter";
import { UserContext } from "../../lib/context";

interface SearchBarProps {
  placeholder: string;
  onSearch: (searchTerm: string) => void;
  context: SearchbarContextType;
  filters?: JobFilterOptions;
  jobId?: string;
  searchbarWidth?: string;
  searchTerm?: string;
  disabled?: boolean;
}

export async function getSuggestions(
  orgId: string,
  searchTerm: string,
  context: SearchbarContextType,
  filters?: JobFilterOptions,
  jobId?: string,
): Promise<string[]> {
  if (!searchTerm.trim().length) return [];

  try {
    if (context === SearchbarContextType.ROLES_VIEW && jobId) {
      const data = await autocompleteAlumniMatchesSearch(orgId, jobId, searchTerm);
      return data.map(
        (item: NameDTO) => `${item.first_name} ${item.last_name}`,
      );
    } else if (context === SearchbarContextType.ROLES_DATABASE) {
      if (!filters)
        throw new Error("Filters are required for ROLES_DATABASE search.");
      const data = await getJobAutocompleteSuggestions(orgId, searchTerm, filters);
      return data.suggestions;
    } else if (context === SearchbarContextType.ALUMNI_DATABASE) {
      const data = await autocompleteAlumniDatabaseSearch(
        orgId,
        searchTerm,
        ALUMNI_DATABASE_SEARCH_SUGGESTIONS_LIMIT,
      );
      return data.suggestions;
    }
  } catch (error) {
    console.error("Error fetching autocomplete suggestions:", error);
    return [];
  }
  return [];
}

export default function SearchBar({
  placeholder,
  onSearch,
  context,
  filters,
  jobId,
  searchbarWidth,
  searchTerm = "",
  disabled = false,
}: SearchBarProps) {
  const { t } = useTranslation(Translation.common);
  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";
  const [inputValue, setInputValue] = useState(searchTerm);
  const [suggestions, setSuggestions] = React.useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);

  const debouncedGetSuggestions = useCallback(
    debounce(async (searchTerm) => {
      const suggestions = await getSuggestions(
        orgId,
        searchTerm,
        context as SearchbarContextType,
        filters,
        jobId,
      );
      setSuggestions(suggestions);
      setShowSuggestions(suggestions.length > 0);
    }, DEBOUNCE_DELAY),
    [context, jobId, filters],
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  useEffect(() => {
    setInputValue(searchTerm);
  }, [searchTerm]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedGetSuggestions(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch(inputValue);
    }
  };

  const handleSelectSuggestion = (suggestion: string) => {
    setInputValue(suggestion);
    setShowSuggestions(false);
    onSearch(suggestion);
  };

  return (
    <Box ref={searchRef} sx={{ position: "relative", width: null }}>
      <TextField
        placeholder={placeholder}
        value={inputValue}
        disabled={disabled}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box
                component="img"
                alt={t("searchIconAlt")}
                src={searchIcon}
                width="1rem"
                height="40px"
              />
            </InputAdornment>
          ),
        }}
        sx={(theme) => ({
          width: searchbarWidth ?? "19rem",
          height: "2.5rem",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: theme.palette.grey[300],
            },
            "&:hover fieldset": {
              borderColor: theme.palette.grey[300],
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.primary.main,
            },
          },
          "& .MuiInputBase-root": {
            paddingLeft: "0.56rem",
            borderRadius: "0.5rem",
          },
          "& .MuiInputBase-input": {
            height: "1.5rem",
            padding: "0.5rem 0.56rem 0.5rem 0",
            fontSize: "0.875rem",
          },
          "& .MuiInputBase-input::placeholder": {
            color: theme.palette.grey[500],
            fontWeight: 400,
            fontSize: "0.875rem",
          },
        })}
      />
      {showSuggestions && (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            zIndex: 10,
            maxHeight: "300px",
            overflowY: "auto",
            overflowWrap: "hidden",
            backgroundColor: "#FFFFFF",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            mt: 0.5,
            padding: "8px",
            borderRadius: "10px",
            "&::-webkit-scrollbar": {
              width: "12px",
              margin: "5px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#EAECF0",
              borderRadius: "10px",
              border: "3px solid transparent",
              backgroundClip: "content-box",
            },
            "& .suggestion-item": {
              padding: "10px 15px",
              "&:hover": {
                backgroundColor: "#F9FAFB",
                cursor: "pointer",
                borderRadius: "6px",
              },
            },
          }}
        >
          {suggestions.map((suggestion, index) => (
            <Box
              key={index}
              className="suggestion-item"
              onClick={() => handleSelectSuggestion(suggestion)}
              sx={{
                typography: "body2",
                fontSize: "0.875rem",
                color: "text.primary",
                padding: "10px 15px",
                fontWeight: "regular",
              }}
            >
              {suggestion
                .split(new RegExp(`(${inputValue})`, "i"))
                .map((part, index) =>
                  part.toLowerCase() === inputValue.toLowerCase() ? (
                    <span key={index} style={{ fontWeight: "500" }}>
                      {part}
                    </span>
                  ) : (
                    part
                  ),
                )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
