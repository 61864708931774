import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import { AlumDTO, getEmploymentTypeDisplay } from "../../models/api/alum";
import Table, { TableColumn } from "../../components/Table";
import { Box, Stack, Typography } from "@mui/material";
import Checkbox, { CheckedState } from "../../components/Checkbox";
import Avatar, { AvatarSize } from "../../theme/Avatar";
import {
  Format,
  formatAnnuPay,
  formatPayRate,
  MAX_SELECTED_ALUMNI,
  trackGoogleAnalyticsEvent,
} from "../../lib/utils";
import { PayPeriodEnum } from "../../models/api/job";
import Tooltip from "../../theme/Tooltip";
import { useContext } from "react";
import { FlagsContext, UserConfigContext } from "../../lib/context";
import { useNavigate } from "react-router-dom";
import { FormattedText, FormattedTextType } from "../../theme/FormattedText";
import { Receiver } from "../../components/SendEmailModal";
import { AlumDBEvent } from "../../lib/eventEnums";

interface AlumniDatabaseTableProps {
  alumni: AlumDTO[];
  page: number;
  pageSize: number;
  totalRows: number;
  onPageChange: (page: number) => void;
  selectedAlumni: Map<string, Receiver>;
  setSelectedAlumni: (selectedAlumni: Map<string, Receiver>) => void;
}

export default function AlumniDatabaseTable({
  alumni,
  page,
  pageSize,
  totalRows,
  onPageChange,
  selectedAlumni,
  setSelectedAlumni,
}: AlumniDatabaseTableProps) {
  const { t, i18n } = useTranslation(Translation.alumniDatabase);
  const common = useTranslation(Translation.common)[0];
  const navigate = useNavigate();

  const [userConfig, _setUserConfig] = useContext(UserConfigContext);

  const orgName = alumni.length > 0 ? alumni[0].org_name : "";

  const someChecked = selectedAlumni.size > 0;
  const maxChecked = selectedAlumni.size === MAX_SELECTED_ALUMNI;
  const noneChecked = selectedAlumni.size === 0;

  // Check to see if we want to show last Known Role and other post-departure related fields.
  const [flags] = useContext(FlagsContext);
  const showPostDepartureDataRelatedFields = () => {
    return flags?.includes("SHOW_POST_DEPARTURE_DATA") ?? false;
  };

  // If PDD is enabled but the current role is the same as the last role at the org,
  // and the current company is the same as the current org, we should render no text for
  // both the last role at org and the current company.
  const renderCorrectCurrentJobTitleText = (alum: AlumDTO) => {
    if (
      alum.last_job_title_at_org === alum.current_job_title &&
      alum.current_company === orgName
    ) {
      return "";
    }
    return alum.current_job_title;
  };
  const renderCorrectCurrentCompanyText = (alum: AlumDTO) => {
    if (
      alum.last_job_title_at_org === alum.current_job_title &&
      alum.current_company === orgName
    ) {
      return "";
    }
    return alum.current_company;
  };

  const caculateMinTableWidth = () => {
    let baseMinWidth = 110.8375;
    if (userConfig?.hasAlumHiringPreferencesData()) baseMinWidth += 18.25;
    if (userConfig?.hasSalaryData()) baseMinWidth += 10.375;
    if (showPostDepartureDataRelatedFields()) baseMinWidth += 27.1875;
    return baseMinWidth.toString() + "rem";
  };

  const minTableWidth = caculateMinTableWidth();

  if (alumni.length > 0) {
    alumni[0].would_rehire = false;
  }

  const columns: TableColumn<AlumDTO>[] = [
    {
      id: "selection",
      label: () => (
        <Stack height="1.5rem" justifyContent="center">
          <Tooltip title={noneChecked ? t("tooltip.cannotSelectAll") : ""}>
            <div>
              <Checkbox
                id="checkbox"
                checked={
                  maxChecked || someChecked
                    ? CheckedState.MIXED
                    : CheckedState.UNCHECKED
                }
                onCheck={onSelectAll}
                disabled={noneChecked}
              />
            </div>
          </Tooltip>
        </Stack>
      ),
      content: (alum: AlumDTO) => (
        <Stack height="1.5rem" justifyContent="center">
          <Tooltip
            title={
              maxChecked && !selectedAlumni.has(alum.id)
                ? t("tooltip.maxSelectionsReached")
                : ""
            }
          >
            <div>
              <Checkbox
                id="checkbox"
                checked={
                  selectedAlumni.has(alum.id)
                    ? CheckedState.CHECKED
                    : CheckedState.UNCHECKED
                }
                onCheck={() => {
                  if (alum.is_subscribed) {
                    onSelectRow(alum);
                  }
                }}
                disabled={
                  !alum.is_subscribed ||
                  (!selectedAlumni.has(alum.id) && maxChecked)
                }
              />
            </div>
          </Tooltip>
        </Stack>
      ),
      styles: {
        width: "3%",
        minWidth: "4.25rem",
        position: "sticky",
        left: 0,
        background: "inherit",
        zIndex: 1,
      },
    },
    {
      id: "name",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.name.label")}
        </Typography>
      ),
      content: (alum: AlumDTO) => (
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={() => onRowClick(alum)}
        >
          <Avatar
            firstName={alum.first_name}
            lastName={alum.last_name}
            email={alum.email}
            size={AvatarSize.MEDIUM}
            photoUrl={alum.profile_photo_url}
            isSubscribed={alum.is_subscribed}
          />
        </Box>
      ),
      styles: {
        width: "18.25%",
        minWidth: "22.125rem",
        position: "sticky",
        left: "4.25rem",
        zIndex: 1,
        background: "inherit",
      },
    },
    {
      id: "formerRole",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.formerRole.label", { org: orgName })}
        </Typography>
      ),
      content: (alum: AlumDTO) => {
        return (
          <Stack>
            <FormattedText
              props={{
                value: alum.last_job_title_at_org,
                format: Format.uppercaseFirstLetter,
              }}
            />
            <FormattedText
              props={{
                value: alum.last_job_department_at_org,
                format: Format.uppercaseFirstLetter,
                type: FormattedTextType.SECONDARY,
              }}
            />
          </Stack>
        );
      },
      styles: {
        width: "17.86%",
        minWidth: "21.65rem",
      },
    },
    {
      id: "formerEmploymentType",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.formerEmploymentType.label")}
        </Typography>
      ),
      content: (alum: AlumDTO) => (
        <FormattedText
          props={{
            value: getEmploymentTypeDisplay(
              alum.former_employment_type,
              common,
            ),
          }}
        />
      ),
      styles: {
        width: "12.4%",
        minWidth: "15rem",
      },
    },
    ...(userConfig?.hasSalaryData()
      ? [
          {
            id: "formerSalary",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.formerSalary.label")}
              </Typography>
            ),
            content: (alum: AlumDTO) => {
              var payRate = alum.former_salary;
              var payPeriod = alum.former_salary_pay_period;

              if (payRate === 0 || payPeriod == null) return <></>;

              return (
                <Stack>
                  <FormattedText
                    props={{
                      value: formatPayRate(
                        payRate,
                        payPeriod,
                        common,
                        i18n.language,
                      ),
                    }}
                    style={{
                      align: "right",
                    }}
                  />
                  {payPeriod !== PayPeriodEnum.YEAR && (
                    <FormattedText
                      props={{
                        value: formatAnnuPay(
                          payRate,
                          payPeriod,
                          common,
                          i18n.language,
                        ),
                        type: FormattedTextType.SECONDARY,
                      }}
                      style={{
                        align: "right",
                      }}
                    />
                  )}
                </Stack>
              );
            },
            styles: {
              width: "8.6%",
              minWidth: "10.375rem",
            },
          },
        ]
      : []),
    ...(showPostDepartureDataRelatedFields()
      ? [
          {
            id: "lastKnownRole",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.lastKnownRole.label")}
              </Typography>
            ),
            content: (alum: AlumDTO) => {
              const lastJobTitle = renderCorrectCurrentJobTitleText(alum);
              const lastCompany = renderCorrectCurrentCompanyText(alum);

              return (
                <Stack>
                  <FormattedText
                    props={{
                      value: lastJobTitle,
                      format: Format.uppercaseFirstLetter,
                    }}
                  />
                  <FormattedText
                    props={{
                      value: lastCompany,
                      format: Format.uppercaseFirstLetter,
                      type: FormattedTextType.SECONDARY,
                    }}
                  />
                </Stack>
              );
            },
            styles: {
              width: "12.4%",
              minWidth: "15rem",
            },
          },
          {
            id: "lastKnownLocation",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.lastKnownLocation.label")}
              </Typography>
            ),
            content: (alum: AlumDTO) => (
              <FormattedText
                props={{
                  value: alum.last_known_location,
                  format: Format.location,
                }}
              />
            ),
            styles: {
              width: "10%",
              minWidth: "12.1875rem",
            },
          },
        ]
      : []),
    {
      id: "departureDate",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
          align="right"
        >
          {t("table.departureDate.label")}
        </Typography>
      ),
      content: (alum: AlumDTO) => (
        <FormattedText
          props={{
            value: alum.end_date_at_org,
            format: Format.dateMonthYear,
          }}
          style={{
            align: "right",
          }}
        />
      ),
      styles: {
        width: "8%",
        minWidth: "9.6875rem",
      },
    },
    {
      id: "lastTimeContacted",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.lastTimeContacted.label")}
        </Typography>
      ),
      content: (alum: AlumDTO) => (
        <FormattedText
          props={{
            value: alum.last_time_contacted,
            format: Format.dateDayMonthYear,
          }}
          style={{
            align: "right",
          }}
        />
      ),
      styles: {
        width: "9%",
        minWidth: "10.9375rem",
      },
    },
    ...(userConfig?.hasAlumHiringPreferencesData()
      ? [
          {
            id: "wouldRehire",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.wouldRehire.label")}
              </Typography>
            ),
            content: (alum: AlumDTO) => (
              <FormattedText
                props={{
                  value: alum.would_rehire
                    ? t("table.wouldRehire.yes")
                    : alum.would_rehire == null
                    ? ""
                    : t("table.wouldRehire.no"),
                }}
              />
            ),
            styles: {
              width: "6.4%",
              minWidth: "7.75rem",
            },
          },
          {
            id: "reasonForDismissal",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.reasonForDismissal.label")}
              </Typography>
            ),
            content: (alum: AlumDTO) => (
              <FormattedText
                props={{
                  value: alum.reason_for_dismissal,
                  format: Format.capitalize,
                }}
              />
            ),
            styles: {
              width: "8.7%",
              minWidth: "10.5rem",
            },
          },
        ]
      : []),
  ];

  const onRowClick = (alum: AlumDTO) => {
    trackGoogleAnalyticsEvent({
      event: AlumDBEvent.ALUM_DB_ALUM_CLICK,
      org: orgName,
      customParameters: {
        name: alum.first_name + " " + alum.last_name,
        id: alum.id,
      },
    });
    navigate(alum.id);
  };

  const onSelectRow = (alum: AlumDTO) => {
    const newSelectedAlumni = new Map(selectedAlumni);
    if (newSelectedAlumni.has(alum.id)) {
      newSelectedAlumni.delete(alum.id);
    } else {
      const newReceiver: Receiver = {
        id: alum.id,
        firstName: alum.first_name,
        lastName: alum.last_name,
        email: alum.email,
      };
      newSelectedAlumni.set(alum.id, newReceiver);
    }
    setSelectedAlumni(newSelectedAlumni);
  };

  const onSelectAll = () => {
    if (someChecked) {
      setSelectedAlumni(new Map());
    }
  };

  return (
    <Table
      columns={columns}
      rows={alumni}
      page={page}
      pageSize={pageSize}
      totalRows={totalRows}
      onPageChange={onPageChange}
      minWidth={minTableWidth}
      categoryPlural={t("paginationFooter")}
      categorySingular={t("paginationFooter")}
      emptyResultText={t("emptyResult")}
      maxPagesToShow={7}
    />
  );
}
