import Autocomplete, {
  AutocompleteRenderGetTagProps,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Checkbox, { CheckedState } from "../Checkbox";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import theme from "../../theme";
import DeleteIcon from "/delete-icon.svg";
import ChevronDownIcon from "/chevron-down.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import { FormattedText } from "../../theme/FormattedText";
import { Box } from "@mui/material";

const SELECT_ALL_ID = "selectAll";

export type MultiSelectOption = {
  value: string;
  label: string;
  dropdownLabelDetails?: string;
  selectable: Boolean;
};

interface MultiSelectComboBoxProps {
  id?: string;
  options: MultiSelectOption[];
  selectedValues: Set<string>;
  setSelectedValues: (ids: Set<string>) => void;
  selectAllLabel?: string;
  handleSelectAll?: (arg: boolean) => void;
  inputErrorMessage?: string;
  showChevron?: boolean;
  placeholder?: string;
  getInputValue?: (inputValue: string) => void;
  readOnly?: boolean;
  isDropdownOpen?: boolean;
  matchFromStartFilter?: boolean;
  maxSelectedValues?: number;
  format?: (value: string) => string;
  disabled?: boolean;
}

export default function MultiSelectComboBox(props: MultiSelectComboBoxProps) {
  const {
    id,
    options,
    selectedValues,
    setSelectedValues,
    selectAllLabel,
    inputErrorMessage,
    showChevron = false,
    placeholder,
    getInputValue,
    readOnly = false,
    isDropdownOpen,
    matchFromStartFilter = false,
    maxSelectedValues,
    format,
    disabled,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(Translation.common);

  const selectableOptions = options.filter((option) => option.selectable);
  const allValidSelected = selectedValues.size === selectableOptions.length;
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<MultiSelectOption[]>(
    [],
  );
  
  const selectAllOption: MultiSelectOption = {
    value: SELECT_ALL_ID,
    label: selectAllLabel ?? "",
    selectable: selectableOptions.length > 0,
  };

  useEffect(() => {
    setSelectedOptions(
      options.filter((option) => selectedValues.has(option.value)),
    );
  }, [selectedValues]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setSearchValue("")
        if (getInputValue) getInputValue("");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef, getInputValue]);

  const filterOption = createFilterOptions<MultiSelectOption>({
    matchFrom: matchFromStartFilter ? "start" : undefined,
    stringify: (option) => option.label,
  });

  const checkOrUncheckAllValid = () => {
    if (allValidSelected) {
      setSelectedValues(new Set());
    } else {
      setSelectedValues(
        new Set(selectableOptions.map((option) => option.value)),
      );
    }
  };

  const renderTagsForWidth = (
    options: MultiSelectOption[],
    getTagProps: AutocompleteRenderGetTagProps,
  ) => {
    const width = ref.current?.offsetWidth;
    // Assumed default width is 764px from Figma design
    const maxWidth = (width ?? 764) * 0.75;
    let index = 0;
    let totalWidth = 14; // 14px is the left padding
    let tags = [];

    while (index < options.length) {
      // 7px per character and 26px for padding, space, and margin
      let newTagWidth = options[index].label.length * 7 + 26;
      if (totalWidth + newTagWidth < maxWidth) {
        tags.push(
          <Chip
            {...getTagProps({ index })}
            variant="outlined"
            sx={{
              borderRadius: ".5rem",
              borderColor: theme.palette.grey[300],
              "& .MuiChip-label": {
                padding: "0rem",
                marginRight: "0.1875rem",
              },
              "& .MuiChip-deleteIcon": {
                margin: "0rem",
              },
            }}
            style={{
              color: theme.palette.grey[800],
              height: "1.5rem",
              fontSize: "0.875rem",
              fontWeight: "400",
              lineHeight: "1.25rem",
              paddingLeft: "0.5625rem",
              paddingRight: "0.25rem",
              paddingTop: "0.125rem",
              paddingBottom: "0.125rem",
            }}
            label={format ? format(options[index].label) : options[index].label}
            deleteIcon={<img src={DeleteIcon} />}
          />,
        );
        index++;
        totalWidth += newTagWidth;
      } else {
        tags.push(
          <Chip
            key={index}
            variant="outlined"
            sx={{
              borderRadius: ".5rem",
              borderColor: theme.palette.grey[300],
            }}
            style={{
              color: theme.palette.grey[800],
              fontSize: "0.875rem",
              fontWeight: "400",
              lineHeight: "1.25rem",
              padding: "0.5rem 0.125rem",
              marginRight: "0.38rem",
            }}
            label={`+${options.length - index}`}
            deleteIcon={<img src={DeleteIcon} />}
          />,
        );
        return tags;
      }
    }

    return tags;
  };

  return (
    <Stack spacing={0.5} sx={{ width: "100%" }} ref={searchRef}>
      <Autocomplete
        id={id}
        className="multi-select-combo-box"
        readOnly={readOnly}
        multiple
        fullWidth
        disableCloseOnSelect
        options={options}
        disableClearable={true}
        open={isDropdownOpen}
        popupIcon={
          showChevron && (
            <Box
              component="img"
              src={ChevronDownIcon}
              style={{ marginTop: "3px" }}
            />
          )
        }
        getOptionKey={(option) => option.value}
        getOptionDisabled={(option) =>
          maxSelectedValues && selectedValues.size >= maxSelectedValues
            ? !selectedValues.has(option.value)
            : !option.selectable
        }
        value={selectedOptions}
        inputValue={inputValue}
        onInputChange={(_event, value) => {
          setInputValue(value);
          if (value !== "") {
            setSearchValue(value)
            if (getInputValue) getInputValue(value);
          } 
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(_event, value) => {
          if (
            selectAllLabel &&
            value.find((option) => option.value === SELECT_ALL_ID)
          ) {
            checkOrUncheckAllValid();
          } else {
            setSelectedValues(new Set(value.map((option) => option.value)));
          }
        }}
        sx={{
          ".MuiAutocomplete-inputRoot": {
            flexWrap: " nowrap !important;",
          },
          "& .MuiInputBase-root": {
            height: "2.5rem",
            padding: "0.625rem 0.875rem",
            borderRadius: "0.5rem",
            backgroundColor: disabled
              ? theme.palette.custom.greyBackground
              : theme.palette.common.white,
          },
        }}
        renderTags={(value, getTagProps) => {
          return (
            <>
              {!readOnly && (
                <Stack direction="row" alignItems="center" spacing="6px">
                  {renderTagsForWidth(value, getTagProps)}
                </Stack>
              )}
            </>
          );
        }}
        filterOptions={(options, params) => {
          const modifiedParams = { ...params, inputValue: searchValue };
          const filtered = filterOption(options, modifiedParams);
          if (searchValue.length === 0 && selectAllLabel)
            return [selectAllOption, ...filtered];
          return filtered;
        }}
        renderOption={(props, option, { selected }) => (
          <Stack ref={searchRef}>
            <li
              key={JSON.stringify(option.value)}
              {...props}
              style={{ background: "transparent" }}
            >
              {option.value === SELECT_ALL_ID && (
                <Stack direction="row" alignItems="center" spacing="0.5rem">
                  <Checkbox
                    checked={
                      allValidSelected && option.selectable
                        ? CheckedState.CHECKED
                        : CheckedState.UNCHECKED
                    }
                    onCheck={() => {
                      checkOrUncheckAllValid;
                    }}
                    disabled={!option.selectable}
                  />
                  <Typography
                    fontSize="0.875rem"
                    fontWeight={400}
                    lineHeight="1.25rem"
                  >
                    {selectAllLabel}
                  </Typography>
                </Stack>
              )}
              {option.value != SELECT_ALL_ID && (
                <Stack direction="row" alignItems="center" spacing="0.5rem">
                  <Checkbox
                    checked={
                      selected ? CheckedState.CHECKED : CheckedState.UNCHECKED
                    }
                    onCheck={() => {}}
                    disabled={!option.selectable}
                  />
                  <FormattedText
                    props={{
                      value: option.label,
                      format: format,
                      ellipsis: false,
                    }}
                  />
                  {option.dropdownLabelDetails && (
                    <Typography
                      fontSize="0.875rem"
                      fontWeight={400}
                      lineHeight="1.25rem"
                      color={theme.palette.grey[500]}
                    >
                      {option.dropdownLabelDetails}
                    </Typography>
                  )}
                </Stack>
              )}
            </li>
          </Stack>
        )}
        renderInput={(params) => (
          <TextField
            placeholder={
              readOnly
                ? selectedOptions.map((option) => option.label).join(", ")
                : placeholder
            }
            error={Boolean(inputErrorMessage) && selectedValues.size === 0}
            ref={ref}
            rows={1}
            className="multi-select-combo-box-text"
            {...params}
            sx={{
              fieldset: {
                borderColor: theme.palette.grey[300] + " !important",
              },
              "& .Mui-focused": {
                fieldset: {
                  borderColor: readOnly
                    ? undefined
                    : theme.palette.primary.dark + " !important",
                  borderWidth: readOnly ? "1px !important" : undefined,
                },
              },
              "& .Mui-error": {
                fieldset: {
                  borderColor: theme.palette.custom.errorBorder + " !important",
                },
              },
              "& input::placeholder": {
                fontSize: "0.875rem",
              },
            }}
          />
        )}
        disabled={disabled}
      />
      {inputErrorMessage && selectedValues.size === 0 && (
        <Typography fontSize="0.875rem" color="error.dark">
          {inputErrorMessage}
        </Typography>
      )}
      {maxSelectedValues && maxSelectedValues < selectedValues.size && (
        <Typography
          fontSize="0.875rem"
          color="error.dark"
          className="max-selected-error-message"
        >
          {`${t("error.maxSelected")} ${maxSelectedValues}.`}
        </Typography>
      )}
    </Stack>
  );
}
