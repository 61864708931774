import { Stack, Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import CenterModal from "../CenterModal";
import Button from "../../theme/Button";
import Input from "../../theme/Input";
import closeSvg from "/x-close.svg";
import theme from "../../theme";
import React from "react";
import { SvgButton } from "../../theme/SvgButton.tsx";

interface AddNoteModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (text: string) => Promise<void>;
  value: string;
  onChange: (value: string) => void;
  characterLimit: number;
}

export const AddNoteModal = ({
  open,
  onClose,
  onSave,
  value,
  onChange,
  characterLimit,
}: AddNoteModalProps) => {
  const { t } = useTranslation(Translation.alumProfile);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= characterLimit) {
      onChange(newValue);
    } else {
      onChange(newValue.slice(0, characterLimit));
    }
  };

  return (
    <CenterModal
      open={open}
      onClose={onClose}
      sx={{
        width: "640px",
        py: "1.5rem",
        maxHeight: "94vh",
        outline: "none",
        overflow: "auto",
      }}
    >
      <Stack id="add-note-modal" gap="1rem">
        <Stack direction="row" px="1.5rem">
          <Typography fontWeight={600} fontSize="1.25rem" flexGrow={1}>
            {t("notes.addNoteModal.title")}
          </Typography>
          <SvgButton
            id="close-add-note-modal-button"
            src={closeSvg}
            onClick={onClose}
          />
        </Stack>
        <Divider />
        <Stack gap="0.3rem" px="1.5rem">
          <Input
            id="note-input"
            error={""}
            placeholder={t("notes.addNoteModal.placeholder")}
            onChange={handleChange}
            stackProps={{ paddingTop: "1.125rem" }}
            inputProps={{
              id: "note-input-text",
              name: "note",
              multiline: true,
              value: value,
              rows: 10.75,
              sx: {
                borderRadius: "0.5rem",
                "& .MuiOutlinedInput-input": {
                  fontSize: "0.875rem",
                  lineHeight: "1.5rem",
                  fontWeight: "400",
                  color: "grey.800",
                },
                fieldset: {
                  borderColor: theme.palette.grey[300] + " !important",
                },
                "&.Mui-focused": {
                  fieldset: {
                    borderColor: theme.palette.primary.dark + " !important",
                  },
                },
                "&.Mui-error": {
                  fieldset: {
                    borderColor:
                      theme.palette.custom.errorBorder + " !important",
                  },
                },
              },
            }}
          />
          <Stack width="100%" direction="row" justifyContent="flex-end">
            <Typography
              fontSize="0.875rem"
              fontWeight={400}
              color={theme.palette.grey[500]}
            >
              {t("notes.addNoteModal.charLimit", {
                count: value.length,
                limit: characterLimit,
              })}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-end"
          px="1.5rem"
        >
          <Button
            buttonProps={{
              onClick: () => void onSave(value),
            }}
          >
            {t("notes.addNoteModal.save")}
          </Button>
        </Stack>
      </Stack>
    </CenterModal>
  );
};
