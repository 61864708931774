import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { IconButton, useTheme } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import Button from "../../theme/Button";
import { useRouteError } from "react-router-dom";

type Props = {
  title: string;
  message?: string;
  buttons: {
    text: string;
    onClick: () => void;
  }[];
}

export default function ErrorElement({title, message, buttons}: Props) {
  const theme = useTheme();
  const error = useRouteError();

  console.log(error)

  return (
    <Box sx={{ height: "100%", width: "100%", display: "flex" }}>
      <Stack
        sx={{
          height: "100%",
          width: "100%",
          overflow: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          disabled
          style={{
            padding: "12px",
            borderRadius: "50%",
            backgroundColor: theme.palette.errorColor[100], //.error.light,
            color: theme.palette.errorColor[700],
          }}
        >
          <ErrorOutlineRoundedIcon fontSize="medium" color="inherit" />
        </IconButton>
        <Stack
          padding="16px 0px 24px 0px"
          width="352px"
          justifyContent="center"
          gap="6px"
        >
          <Typography
            fontSize="16px"
            fontWeight={600}
            lineHeight="24px"
            color={theme.palette.grey[800]}
            textAlign="center"
          >
            {title}
          </Typography>
          {message && <Typography
            fontSize="14px"
            fontWeight={400}
            lineHeight="20px"
            color={theme.palette.grey[500]}
            textAlign="center"
          >
            {message}
          </Typography>}
        </Stack>
        <Stack direction="row">
          { buttons.map(buttonInfo =>  (
          <Button
            buttonProps={{
              onClick: buttonInfo.onClick,
              disableRipple: true,
              sx: {
                height: "2.5rem",
                fontSize: "0.875rem",
                boxShadow: "none",
                background: theme.palette.background.default,
                color: theme.palette.grey[600],
                borderRadius: "0.5rem",
                border: 1,
                borderColor: theme.palette.grey[300],
                "&:hover": {
                  backgroundColor: theme.palette.grey[100],
                  boxShadow: "none",
                },
                "&:active": {
                  backgroundColor: theme.palette.background.default,
                  boxShadow: "none",
                },
                "&:disabled": {
                  borderColor: theme.palette.grey[200],
                  backgroundColor: theme.palette.background.default,
                  boxShadow: "none",
                },
              },
            }}
          >
            {buttonInfo.text}
          </Button>))}
        </Stack>
      </Stack>
    </Box>
  );
}
