import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Translation } from "../lib/constants";

interface NavItemProps {
  title: string;
  icon?: string;
  rightIcon?: string;
  selected: boolean;
  onClick: () => void;
  attachedIcon?: string;
  atBottom?: boolean;
}

export default function NavItem(props: NavItemProps) {
  const { t } = useTranslation(Translation.common);
  const { title, icon, rightIcon, selected, onClick, attachedIcon, atBottom } = props;

  return (
    <Stack
      tabIndex={0}
      width="100%"
      padding="0.5rem 0.75rem"
      borderRadius="0.25rem"
      flexDirection="row"
      gap="0.75rem"
      alignItems="center"
      marginTop={atBottom ? "auto" : 0}
      sx={(theme) => ({
        cursor: "pointer",
        backgroundColor: selected ? theme.palette.grey[50] : "none",
        color: selected ? theme.palette.grey[800] : theme.palette.grey[700],
        fontWeight: selected ? 600 : 500,
        "&:hover": {
          backgroundColor: theme.palette.grey[50],
        },
        "&:focus-visible": {
          outline: "none",
          backgroundColor: theme.palette.grey[50],
          boxShadow: `0px 0px 0px 4px ${theme.palette.custom.navGrey}`,
        },
      })}
      onClick={onClick}
      onKeyDown={(e) => e.key === "Enter" && onClick()}
    >
      {icon && <Box component="img" alt={t("navIconAlt")} src={icon} />}
      <Typography fontSize="0.875rem" fontWeight="inherit" color="inherit">
        {title}
      </Typography>
      {attachedIcon && (
        <Box component="img" alt={t("navIconAlt")} src={attachedIcon} />
      )}
      {rightIcon && (
        <Box
          component="img"
          alt={t("navIconAlt")}
          src={rightIcon}
          sx={{ marginLeft: "auto" }}
        />
      )}
    </Stack>
  );
}
