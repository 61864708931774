import { useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  UserConfigContext,
  UserContext,
  FlagsContext,
} from "../../lib/context";
import Box from "@mui/material/Box";
import Sidebar from "../Sidebar";
import ReactGA from "react-ga4";
import { loadUserFromStorage } from "../../lib/utils";
import { getUserConfig } from "../../api/user";
import { getEnabledFlags } from "../../api/flag";

export default function ProtectedRoute() {
  const [user, _setUser] = useContext(UserContext);
  const [_userConfig, setUserConfig] = useContext(UserConfigContext);
  const [_flags, setFlags] = useContext(FlagsContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname != "/") {
      ReactGA.send({ hitType: "pageview", page: pathname, title: pathname, org: user?.orgName });
    }
      const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
      const cleanedPathName =  pathname.replace(uuidRegex, 'id');
      if (pathname !== cleanedPathName) {
        ReactGA.send({ hitType: "pageview", page: cleanedPathName, title: cleanedPathName, org: user?.orgName })
    }
  }, [pathname]);

  useEffect(() => {
    function handleFocus() {
      const localUser = loadUserFromStorage();
      if (localUser?.email != user?.email) {
        navigate("/");
      }
    }

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  useEffect(() => {
    fetchUserConfig();
    fetchFlags();
  }, [user]);

  const fetchUserConfig = async () => {
    if (user) {
      ReactGA.set({ org: user.orgName });
      setUserConfig(await getUserConfig(user.orgId));
    }
  };

  const fetchFlags = async () => {
    if (user) {
      setFlags(await getEnabledFlags(user.orgId));
    }
  };

  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <Sidebar />
      <Box sx={{ height: "100%", width: "100%", overflow: "auto" }}>
        <Outlet />
      </Box>
    </Box>
  );
}
