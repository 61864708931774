import { http } from ".";
import { HttpError, HttpResponse, ResponseStatus } from "../models/api/http";
import {
  JobDashboardDTO,
  JobDashboardResponse,
  ListJobAlumniMatchDTO,
  RoleViewSortOptionEnum,
  JobAutocompleteResponse,
  AlumniMatchesSortOptionEnum,
  JobDTO,
  ListJobTitlesByDepartmentResponse,
} from "../models/api/job";
import { JobFilterOptions } from "../models/filter";
import { NameDTO } from "../models/api/common";

export const getJobDashboard = async (
  orgId: string,
  page: number,
  pageSize: number,
  sortOption: RoleViewSortOptionEnum,
  sortDesc: boolean,
  filters: JobFilterOptions,
  search?: string,
): Promise<JobDashboardResponse> => {
  let queryParams = `page=${page}&page_size=${pageSize}&sort_option=${sortOption}&sort_desc=${sortDesc}`;
  if (search) {
    queryParams += `&search=${encodeURIComponent(search)}`;
  }
  const response = await http.post<HttpResponse<JobDashboardResponse>>(
    `/api/org/${orgId}/job/dashboard?${queryParams}`,
    transformFiltersForBackend(filters),
  );

  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const getJobAutocompleteSuggestions = async (
  orgId: string,
  searchTerm: string,
  filters: JobFilterOptions,
  limit: number = 10,
): Promise<JobAutocompleteResponse> => {
  const queryParams = `search_term=${encodeURIComponent(
    searchTerm,
  )}&limit=${limit}`;
  const response = await http.post<HttpResponse<JobAutocompleteResponse>>(
    `/api/org/${orgId}/job/autocomplete?${queryParams}`,
    transformFiltersForBackend(filters),
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(
      response.status,
      response.data.message ??
        "An error occurred while fetching autocomplete suggestions.",
    );
  }
  return response.data.data;
};

export const getJob = async (
  orgId: string,
  jobId: string,
): Promise<JobDashboardDTO> => {
  const response = await http.get<HttpResponse<JobDashboardDTO>>(
    `/api/org/${orgId}/job/${jobId}`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const getJobAlumniMatches = async (
  orgId: string,
  jobId: string,
  page: number,
  pageSize: number,
  sortOption?: AlumniMatchesSortOptionEnum,
  sortDesc?: boolean,
  search?: string,
): Promise<ListJobAlumniMatchDTO> => {
  let queryParams = `page=${page}&page_size=${pageSize}`;
  if (sortOption != undefined) {
    queryParams += `&sort_option=${sortOption}`;
  }
  if (sortDesc != undefined) {
    queryParams += `&sort_desc=${sortDesc}`;
  }
  if (search != undefined) {
    queryParams += `&search=${encodeURIComponent(search)}`;
  }

  const response = await http.get<HttpResponse<ListJobAlumniMatchDTO>>(
    `/api/org/${orgId}/job/${jobId}/alumni-matches?${queryParams}`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const autocompleteAlumniMatchesSearch = async (
  orgId: string,
  jobId: string,
  searchTerm: string,
): Promise<NameDTO[]> => {
  const response = await http.get<HttpResponse<NameDTO[]>>(
    `/api/org/${orgId}/alumni-matches/autocomplete?job_id=${jobId}&search_term=${encodeURIComponent(
      searchTerm,
    )}`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const listJobNamesByAtsDepartment = async (
  orgId: string,
  departmentId: string | null,
): Promise<ListJobTitlesByDepartmentResponse> => {
  const params = new URLSearchParams();
  if (departmentId) {
    params.append("ats_department_id", departmentId);
  }
  const response = await http.get<
    HttpResponse<ListJobTitlesByDepartmentResponse>
  >(`/api/org/${orgId}/job/list/ats-department?${params.toString()}`);
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const listJobsImpactedByRoleMatchingCriteria = async (
  orgId: string,
  jobTitles: string[],
  departmentId: string | null,
  applyToAllRolesInDepartment: boolean,
): Promise<JobDTO[]> => {
  const response = await http.post<HttpResponse<JobDTO[]>>(
    `/api/org/${orgId}/job/list/title`,
    {
      job_titles: jobTitles,
      ats_department_id: departmentId,
      apply_to_all_roles_in_department: applyToAllRolesInDepartment,
    },
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

const transformFiltersForBackend = (filter: JobFilterOptions) => {
  return {
    status: Object.entries(filter.status).reduce(
      (acc: string[], [key, value]) => (value ? acc.concat(key) : acc),
      [],
    ),
    recruiter_ids: Array.from(filter.recruiterIds),
    locations: Array.from(filter.locations),
  };
};
