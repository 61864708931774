import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import theme from "../../theme";

export default function EmptyResult({
  heading,
  text,
  imgSrc,
}: {
  text: string;
  heading?: string;
  imgSrc?: string;
}) {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      color={theme.palette.grey[500]}
      paddingTop="48px"
      className="empty-list-error"
    >
      <IconButton
        disabled
        style={{
          padding: "12px",
          borderRadius: "50%",
          backgroundColor: theme.palette.grey[100],
        }}
      >
        {imgSrc ? (
          <Box component="img" src={imgSrc} />
        ) : (
          <ErrorOutlineRoundedIcon fontSize="medium" />
        )}
      </IconButton>
      <Box paddingTop="16px"></Box>
      {heading ? (
        <Typography
          style={{
            color: theme.palette.grey[800],
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          {heading}
        </Typography>
      ) : null}
      <Typography
        style={{
          color: theme.palette.grey[500],
          fontSize: "14px",
          fontWeight: 400,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
}
