import { http } from ".";
import { HttpError, HttpResponse, ResponseStatus } from "../models/api/http";
import { UserConfigDTO, UserConfigJson } from "../models/config";

export const getUserConfig = async (orgId: string): Promise<UserConfigDTO> => {
  const response = await http.get<HttpResponse<UserConfigJson>>(
    `/api/org/${orgId}/user/config`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return UserConfigDTO.fromJson(response.data.data);
};
