import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useMatches, Link as RouterLink } from "react-router-dom";
import theme from "../../theme";
import { HandleType } from "../../route";

/**
 * Breadcrumbs are being setup within {../../main.tsx}. Following the example https://reactrouter.com/en/main/hooks/use-matches#breadcrumbs
 * The output expected from handle.crumb() method is [label: string, link: string].
 */
export default function breadcrumbs() {
  let matches = useMatches();
  let crumbs: ([label: string, link: string] | undefined)[] = matches
    // remove matches that don't have handle and crumb
    .filter((match) => Boolean((match.handle as HandleType | undefined)?.crumb))
    // map them into a label and link pair
    .map((match) => (match.handle as HandleType).crumb!(match.data));

  const breadCrumbTruncate = (labelToTruncate: string) => {
    if (labelToTruncate.length <= 30) return labelToTruncate;
    var labelSeperated = labelToTruncate.split(" ");
    var lastWordInLabel = labelSeperated[labelSeperated.length - 1];
    if (lastWordInLabel.length < 27) {
      return (
        labelToTruncate.substring(0, 27 - lastWordInLabel.length) +
        "..." +
        lastWordInLabel
      );
    } else {
      return (
        labelToTruncate.substring(0, 16) + "..." + labelToTruncate.slice(-15)
      );
    }
  };

  return (
    <Breadcrumbs
      className="breadcrumbs"
      separator={
        <ChevronRightRoundedIcon sx={{ color: theme.palette.grey[300] }} />
      }
      aria-label="breadcrumb"
    >
      {crumbs.map((crumb, index) => {
        if (!crumb) return undefined;

        var isNotLast = index < crumbs.length - 1;
        return (
          <Link
            component={RouterLink}
            to={isNotLast ? crumb[1] : ""}
            underline={isNotLast ? "hover" : "none"}
            fontSize="0.75rem"
            key={index + 1}
            color={theme.palette.grey[500]}
            fontWeight={isNotLast ? 400 : 500}
            onClick={() => {}}
          >
            {breadCrumbTruncate(crumb[0])}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
