import Stack, { StackProps } from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import { ComponentProps, useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { SxProps } from "@mui/material";
import theme from ".";
import Tooltip from "./Tooltip";
import helpIcon from "/help-circle-secondary.svg";

export interface InputProps {
  id?: string;
  label?: string;
  placeholder: string;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: string;
  stackProps?: StackProps;
  inputStyleProps?: SxProps;
  inputProps?: OutlinedInputProps;
  titleProps?: TypographyProps;
  rightSideComponent?: React.ReactElement;
  tooltipText?: string;
  onFocus?: () => void;
}

export default function Input(props: InputProps) {
  const {
    id,
    label,
    placeholder,
    required,
    onChange,
    error,
    stackProps,
    inputStyleProps,
    inputProps,
    titleProps,
    rightSideComponent,
    tooltipText,
    onFocus,
  } = props;

  return (
    <Stack spacing="0.375rem" {...stackProps} id={id}>
      <Stack direction="row" alignItems="center">
        {label && (
          <Typography
            className="input-label"
            fontSize="0.875rem"
            fontWeight={500}
            {...titleProps}
          >
            {label}
          </Typography>
        )}
        {required && (
          <Typography
            fontSize="0.875rem"
            fontWeight={500}
            color="grey.500"
            lineHeight="1.25rem"
            paddingRight="0.25rem"
          >
            *
          </Typography>
        )}
        {tooltipText && (
          <Tooltip
            arrow={false}
            title={tooltipText}
            className="tooltip"
            placement="right-start"
            componentsProps={{
              tooltip: {
                sx: {
                  padding: "0.75rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "0.5rem",
                  bgcolor: "white",
                  color: "grey.500",
                  fontSize: "0.75rem",
                  fontWeight: 500,
                  boxShadow:
                    "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);",
                },
              },
            }}
          >
            <Box component="img" paddingLeft="0.25rem" src={helpIcon} />
          </Tooltip>
        )}
      </Stack>
      <Stack direction="row" alignItems="center">
        <OutlinedInput
          placeholder={placeholder}
          fullWidth
          onChange={onChange}
          error={error !== ""}
          sx={{
            borderRadius: "0.5rem",
            "& .MuiInputBase-input::placeholder": {
              color: theme.palette.grey[500],
            },
            "& .MuiOutlinedInput-input": {
              padding: "10px 14px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: error
                ? theme.palette.errorColor[300] + "!important"
                : theme.palette.grey[300],
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: error
                ? theme.palette.errorColor[300] + "!important"
                : theme.palette.grey[300],
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: error
                ? theme.palette.errorColor[300]
                : theme.palette.primary.main,
              borderWidth: error ? "1px" : "2px",
            },
            ...inputStyleProps,
          }}
          {...inputProps}
          onFocus={onFocus}
        />
        {rightSideComponent}
      </Stack>
      {error !== "" && (
        <Typography
          fontSize="0.875rem"
          color={theme.palette.errorColor[700]}
          className="input-error"
        >
          {error}
        </Typography>
      )}
    </Stack>
  );
}

const StyledInput = styled("input")`
  display: block;
  width: 100%;
  border: none;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &:focus {
    outline: none;
  }
`;

export const PasswordInput = (props: ComponentProps<typeof StyledInput>) => {
  const [hidden, setHidden] = useState(true);

  return (
    <Stack gap="6px" alignItems="end">
      <Stack
        sx={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.grey[300],
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 14px",
          height: "44px",
          width: "100%",
          borderRadius: "0.5rem",

          "& .MuiOutlinedInput-input": {
            padding: "10px 14px",
          },
          "&:hover ": {
            borderColor: "#b2b8c3",
          },
          "&:focus-within ": {
            borderColor: theme.palette.primary.main,
            borderWidth: "2px",
          },
          "&::placeholder ": {
            color: theme.palette.grey[500],
          },
        }}
      >
        <StyledInput type={hidden ? "password" : "text"} {...props} />
        {hidden ? (
          <VisibilityOffOutlinedIcon onClick={() => setHidden(false)} />
        ) : (
          <VisibilityOutlinedIcon onClick={() => setHidden(true)} />
        )}
      </Stack>
    </Stack>
  );
};
