import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import FilterListIcon from "@mui/icons-material/FilterList";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { Translation } from "../../lib/constants";
import Box from "@mui/material/Box";

type Props = { onClick: () => void; isDisabled: boolean; count: number };
const FilterButton = ({ onClick, isDisabled, count }: Props) => {
  const { t } = useTranslation(Translation.common);

  const clickHandler = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  return (
    <Card
      id="filter-button"
      onClick={clickHandler}
      style={{
        cursor: isDisabled ? "default" : "pointer",
        border: "none",
        boxShadow: "none",
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      <Stack
        height="40px"
        direction="row"
        gap="8px"
        alignItems="center"
        padding="0.625rem 1rem"
        border={(theme) => `1px solid ${theme.palette.grey[300]}`}
        borderRadius="0.5rem"
        sx={(theme) => ({
          "&:hover": {
            backgroundColor: isDisabled ? "white" : theme.palette.grey[50],
          },
        })}
      >
        <FilterListIcon
          style={{
            width: "1.25rem",
            height: "1.25rem",
            userSelect: "none",
          }}
        />
        <Typography
          fontSize="0.875rem"
          fontWeight={500}
          color={(theme) => theme.palette.grey[600]}
          sx={{
            userSelect: "none",
          }}
        >
          {t("filter.dropdownLabel")}
        </Typography>

        {count > 0 && (
          <Box
            sx={(theme) => ({
              height: "22px",
              width: "22px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "18px",
              color: theme.palette.grey[600],
              backgroundColor: theme.palette.grey[100],
            })}
          >
            {count}
          </Box>
        )}
      </Stack>
    </Card>
  );
};

export default FilterButton;
