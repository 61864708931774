import { useTranslation } from "react-i18next";
import textLogo from "/text-logo.svg";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useParams } from "react-router";
import { urlDecodeBase64 } from "../../lib/utils";
import { useSearchParams } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { Translation } from "../../lib/constants";

type UrlParams = {
  qrCodeUri: string;
};

export default function Authenticator() {
  const { t } = useTranslation(Translation.authenticator);
  const params = useParams<UrlParams>();
  const qrCodeUri = urlDecodeBase64(params.qrCodeUri ?? "");

  const [searchParams, _setSearchParams] = useSearchParams();
  const email = searchParams.get("email");

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: "grid",
          placeItems: "center",
        }}
      >
        <img
          src={textLogo}
          alt={t("boomerangLogoAlt", { ns: Translation.common })}
        />
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            mt: (theme: Theme) => theme.spacing(2),
            gap: (theme: Theme) => theme.spacing(2),
          }}
        >
          <Typography variant="body2" textAlign="center">
            {t("2faEnabled")}
            {email && <strong>{email}</strong>}.
          </Typography>
          <img
            src={qrCodeUri}
            alt={t("qrCodeAlt", { ns: Translation.common })}
          />
          <Typography variant="body2" textAlign="center">
            {t("scanQrCode")}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
