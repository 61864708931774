import InfoAlert from "../../theme/InfoAlert";
import Link from "@mui/material/Link";
import { Translation } from "../../lib/constants";
import { useTranslation } from "react-i18next";
import theme from "../../theme";

export default function SettingsInfoAlert({ heading }: { heading: string }) {
  const { t } = useTranslation(Translation.settings);

  return (
    <InfoAlert heading={heading}>
      {t("infoAlert.beforeEmail")}
      <Link
        href="mailto:tasupport@hiboomerang.com"
        color={theme.palette.primary.main}
        underline="none"
      >
        tasupport@hiboomerang.com
      </Link>{" "}
      {t("infoAlert.afterEmail")}
    </InfoAlert>
  );
}
