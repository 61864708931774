import { http } from ".";
import { CriteriaEmailResponseDTO } from "../models/api/criteria";
import { HttpResponse, HttpError, ResponseStatus } from "../models/api/http";

export const subscribeEmployeeByEmail = async (
  employeeId: string,
): Promise<string> => {
  const response = await http.post<HttpResponse<string>>(
    `/api/employee/${employeeId}/subscribe`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(
      response.status,
      response.data.message ?? "Error subscribing the employee.",
    );
  }
  return response.data.data;
};

export const unsubscribeEmployeeByEmail = async (
  employeeId: string,
): Promise<string> => {
  const response = await http.post<HttpResponse<string>>(
    `/api/employee/${employeeId}/unsubscribe`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(
      response.status,
      response.data.message ?? "Error unsubscribing the employee.",
    );
  }
  return response.data.data;
};

export const renderSendRoleEmail = async (
  orgId: string,
  emailContent: AlumniEmailContent,
  jobId: string | null,
): Promise<string> => {
  const response = await http.post<HttpResponse<string>>(
    `/api/org/${orgId}/job/render-send-role-email`,
    {
      job_id: jobId,
      email_content: {
        headline: emailContent.headline,
        body_paragraphs: emailContent.bodyParagraphs,
        subject: emailContent.subject,
        should_show_referral: emailContent.requestReferrals,
        include_signature: emailContent.includeSignature,
      },
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const renderReferralEmail = async (
  orgId: string,
  emailContent: ReferralEmailContent,
  jobIds: string[],
): Promise<string> => {
  const response = await http.post<HttpResponse<string>>(
    `/api/org/${orgId}/job/render-referral-email`,
    {
      email_content: emailContent,
      job_ids: jobIds,
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const renderMessageEmail = async (
  orgId: string,
  emailContent: MessageEmailContent,
): Promise<string> => {
  const response = await http.post<HttpResponse<string>>(
    `/api/org/${orgId}/job/render-message-email`,
    {
      email_content: {
        subject: emailContent.subject,
        headline: emailContent.headline,
        body_paragraphs: emailContent.bodyParagraphs,
        recruiter_email: emailContent.recruiterEmail,
      },
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const sendRoleEmail = async (
  orgId: string,
  emailContent: AlumniEmailContent,
  jobId: string,
  employeeIds: string[],
): Promise<void> => {
  const response = await http.post<HttpResponse<string>>(
    `/api/org/${orgId}/job/send-role-email`,
    {
      job_id: jobId,
      employee_ids: employeeIds,
      email_content: {
        headline: emailContent.headline,
        body_paragraphs: emailContent.bodyParagraphs,
        should_show_referral: emailContent.requestReferrals,
        include_signature: emailContent.includeSignature,
        subject: emailContent.subject,
      },
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return;
};

export const sendReferralEmail = async (
  orgId: string,
  emailContent: ReferralEmailContent,
  jobIds: string[],
  employeeIds: string[],
): Promise<void> => {
  const response = await http.post<HttpResponse<string>>(
    `/api/org/${orgId}/job/send-referral-email`,
    {
      email_content: emailContent,
      employee_ids: employeeIds,
      job_ids: jobIds,
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return;
};

export const sendMessageEmail = async (
  orgId: string,
  emailContent: MessageEmailContent,
  employeeIds: string[],
): Promise<void> => {
  const response = await http.post<HttpResponse<string>>(
    `/api/org/${orgId}/job/send-message-email`,
    {
      employee_ids: employeeIds,
      email_content: {
        subject: emailContent.subject,
        headline: emailContent.headline,
        body_paragraphs: emailContent.bodyParagraphs,
        recruiter_email: emailContent.recruiterEmail,
      },
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return;
};

export enum EmailSettingsType {
  Roles = "role-matching-criteria",
  Referrals = "referral-matching-criteria",
}

export const getEmailFrequency = async (
  orgId: string,
  type: EmailSettingsType,
): Promise<CriteriaEmailResponseDTO> => {
  const response = await http.get<HttpResponse<CriteriaEmailResponseDTO>>(
    `/api/org/${orgId}/settings/${type}/email`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const updateEmailFrequency = async (
  orgId: string,
  type: EmailSettingsType,
  emailFrequency: number,
  emailFrequencyUnit: string,
): Promise<void> => {
  const response = await http.patch<HttpResponse<CriteriaEmailResponseDTO>>(
    `/api/org/${orgId}/settings/${type}/email`,
    {
      frequency: emailFrequency,
      frequency_unit: emailFrequencyUnit,
    },
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return;
};

export type AlumniEmailContent = {
  headline: string;
  bodyParagraphs: string[];
  subject: string;
  requestReferrals: boolean;
  includeSignature: boolean;
};

export type ReferralEmailContent = {
  subject: string;
  headline: string;
  body: string;
  include_signature: boolean;
};

export type MessageEmailContent = {
  subject: string;
  headline: string;
  bodyParagraphs: string[];
  recruiterEmail: string | null;
};
